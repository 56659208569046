import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-tabs",style:({
		height: _vm.tabsHeight,
	})},_vm._l((_vm.tabs),function(tab){return _c(VBtn,{key:tab.id,staticClass:"runnit-tab",class:{
			'runnit-tab-active': _vm.isActive(tab.id),
			...(_vm.isActive(tab.id) && !_vm._isEmpty(_vm.activeTabClasses) && {
				..._vm.activeTabClasses,
			}),
		},style:({
			'background-color': _vm.isActive(tab.id) ? _vm.activeBackgroundColor : 'transparent',
			...(_vm.isActive(tab.id) && !_vm._isEmpty(_vm.activeTabStyles) && {
				..._vm.activeTabStyles,
			}),
		}),on:{"click":function($event){return _vm.emitTabInput(tab.id)}}},[(tab.icon)?_c(VIcon,{style:({
				color: _vm.isActive(tab.id) ? 'white' : _vm.labelColor,
				margin: _vm.noLabel ? '0' : '0 8px 0 0',
				fontSize: _vm.iconSize,
			})},[_vm._v(" "+_vm._s(tab.icon)+" ")]):_vm._e(),(!_vm.noLabel)?_c('span',[_vm._v(" "+_vm._s(tab.label)+" ")]):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }