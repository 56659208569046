import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"runnit-title-bar-image",class:{
			'runnit-title-bar-image-smAndDown': _vm.$vuetify.breakpoint.smAndDown,
		},style:({
			margin: _vm.$vuetify.breakpoint.smAndDown ? '0 10px 0 0' : '0 15px 0 0',
		}),on:{"click":() => { _vm.canEdit ? _vm.onEditRunnitSettings(_vm.runnitState.runnit) : null }}},[(_vm._get(_vm.runnitState, 'runnit.avatar.id'))?_c('runnit-image',{staticStyle:{"height":"40px","width":"40px"},attrs:{"avatar":_vm.runnitState.runnit.avatar,"is-avatar":"","no-click":""}}):_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#757575"}})],1),(_vm._get(_vm.runnitState, 'runnit.title') && _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"runnit-title-bar-name",style:({
			fontSize: _vm.$vuetify.breakpoint.xsOnly ? '16px' : '20px',
		})},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.runnitState.runnit.title,{ length: _vm.computedTitleTruncateLength }))+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.canEdit)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticStyle:{"margin":"0 0 0 2px"},attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.onEditRunnitSettings(_vm.runnitState.runnit)}}},'base-button',attrs,false),on),[_c(VIcon,{staticStyle:{"color":"var(--grey-400)"}},[_vm._v("mdi-cog-outline")])],1)]}}],null,false,602563019)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Configure settings or delete Runnit Board")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }