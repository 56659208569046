
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { AppKey, AppOffer } from '@run-diffusion/shared';
import ErrorState from '@/components/states/ErrorState.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import AppOfferCard from '@/views/Sessions/AppOffersList/AppOfferCard/AppOfferCard.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'AppOffersList',
	props: {
		selectionMode: { type: Boolean, default: false },
	},
	data () {
		return {
			loading: false, // TODO: loading state with Vuex
			hasError: false, // TODO: error state with Vuex
			intersectAppKey: null,
			canSetIntersectAppOffer: false,

			resourcesDialog: {
				open: false,
				appOffer: null,
			},
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
			'appOffers',
			'loadingUser',
			'loadingTeam',
		]),
	},
	watch: {
		appOffers (newVal: AppOffer[], oldVal: AppOffer[]) {
			if (!this.intersectAppKey && newVal && newVal.length && !(oldVal && oldVal.length)) {
				this.intersectAppKey = newVal[0].app;
				setTimeout(() => {
					this.canSetIntersectAppOffer = true;
				}, 500);
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		scrollToAppOffer (app: AppKey) {
			this.$scrollTo(`#app-offer-${app}`, 500, {
				offset: -50,
			});
		},
		onAppOfferCardIntersect (appOffer: AppOffer) {
			if (appOffer && this.canSetIntersectAppOffer) {
				this.intersectAppKey = appOffer.app;
			}
		},
		onAppOfferSelect (appOffer: AppOffer) {
			if (this.selectionMode) {
				this.$emit('on-app-offer-select', appOffer);
			} else {
				this.routeToSetupSession(appOffer.app);
			}
		},
		routeToSetupSession (app: AppKey) {
			if (this.$route.name === ROUTER.ANOTHER_SESSION) {
				this.routerPush(this.$route, this.$router, { name: ROUTER.SETUP_ANOTHER_SESSION, query: { app } });
			} else {
				this.routerPush(this.$route, this.$router, { name: ROUTER.SETUP_SESSION, query: { app } });
			}
		},
	},
	components: {
		AppOfferCard,
		EmptyState,
		LoadingState,
		ErrorState,
	},
});
