
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import RunnitImage from './RunnitImage.vue';
import RunnitVideo from './RunnitVideo.vue';
import { RunnitRemixMixin, RunnitsImageSelectMixin } from '@/mixins';
import _isEmpty from 'lodash/isEmpty';
import { ROUTER } from '@/router/constants';
import {
    extractPromptStringValue,
    RUNNIT_NODE_RUN_RESULT_TYPE,
    RunnitNodeField,
    RunnitNodeFieldGroup,
    RUNNIT_TYPE,
    RunnitNodeDef,
} from '@run-diffusion/shared';

export default Vue.extend({
    name: 'RunnitResult',
    props: {
        // Runnit Image Props
        timerStartAtMillis: { type: Number, default: null },
        nodeRunResult: { type: Object, default: null },
        upload: { type: Object, default: null },
        avatar: { type: Object, default: null },
        noClick: { type: Boolean, default: false },
        isThumb: { type: Boolean, default: false },
        isAvatar: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        width: { type: String, default: null },
        height: { type: String, default: null },
        maxHeight: { type: String, default: null },
        isErrorPlaceholder: { type: Boolean, default: false },
        isLoadingPlaceholder: { type: Boolean, default: false },
        isInsideInfoCarousel: { type: Boolean, default: false },
        isSelectedImage: { type: Boolean, default: false },
        useZoomViewer: { type: Boolean, default: false },
        coldStartWarningSeconds: { type: Number, default: null },
        isNsfwPlaceholder: { type: Boolean, default: false },
        nodeDef: { type: Object, default: null },
        nodeRunId: { type: String, default: null },
        nodeRun: { type: Object, default: null },
        hidePromptOverlay: { type: Boolean, default: false },
        draggable: { type: Boolean, default: false },
    },
    mixins: [
        RunnitRemixMixin,
        RunnitsImageSelectMixin,
    ],
    data () {
        return {
            imageDimensions: {
                width: 0,
                height: 0
            },
            isHovering: false
        };
    },
    computed: {
        ...mapState(['user']),
        isSmallImage () {
            return this.imageDimensions.width < 120 || this.imageDimensions.height < 120;
        },
        promptField () {
            if (!this.nodeRun || !this.nodeRun.fields) {
                return null;
            }

            // Find the prompt field in the nodeRun fields
            return this.findPromptField(this.nodeRun.fields);
        },
        showPromptMagic () {
            return !!(
                this.promptField &&
                this.nodeRun.promptMagic &&
                this.nodeRun.promptMagic.results &&
                this.nodeRun.promptMagic.results[this.promptField.fieldDefUuid] &&
                this.nodeRun.inputs &&
                extractPromptStringValue(this.nodeRun.inputs[this.promptField.fieldDefUuid]) !== this.nodeRun.promptMagic.results[this.promptField.fieldDefUuid]
            );
        },
        promptText () {
            if (!this.promptField || !this.nodeRun.inputs) {
                return null;
            }

            // Get Prompt Magic prompt if exists
            if (this.showPromptMagic) return this.nodeRun.promptMagic.results[this.promptField.fieldDefUuid];

            // Get the prompt value from the inputs using the fieldDefUuid
            const promptValue: string = this.nodeRun.inputs[this.promptField.fieldDefUuid];
            return extractPromptStringValue(promptValue);
        },
        isRemixable () {
            return this.canRemix(this.nodeRun);
        },
        isSelectionMode () {
            return !!(!this.noClick && this.currentSelectionState.nodeRunResultSelectionMode && this.currentSelectionState.canSelectTypes.includes(RUNNIT_NODE_RUN_RESULT_TYPE.IMG));
        },
    },
    mounted () {
        // If RunnitImage exposes dimensions directly, try to get them
        if (this.$refs.runnitImage && (this.$refs.runnitImage as any).dimensions) {
            this.imageDimensions = (this.$refs.runnitImage as any).dimensions;
        }
    },
    methods: {
        ...mapActions([
        ]),
        _isEmpty,
        onDimensionsUpdated (dimensions) {
            this.imageDimensions = dimensions;
        },
        findPromptField (fields: (RunnitNodeField | RunnitNodeFieldGroup)[]): RunnitNodeField {
            // Recursively search for a field with type 'PROMPT'
            for (const field of fields) {
                const f: RunnitNodeField = field as RunnitNodeField;
                const g: RunnitNodeFieldGroup = field as RunnitNodeFieldGroup;
                if (f.__rfield && f.type === 'PROMPT') {
                    return f;
                } else if (g.__rgroup && g.fields) {
                    const foundField: RunnitNodeField = this.findPromptField(g.fields);
                    if (foundField) {
                        return foundField;
                    }
                }
            }
            return null;
        },
        onSingleSelect (event) {
            this.$emit('on-single-select', event);
        },
        onMultiSelect (event) {
            this.$emit('on-multi-select', event);
        },
        onClick (event) {
            this.$emit('on-click');
        },
        onClear (event) {
            this.$emit('on-clear');
        },
        handleRemixClick (nodeDef, nodeRunId, nodeRun) {
            if (!this.user) {
                const currentPath: string = this.$router.resolve({
                    name: ROUTER.RUNNIT_REMIX,
                    query: {
                        rnrid: nodeRunId,
                        ruuid: this.nodeRunResult.uuid,
                        autoRemix: true,
                    },
                }).href;
                this.$router.push({
                    name: ROUTER.LOGIN,
                    query: { redirect: currentPath }
                });
            } else {
                this.openToolWithSameInputs(nodeDef, nodeRun, nodeRunId, nodeRun ? nodeRun.runnitId : null, nodeRun ? nodeRun.nodeId : null);
            }
        },
        handleTitleClick (nodeDef: RunnitNodeDef) {
            if (this.user) {
                this.onAddRunnitClick(nodeDef, RUNNIT_TYPE.SINGLE_TOOL);
            }
        },
    },
    components: {
        RunnitImage,
        RunnitVideo,
    },
});
