import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width"},[_c(VRow,[_c('div',{staticClass:"full-width mb-4 px-3"},[_c('info-asset-banner',{staticStyle:{"margin":"50px 20px"},attrs:{"info-flex":9,"asset-flex":_vm.$vuetify.breakpoint.lgAndUp ? 4 : 2},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v(" Change your "),_c('b',[_vm._v("Runnit Settings")]),_vm._v(" here. "),_c('br'),_c('br'),_vm._v(" Runnit team subscriptions are currently in development. However while we are busy working on it, you can preview early-access Runnit team features, as long as you have an active RunDiffusion team subscription. ")])]},proxy:true}])}),_c(VCard,{staticClass:"mx-4 pb-9 full-width",class:{
					'px-9 pt-7': _vm.$vuetify.breakpoint.mdAndUp,
					'px-5 pt-6': _vm.$vuetify.breakpoint.smAndDown,
				}},[_c(VCardTitle,{staticClass:"pt-0 px-0 mt-0",style:({
						fontSize: _vm.$vuetify.breakpoint.xsOnly ? '17px': '20px',
					})},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"},attrs:{"left":""}},[_vm._v("mdi-crown")]),_vm._v(" Enterprise Pro Features ")],1),_c(VRow,[_c(VCol,[_c(VSelect,{staticStyle:{"max-width":"400px"},attrs:{"value":_vm.team.nsfwThreshold,"items":_vm.nsfwThresholdOptions,"label":"Customize team's NSFW threshold setting","outlined":"","disabled":_vm.nsfwThresholdSaving,"loading":_vm.nsfwThresholdSaving},on:{"input":_vm.setNsfwThreshold},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',[_c('div',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"caption text--secondary"},[_vm._v(_vm._s(item.subheader))])])]}}])})],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }