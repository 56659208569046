
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import ListItem from '@/components/base/ListItem.vue';
import { Route } from 'vue-router/types/router';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'SettingsBtn',
	props: {
		isListItem: { type: Boolean, default: false },
	},
	data () {
		return {
			autoSoundMachineNeedsInit: false,
		};
	},
	computed: {
		...mapState([
			'sessionSoundsOff',
		]),
	},
	watch: {
		'$route' (newVal: Route, oldVal: Route) {
			if (
				!this.$autoSoundMachine.isInitialized() &&
				!this.sessionSoundsOff &&
				newVal.name === ROUTER.LAUNCH &&
				(
					!oldVal ||
					oldVal.name !== ROUTER.LAUNCH
				)
			) {
				// Pop confirm dialog over all the iframes so that our $autoSoundMachine can get a click listener to init itself
				this.autoSoundMachineNeedsInit = true;
			}
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		openSettings () {
			this.updateToolbar({
				settingsOpen: true,
				mobileMenuOpen: false,
			});
		},
	},
	components: {
		ListItem,
		ConfirmDialog,
	},
});
