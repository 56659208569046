
import Vue from 'vue';
import _truncate from 'lodash/truncate';
import _isNil from 'lodash/isNil';
import { SETTING_ACTIONS } from '@/views/Runnits/constants';
import {
	RunnitNodeField,
	RunnitMask,
	getLogicOnFieldsFromField,
	RUNNIT_NODE_FIELD_TYPE,
	RunnitNodeDef,
	RUNNIT_TYPE,
	extractPromptStringValue,
} from '@run-diffusion/shared';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import RunnitModelSelection from '@/views/Runnits/RunnitSettings/RunnitModelSelection.vue';
import ImageInfoMaskPreview from '@/components/ImageGallery/ImageInfoMaskPreview.vue';
import { mapState } from 'vuex';
import { db } from '@/firebase';
import { RunnitRemixMixin } from '@/mixins/RunnitRemixMixin';
import ToolInfoActionBar from './ToolInfoActionBar.vue';
import ClipboardCopy from '@/components/ClipboardCopy.vue';

export default Vue.extend({
	name: 'ImageInfoFields',
	props: {
		fields: { type: Array, required: true },
		nodeRun: { type: Object, required: true },
		insideDrawer: { type: Boolean, default: false },
		insideNode: { type: Boolean, default: false },
		insideCarousel: { type: Boolean, default: false },
		ignoreTruncation: { type: Boolean, default: false },
		index: { type: [String, Number], default: null },
		imageAttributionStyles: { type: Object, default: null },
		hideGeneratingTool: { type: Boolean, default: false },
		resultUuid: { type: String, default: null },
	},
	data () {
		return {
			SETTING_ACTIONS,
			RUNNIT_NODE_FIELD_TYPE,

			showMask: true,
			expandedGroups: {},
			loadingNodeDef: false,
		};
	},
	mixins: [
		RunnitRemixMixin,
	],
	watch: {
		fields: {
			immediate: true,
			handler (newVal) {
				if (newVal && newVal.length) {
					this.expandedGroups = newVal.reduce((expandedMap, field) => {
						if (field.__rgroup) {
							expandedMap[field.uuid] = true;
						}
						return expandedMap;
					}, {});
				}
			}
		},
		nodeRun: {
			immediate: true,
			async handler (newVal) {
				const needsNodeDefFetch = newVal.nodeDef && (
					typeof newVal.nodeDef === 'string' ||
					!newVal.nodeDef.title ||
					newVal.nodeDef.avatar?.firestore
				);

				this.loadingNodeDef = true;
				try {
					const nodeDefData = needsNodeDefFetch ?
						(await db.doc(`runnitNodeDefs/${newVal.nodeDefId}`).get()).data() :
						newVal.nodeDef;

					let avatar = nodeDefData?.avatar;
					if (nodeDefData && nodeDefData.avatarId && newVal.nodeDef.avatar?.firestore) {
						const avatarDoc = await db.doc(`avatars/${nodeDefData.avatarId}`).get();
						avatar = avatarDoc.exists ? { ...avatarDoc.data(), id: nodeDefData.avatarId } : null;
					}

					this.nodeRun.nodeDef = {
						...nodeDefData,
						id: newVal.nodeDefId,
						avatar,
					};
				} catch (err) {
					console.error('Failed to fetch the generating tool', err);
				} finally {
					this.loadingNodeDef = false;
				}
			}
		},
	},
	computed: {
		...mapState([
			'user',
		]),
		fieldValueTruncateCharCount () {
			let charCount: number = 400;
			if (this.$vuetify.breakpoint.xsOnly || this.insideDrawer) {
				charCount = 100;
			}
			if (this.ignoreTruncation) {
				charCount = 3000;
			}
			return charCount;
		},
		fieldsThatShouldDisplay () {
			return this.fields.filter((field) => this.shouldFieldBeDisplayed(field));
		}
	},
	methods: {
		extractPromptStringValue,
		_truncate,
		_isNil,
		determineWidth () {
			let width = '100%';
			if (
				this.$vuetify.breakpoint.smAndUp &&
				!this.insideDrawer
			) {
				width = '50%';
			}
			return width;
		},
		getInpaintingMask (field: RunnitNodeField): RunnitMask {
			return (
				field &&
				field.type === RUNNIT_NODE_FIELD_TYPE.IMG &&
				this.nodeRun.inputs[field.fieldDefUuid] &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting.mask
			) || null;
		},
		toggleGroupExpansion (uuid) {
			this.expandedGroups[uuid] = !this.expandedGroups[uuid];
		},
		shouldFieldBeDisplayed (field) {
			const {
				allDisables,
				allShows,
				triggeredDisables,
				triggeredShows,
			} = getLogicOnFieldsFromField(field, this.nodeRun.inputs);
			const isShown: boolean = !!(!allShows.length || triggeredShows.length);
			const isDisabled: boolean = !!(isShown && triggeredDisables.length);
			const hideAdmin: boolean = field.adminOnly && !this.user.isAdmin
			return isShown && !isDisabled && !hideAdmin;
		},
		routeToTool (runnitNodeDef: RunnitNodeDef) {
			this.onAddRunnitClick(runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL)
			this.$emit('close-carousel', true);
		},
		showPromptMagic (field: RunnitNodeField) {
			return !!(
				this.nodeRun.promptMagic &&
				this.nodeRun.promptMagic.results &&
				this.nodeRun.promptMagic.results[field.fieldDefUuid] &&
				this.nodeRun.inputs &&
				extractPromptStringValue(this.nodeRun.inputs[field.fieldDefUuid]) !== this.nodeRun.promptMagic.results[field.fieldDefUuid]
			);
		}
	},
	components: {
		ToolInfoActionBar,
		ImageInfoMaskPreview,
		RunnitModelSelection,
		RunnitImage,
		ClipboardCopy,
	},
});
