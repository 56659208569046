
import { SELECTED_IMAGE_MODE, SELECTED_IMAGE_MODE_MENU_ITEMS } from '@/views/Runnits/constants';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import BaseStyledMenu from '../base/BaseStyledMenu.vue';
import GlassButton from '../base/GlassButton.vue';

export default Vue.extend({
	name: 'ImageModeButton',
	props: {},
	data () {
		return {
			SELECTED_IMAGE_MODE,
			selectedImageModeMenuItems: SELECTED_IMAGE_MODE_MENU_ITEMS,
		}
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
	},
	components: {
		BaseStyledMenu,
		GlassButton,
	},
});
