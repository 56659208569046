import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-carousel"},[(_vm.includeViewAll && (_vm.canScrollLeft || _vm.canScrollRight))?_c(VBtn,{staticClass:"view-all-button",style:(_vm.viewAllStyles),attrs:{"text":""},on:{"click":function($event){return _vm.$emit('view-all-click')}}},[_vm._v(" "+_vm._s(_vm.viewAllText)+" "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.useCircleButtons)?[_c(VFadeTransition,[(_vm.canScrollLeft)?_c('div',{staticClass:"scroll-button left circle-button-wrapper"},[_c('div',{staticClass:"gradient-bg"}),_c(VBtn,{staticClass:"scroll-button circle-button",attrs:{"disabled":!_vm.canScrollLeft},on:{"click":_vm.scrollLeft}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)],1):_vm._e()]),_c(VFadeTransition,[(_vm.canScrollRight)?_c('div',{staticClass:"scroll-button right circle-button-wrapper"},[_c('div',{staticClass:"gradient-bg"}),_c(VBtn,{staticClass:"scroll-button circle-button",attrs:{"disabled":!_vm.canScrollRight},on:{"click":_vm.scrollRight}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()])]:[_c(VFadeTransition,[(_vm.canScrollLeft)?_c(VBtn,{staticClass:"scroll-button left",attrs:{"disabled":!_vm.canScrollLeft},on:{"click":_vm.scrollLeft}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c(VFadeTransition,[(_vm.canScrollRight)?_c(VBtn,{staticClass:"scroll-button right",attrs:{"disabled":!_vm.canScrollRight},on:{"click":_vm.scrollRight}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],_c('div',{ref:"carouselWrapper",staticClass:"carousel-wrapper"},[_c('div',{ref:"carouselContent",staticClass:"carousel-content",style:({
				gap: _vm.gap
			})},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"carousel-item"},[_vm._t("item",null,{"item":item})],2)}),0)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }