
import Vue from 'vue';
import { mapState } from 'vuex';
import VersionSelector from './components/VersionSelector.vue';
import FeatureCard from './components/FeatureCard.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { WhatsNewState, WhatsNewUpdate } from './types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment-timezone';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'WhatsNewPage',
	components: {
		VersionSelector,
		FeatureCard,
		BaseButton
	},
	data(): WhatsNewState {
		return {
			selectedVersionIndex: 0,
			updates: [] as WhatsNewUpdate[],
			loading: true,
			error: null
		};
	},
	computed: {
		...mapState(['user']),
		currentVersion(): WhatsNewUpdate | null {
			return this.updates.length > 0 ? this.updates[this.selectedVersionIndex] : null;
		},
		// Helper computed property to check if user is admin
		isAdmin(): boolean {
			return !!(this.user && this.user.isAdmin);
		},
	},
	mounted() {
		this.fetchUpdates();
	},
	methods: {
		onVersionSelected(index: number) {
			this.selectedVersionIndex = index;
		},

		formatDate(date: string): string {
			if (!date) return '';
			return moment(date).format('MMMM D, YYYY');
		},

		async fetchUpdates() {
			try {
				this.loading = true;
				// index created: whatsNewUpdates	isPublished Ascending date Descending __name__ Descending
				const snapshot = await firebase.firestore()
					.collection('whatsNewUpdates')
					.where('isPublished', '==', true)
					// Sort by date in descending order (newest first)
					.orderBy('date', 'desc')
					.limit(100)
					.get();

				this.updates = snapshot.docs.map(doc => {
					const data = doc.data() as WhatsNewUpdate;
					return {
						...data,
						id: doc.id
					};
				});

				this.loading = false;
			} catch (error) {
				console.error('Error fetching updates:', error);
				this.error = error;
				this.loading = false;
			}
		},
		goToManageUpdates() {
			this.$router.push({ name: 'ManageUpdates' });
		},

		/**
		 * Handle call to action button clicks for a specific update
		 * This method will execute the appropriate action for the given update ID
		 *
		 * HOW TO USE:
		 * 1. When creating a new update in ManageUpdatesPage, add text for the "Call-to-Action Button"
		 * 2. After the update is saved, note its ID in the database (visible in Firebase console)
		 * 3. Add a new handler here using the update ID as the key and a function that performs
		 *    the desired action when the button is clicked
		 *
		 * EXAMPLE:
		 * 'your-update-id-here': () => {
		 *   // Your custom code here
		 *   this.$router.push('/some-path'); // Navigate to a page
		 *   this.$store.dispatch('someAction'); // Dispatch a Vuex action
		 *   window.open('https://example.com'); // Open external URL
		 * }
		 *
		 * @param updateId - The ID of the WhatsNewUpdate being displayed
		 */
		handleCallToAction(updateId: string) {
			// Map of update IDs to handler functions
			const actionHandlers: {[key: string]: Function} = {
				// Define custom handlers for specific update IDs
				// Example handlers - replace these with your actual implementation
				// These are just examples to show how to implement feature-specific actions
				'f99f0a12-7772-45b1-a6b4-943634e41da7': () => { // March 28th, 2025
					// Example: Navigate to a specific page
					this.$router.push({ name: ROUTER.RUNNITS_APP_LIBRARY });
				},
				'7be7a1ee-a9df-40b1-b766-d4e50127dd9b': () => { // February 27th, 2025
					this.$router.push({ name: ROUTER.RUNNITS_HOME });
				},
				'example-update-2': () => {
					// Example: Open a specific feature or modal
					this.$store.dispatch('openFeatureModal', { feature: 'newFeature' });
				},
				'example-update-3': () => {
					// Example: Trigger a tutorial
					this.$store.dispatch('startTutorial', { tutorialId: 'new-feature-tutorial' });
				}
			};

			// Check if we have a specific handler for this update
			if (actionHandlers[updateId]) {
				actionHandlers[updateId]();
			} else {
				// Default handler - you can customize this
				console.error(`Action for update ${updateId} would happen here. Define a custom handler for this update.`);
			}
		}
	}
});
