import { render, staticRenderFns } from "./RemixLandingPage.vue?vue&type=template&id=93d929ce&scoped=true"
import script from "./RemixLandingPage.vue?vue&type=script&lang=ts"
export * from "./RemixLandingPage.vue?vue&type=script&lang=ts"
import style0 from "./RemixLandingPage.vue?vue&type=style&index=0&id=93d929ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93d929ce",
  null
  
)

export default component.exports