import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('menu-or-bottom-sheet',{attrs:{"max-width":600,"min-width":260,"nudge-bottom":50,"open-on-hover":_vm.openOnHover,"close-delay":300,"close-on-click":_vm.closeOnClick,"bottom-sheet-persistent":_vm.bottomSheetPersistent},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_vm._t("trigger",null,{"on":on})]}},{key:"content",fn:function({ close }){return [_c('div',{staticClass:"menu-item-container-outer",style:({
				...(_vm.$vuetify.breakpoint.xsOnly && {
					paddingBottom: '25px',
					paddingTop: '5px',
				}),
			})},[_c('complex-background-base-styled-menu'),(_vm.menuItems.length)?_c('div',{staticClass:"menu-item-container",style:({
					width: _vm.menuWidth,
					...(_vm.horizontalMenu && !_vm.$vuetify.breakpoint.xsOnly && {
						'display': 'flex',
						'justify-content': 'space-around',
						'align-items': 'center',
						'gap': '8px',
					}),
				})},[_c(VList,{staticClass:"menu-list",attrs:{"dense":""}},_vm._l((_vm.menuItems),function(menuItem){return _c(VListItem,{key:menuItem.id,staticClass:"menu-item",class:{ 'menu-item-subheader': !!menuItem.isSubheader },attrs:{"disabled":menuItem.disabled},on:{"click":function($event){return _vm.onMenuItemSelect(menuItem.value, close)}}},[(menuItem.icon && !menuItem.isSubheader)?_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,{class:{
									'gradient-text': _vm.value === menuItem.value,
								},staticStyle:{"font-size":"23px"},style:({
									color: (
										_vm.itemsAlwaysWhite ||
										_vm.value === menuItem.value
									)
										? 'white'
										: 'var(--grey-500)',
									...(menuItem.iconStyle),
									...(menuItem.disabled && { color: 'var(--grey-650)' }),
								})},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1):_vm._e(),_c(VListItemContent,{staticClass:"py-1"},[_c(VListItemTitle,{staticClass:"font-weight-bold",staticStyle:{"font-size":"15px"},style:({
									color: (
										_vm.itemsAlwaysWhite ||
										_vm.value === menuItem.value
									)
										? 'white'
										: 'var(--grey-500)',
									...(menuItem.disabled && { color: 'var(--grey-650)' }),
								})},[_vm._v(" "+_vm._s(menuItem.label)+" ")]),(menuItem.description)?_c(VListItemSubtitle,{staticClass:"text-start",staticStyle:{"font-size":"10px","letter-spacing":"0.4px","white-space":"normal","word-wrap":"break-word"},style:({
									color: (
										_vm.itemsAlwaysWhite ||
										_vm.value === menuItem.value
									)
										? 'white'
										: 'var(--grey-500)',
									...(menuItem.disabled && { color: 'var(--grey-650)' }),
								})},[_vm._v(" "+_vm._s(menuItem.description)+" ")]):_vm._e(),(menuItem.disabled)?_c(VListItemSubtitle,{staticClass:"text-start",staticStyle:{"color":"var(--grey-650)","font-size":"10px","letter-spacing":"0.4px","white-space":"normal","word-wrap":"break-word"}},[_vm._v(" "+_vm._s(_vm.disabledText)+" ")]):_vm._e()],1)],1)}),1)],1):_vm._e(),_vm._t("appendContent")],2)]}}],null,true),model:{value:(_vm.localIsOpen),callback:function ($$v) {_vm.localIsOpen=$$v},expression:"localIsOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }