
import Vue from 'vue';
import { mapState } from 'vuex';
import RunnitCarousel from '../../RunnitCarousel.vue';
import {
	RunnitNodeDefTag,
	RUNNIT_NODE_DEF_TAG_TYPE,
} from '@run-diffusion/shared';
import { TRAINING_TYPE_CARDS } from "@/views/Runnits/constants";

export default Vue.extend({
	name: 'ModelTagTypeTabs',
	props: {
		value: { type: String, default: 'ALL' },
	},
	computed: {
		...mapState([
			'publicRunnitNodeDefTags',
		]),
		items() {
			return this.publicRunnitNodeDefTags
				.filter((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE)
				.map((tag: RunnitNodeDefTag) => ({
					id: tag.id,
					label: TRAINING_TYPE_CARDS[tag.id].title,
					icon: TRAINING_TYPE_CARDS[tag.id].icon,
				}));
		},
	},
	methods: {
		handleTabChange(id: string) {
			this.$emit('input', id);
			this.$emit('on-select', id);
		},
	},
	components: {
		RunnitCarousel,
	},
});
