"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_DETAILS_MAP = exports.ITEM_CATEGORY_MAP = exports.CHECKOUT_TEAM_PLAN_SUBSCRIPTION = exports.CHECKOUT_USER_SUBSCRIPTION = exports.CHECKOUT_ADD_FUNDS_TEAM = exports.CHECKOUT_ADD_FUNDS = exports.CHECKOUT_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE = exports.STRIPE_TEAM_PLAN_ADDITIONAL_50GB_PRICE_ID = exports.STRIPE_TEAM_PLAN_ADDITIONAL_50GB_PRODUCT_ID = exports.STRIPE_TEAM_PLAN_ADDITIONAL_MEMBER_PRICE_ID = exports.STRIPE_TEAM_PLAN_ADDITIONAL_MEMBER_PRODUCT_ID = exports.STRIPE_TEAM_PLAN_SETUP_FEE_PRICE_ID = exports.STRIPE_TEAM_PLAN_SETUP_FEE_PRODUCT_ID = exports.STRIPE_TEAM_PLAN_SUB_PRICE_ID = exports.STRIPE_TEAM_PLAN_SUB_PRODUCT_ID = exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_TEAMS_PRICE_ID_MIN_1 = exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_TEAMS_PRICE_ID = exports.ADD_FUNDS_TEAMS_PRODUCT_ID = exports.STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRICE_ID = exports.STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRODUCT_ID = exports.PRORATED_LAST_MONTHS_RUNNIT_TOKENS_PRODUCT_ID = exports.STRIPE_ADD_FUNDS_100_PRICE_ID = exports.STRIPE_ADD_FUNDS_90_PRICE_ID = exports.STRIPE_ADD_FUNDS_80_PRICE_ID = exports.STRIPE_ADD_FUNDS_70_PRICE_ID = exports.STRIPE_ADD_FUNDS_60_PRICE_ID = exports.STRIPE_ADD_FUNDS_50_PRICE_ID = exports.STRIPE_ADD_FUNDS_45_PRICE_ID = exports.STRIPE_ADD_FUNDS_40_PRICE_ID = exports.STRIPE_ADD_FUNDS_35_PRICE_ID = exports.STRIPE_ADD_FUNDS_30_PRICE_ID = exports.STRIPE_ADD_FUNDS_25_PRICE_ID = exports.STRIPE_ADD_FUNDS_20_PRICE_ID = exports.STRIPE_ADD_FUNDS_15_PRICE_ID = exports.STRIPE_ADD_FUNDS_10_PRICE_ID = exports.STRIPE_ADD_FUNDS_5_PRICE_ID = exports.TEST_STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_PRICE_ID = exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_PRICE_ID_MAX_400 = exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_PRICE_ID = exports.ADD_FUNDS_PRODUCT_ID = exports.TEST_STRIPE_TEAMS_PUBLISHABLE_KEY = exports.STRIPE_TEAMS_PUBLISHABLE_KEY = exports.TEST_STRIPE_PUBLISHABLE_KEY = exports.STRIPE_PUBLISHABLE_KEY = void 0;
const clubOffers_1 = require("./clubOffers");
// Pub Key
exports.STRIPE_PUBLISHABLE_KEY = 'pk_live_51M7lvEDHUMQurLbxcaQhL30igGsSkWqpErMAAN1mNGQKRN9mhVrU4EtrktrA1sybAbsQ2uecHTPIW6kTmZx2CbTp00LDIiMTXp';
exports.TEST_STRIPE_PUBLISHABLE_KEY = 'pk_test_51M7lvEDHUMQurLbxXfR1qeVTwVATiw7UwzLWmVlX5cTy9VJ0b31GETpEwZJ6tdRbV46sR5qdPxKomNsJTTB3vgix00aF2xWyPE';
// Teams Pub Key
exports.STRIPE_TEAMS_PUBLISHABLE_KEY = 'pk_live_51MMEE3JJLrjuC4xnFnUx2sozCyBLzct3Ea0gxQZF18xvwweyRwLloVjtTMC1WspLZ0DruAbKWIxKUPPUeUkVHi4O00X0eFQlQz';
exports.TEST_STRIPE_TEAMS_PUBLISHABLE_KEY = 'pk_test_51MMEE3JJLrjuC4xnPI7n4fNcYwQ7iXEJIFRNC4g8WeSEYxzAwB4TLY6bOBec2Zr3rSkS5vFMQ6FVxZpbopDxB4RU00MSv7tmgZ';
// Add funds
exports.ADD_FUNDS_PRODUCT_ID = 'prod_MsI7dbYuGsxDoH';
exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_PRICE_ID = 'price_1R4YfiDHUMQurLbxR5vhvfCV';
exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_PRICE_ID_MAX_400 = 'price_1M9LylDHUMQurLbxbfP1SKum';
exports.TEST_STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_PRICE_ID = 'price_1M8XriDHUMQurLbxsoOoFUG3';
exports.STRIPE_ADD_FUNDS_5_PRICE_ID = 'price_1NXR8dDHUMQurLbxGmOsdkt6';
exports.STRIPE_ADD_FUNDS_10_PRICE_ID = 'price_1NXR8jDHUMQurLbxRRSZZj6v';
exports.STRIPE_ADD_FUNDS_15_PRICE_ID = 'price_1NXRmsDHUMQurLbx6TC9FPlu';
exports.STRIPE_ADD_FUNDS_20_PRICE_ID = 'price_1NXRmwDHUMQurLbxdm3d83pN';
exports.STRIPE_ADD_FUNDS_25_PRICE_ID = 'price_1NXRBzDHUMQurLbxPeBaIr4B';
exports.STRIPE_ADD_FUNDS_30_PRICE_ID = 'price_1NXRn1DHUMQurLbxCCTsJOYx';
exports.STRIPE_ADD_FUNDS_35_PRICE_ID = 'price_1NXRn8DHUMQurLbxdGHDGh6g';
exports.STRIPE_ADD_FUNDS_40_PRICE_ID = 'price_1NXRnJDHUMQurLbxlTRbPFXJ';
exports.STRIPE_ADD_FUNDS_45_PRICE_ID = 'price_1NXRnODHUMQurLbxBuw8vTco';
exports.STRIPE_ADD_FUNDS_50_PRICE_ID = 'price_1NB86XDHUMQurLbxGVlfcaN6';
exports.STRIPE_ADD_FUNDS_60_PRICE_ID = 'price_1NXRnTDHUMQurLbx5V1iAL4j';
exports.STRIPE_ADD_FUNDS_70_PRICE_ID = 'price_1NXRnYDHUMQurLbxXPQs7kpS';
exports.STRIPE_ADD_FUNDS_80_PRICE_ID = 'price_1NXRncDHUMQurLbxB5CcWuKF';
exports.STRIPE_ADD_FUNDS_90_PRICE_ID = 'price_1NXRngDHUMQurLbxScbziUCo';
exports.STRIPE_ADD_FUNDS_100_PRICE_ID = 'price_1NXRnlDHUMQurLbxPL57Yegh';
// Runnit Tokens
exports.PRORATED_LAST_MONTHS_RUNNIT_TOKENS_PRODUCT_ID = 'prod_QwiREiiqdH9xlh';
exports.STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRODUCT_ID = 'prod_RaSbg3fo68RsV4';
exports.STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRICE_ID = 'price_1QhHg4DHUMQurLbxpxLTIVgP';
// Teams - Add funds
exports.ADD_FUNDS_TEAMS_PRODUCT_ID = 'prod_NomAwxJ8HlkAD4';
exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_TEAMS_PRICE_ID = 'price_1N38cPJJLrjuC4xn14p1xKuu';
exports.STRIPE_ADD_FUNDS_PAY_WHAT_YOU_WANT_TEAMS_PRICE_ID_MIN_1 = 'price_1N6OGuJJLrjuC4xnfaBVG5mP';
// Teams - Subscription
exports.STRIPE_TEAM_PLAN_SUB_PRODUCT_ID = 'prod_Nu2vJwjMcSnxVI';
exports.STRIPE_TEAM_PLAN_SUB_PRICE_ID = 'price_1NLtWMJJLrjuC4xnPB2CR3O0';
exports.STRIPE_TEAM_PLAN_SETUP_FEE_PRODUCT_ID = 'prod_Nu2xuT8w5SvRIR';
exports.STRIPE_TEAM_PLAN_SETUP_FEE_PRICE_ID = 'price_1N8Es3JJLrjuC4xnkAoKbDJp';
exports.STRIPE_TEAM_PLAN_ADDITIONAL_MEMBER_PRODUCT_ID = 'prod_Nu31HLRT2zUi5K';
exports.STRIPE_TEAM_PLAN_ADDITIONAL_MEMBER_PRICE_ID = 'price_1NLtsVJJLrjuC4xnFVuWkORY';
exports.STRIPE_TEAM_PLAN_ADDITIONAL_50GB_PRODUCT_ID = 'prod_Nu33teWcBGAWKk';
exports.STRIPE_TEAM_PLAN_ADDITIONAL_50GB_PRICE_ID = 'price_1N8ExKJJLrjuC4xnf7bwFJhE';
// Specific checkout flows/pages
exports.CHECKOUT_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE = 'runnitsEarlyTopUp';
exports.CHECKOUT_ADD_FUNDS = 'addFunds';
exports.CHECKOUT_ADD_FUNDS_TEAM = 'teamAddFunds';
exports.CHECKOUT_USER_SUBSCRIPTION = 'ccMembership';
exports.CHECKOUT_TEAM_PLAN_SUBSCRIPTION = 'teamSubscription';
exports.ITEM_CATEGORY_MAP = {
    RUNNIT_TOKENS: 'RunnitTokens',
    FUNDS: 'Funds',
    SUBSCRIPTION: 'Subscription',
};
exports.PRODUCT_DETAILS_MAP = {
    [exports.STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRODUCT_ID]: {
        name: 'Runnit Tokens',
        category: exports.ITEM_CATEGORY_MAP.RUNNIT_TOKENS,
    },
    [exports.ADD_FUNDS_PRODUCT_ID]: {
        name: 'Add Funds',
        category: exports.ITEM_CATEGORY_MAP.FUNDS,
    },
    [clubOffers_1.CLUB_OFFER_PLAN_INFO['CC-001'].productId]: {
        name: 'Creator\'s Club',
        category: exports.ITEM_CATEGORY_MAP.SUBSCRIPTION,
    },
};
