import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mt-8 mb-12 px-9 pb-9 pt-7",class:{
		'elevation-0': _vm.selectionMode,
	}},[((
			!_vm.selectionMode &&
			!_vm.loadingUser &&
			!_vm.loadingTeam &&
			!_vm._get(_vm.team, 'isActive') &&
			_vm.$vuetify.breakpoint.mdAndUp
		))?_c('div',{staticClass:"teaser-arrow"}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-start flex-wrap mb-6"},[_c(VCardTitle,{staticClass:"pt-0 px-0 mt-0",style:({
				fontSize: _vm.$vuetify.breakpoint.xsOnly ? '17px': '20px',
			})},[_vm._v(" What do you want to launch? ")])],1),(_vm.loading)?_c('loading-state'):(_vm.hasError)?_c('error-state'):(_vm.appOffers.length)?_c('div',{staticClass:"d-flex"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VList,{staticStyle:{"margin":"10px 0 0 0","position":"sticky","top":"100px","left":"0","max-width":"200px","height":"fit-content"}},[_c(VListItemGroup,{attrs:{"value":_vm.intersectAppKey}},_vm._l((_vm.appOffers),function(appOffer){return _c(VListItem,{key:appOffer.id,staticClass:"v-list-item-shaped-right",attrs:{"value":appOffer.app},on:{"click":function($event){return _vm.scrollToAppOffer(appOffer.app)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(appOffer.shortLabel))])],1)],1)}),1)],1):_vm._e(),_c(VRow,_vm._l((_vm.appOffers),function(appOffer){return _c(VCol,{key:appOffer.id,attrs:{"id":`app-offer-${appOffer.app}`,"cols":"12"}},[_c('app-offer-card',{attrs:{"app-offer":appOffer},on:{"on-intersect":function($event){return _vm.onAppOfferCardIntersect(appOffer)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('base-button',{staticClass:"full-width",staticStyle:{"max-width":"350px"},attrs:{"large":""},on:{"click":function($event){return _vm.onAppOfferSelect(appOffer)}}},[_vm._v(" Select ")])]},proxy:true}],null,true)})],1)}),1)],1):_c('empty-state',{attrs:{"message":"No options available, please try again later or contact us"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }