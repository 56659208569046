
import Vue from 'vue';
import {
	RunnitNodeDefTagType,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RunnitNodeDefTag,
} from '@run-diffusion/shared';
import { mapState } from 'vuex';
import { TRAINING_TYPE_CARDS } from "@/views/Runnits/constants";

export default Vue.extend({
	name: 'ModelTagTypeSelect',
	props: {
		selectedTypes: { type: Array, default: () => [] },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
		};
	},
	watch: {
		selectedTypes: {
			immediate: true,
			handler (newVal) {
				if (!newVal.length) {
					this.onSelectAll();
				}
			}
		}
	},
	computed: {
		...mapState([
			'publicRunnitNodeDefTags'
		]),
		items () {
			return this.publicRunnitNodeDefTags
				.filter((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE)
				.map((tag: RunnitNodeDefTag) => ({
					id: tag.id,
					label: TRAINING_TYPE_CARDS[tag.id].title,
					icon: TRAINING_TYPE_CARDS[tag.id].icon,
				}));
		},
		selectedTypesMap () {
			return this.selectedTypes.reduce((map: Record<RunnitNodeDefTagType, boolean>, tagType: RunnitNodeDefTagType) => ({
				...map,
				[tagType]: true,
			}), {});
		},
		isSelectAllFilledIn () {
			const allSelected = this.items.length <= this.selectedTypes.length;
			this.$emit('is-all-selected', allSelected);
			return allSelected;
		},
	},
	methods: {
		onSelectAll () {
			this.$emit('on-select', this.items.map((item: RunnitNodeDefTag) => item.id));
		},
		handleOptionSelection (selectedItem: RunnitNodeDefTag) {
			this.$emit('on-select', [selectedItem.id]);
		},
	},
});
