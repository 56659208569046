
import Vue from 'vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import { mapState } from 'vuex';
import RunnitImage from "@/views/Runnits/RunnitImage.vue";
import ImageUpload from "@/views/Runnits/RunnitSettings/ImageInput/ImageUpload.vue";
import { IMAGE_UPLOAD_MODE } from "@run-diffusion/shared";
import RunnitDynamicFieldLabelRow from "@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue";
import TrainerUploadEmptyState from "@/views/Runnits/pages/models/TrainerUploadEmptyState.vue";

export default Vue.extend({
	name: 'MultiImageSelect',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: Object, default: () => ({}) },
		adminOnly: { type: Boolean, default: false },
	},
	data () {
		return {
			IMAGE_UPLOAD_MODE,
			loadingPrompt: false,
			rules: {
				requiredImagesInput: (v: any) => {
					return (v && v.length > 0) || 'At least one image is required';
				},
			},
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		maxImages (): number {
			return this._get(this.field, 'display.maxImages') || null;
		},
		isMaxImagesReached (): boolean {
			if (!this.maxImages) return false;
			return this.value?.uploads?.length >= this.maxImages;
		},
		imagesValue (): any[] {
			return this.value?.uploads || [];
		},
	},
	methods: {
		onUploadComplete (files) {
			if (this.maxImages) {
				const currentCount: number = this.value?.uploads?.length || 0;
				const availableSlots: number = this.maxImages - currentCount;

				if (availableSlots <= 0) {
					// Already at max, don't add more
					return;
				}

				// Only add up to the max limit
				const filesToAdd: any[] = files.slice(0, availableSlots);
				const appendToExisting: any[] = (this.value?.uploads || []).concat(filesToAdd);
				this.$emit('input', { uploads: appendToExisting });
			} else {
				// No limit, add all files
				const appendToExisting: any[] = (this.value?.uploads || []).concat(files);
				this.$emit('input', { uploads: appendToExisting });
			}
		},
		removeUpload (index) {
			const uploads = (this.value?.uploads || []).filter((_, itemIndex) => itemIndex !== index);
			this.$emit('input', { uploads: uploads });
		},
		validateMaxImages (v: any[]) {
			if (!this.maxImages || !v) return true;
			return v.length <= this.maxImages || `Maximum of ${this.maxImages} images allowed`;
		},
	},
	components: {
		RunnitDynamicFieldLabelRow,
		ImageUpload,
		RunnitImage,
		TrainerUploadEmptyState,
	},
});
