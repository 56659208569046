import {
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_NODE_DEF_PROVIDER,
} from '@run-diffusion/shared';

interface DROPDOWN_ITEM {
	id: string | null;
	name: string;
}

export const PROVIDER_ITEMS: DROPDOWN_ITEM[] = [
	{
		id: null,
		name: '---',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG,
		name: '"Runnit" branded image generation pipelines',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RD,
		name: 'RunDiffusion',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.OCTOAI,
		name: 'octo.ai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNPOD,
		name: 'RunPod',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM,
		name: 'Ideogram',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.FALAI,
		name: 'FAL.ai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR,
		name: 'NicheTensor',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNWAY,
		name: 'RunwayML',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.LUMAAI,
		name: 'Lumaai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNWARE,
		name: 'Runware',
	},
];

export const NODE_TYPE_ITEMS: DROPDOWN_ITEM[] = [
	{
		id: null,
		name: '---',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
		name: 'Core',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.TEAM,
		name: 'Team',
	},
];

export const ENDPOINT_ID_ITEMS: Record<string, DROPDOWN_ITEM[]> = {
	[RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'runnit-img-gen-1',
			name: 'Runnit Apex (Image Gen)',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RD]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'cmfy-workflow',
			name: '(COMFY) Default Comfy Workflow',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.FALAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'any-sd',
			name: 'Any Stable Diffusion',
		},
		{
			id: 'creative-upscaler',
			name: 'Creative Upscaler',
		},
		{
			id: 'aura-sr-upscaler',
			name: 'AuraSR Upscaler',
		},
		{
			id: 'easel-face-swap',
			name: 'Easel Face Swap',
		},
		{
			id: 'stable-diffusion-xl',
			name: 'Stable Diffusion XL',
		},
		{
			id: 'stable-diffusion-v35-large',
			name: 'Stable Diffusion v3.5 Large',
		},
		{
			id: 'remove-background',
			name: 'Remove Background',
		},
		{
			id: 'remove-background-birefnet',
			name: 'Remove Background Birefnet',
		},
		{
			id: 'ddcolor',
			name: 'DDColor',
		},
		{
			id: 'codeformer',
			name: 'CodeFormer',
		},
		{
			id: 'leffa-tryon',
			name: 'Leffa Virtual TryOn',
		},
		{
			id: 'gemini-flash-edit',
			name: 'Gemini Flash Edit',
		},
		{
			id: 'ccsr',
			name: 'CCSR',
		},
		{
			id: 'bria-eraser',
			name: 'Bria Eraser',
		},
		{
			id: 'bria-expand',
			name: 'Bria Expand',
		},
		{
			id: 'flux-dev',
			name: 'Flux Dev',
		},
		{
			id: 'flux-dev-with-loras',
			name: 'Flux Dev with LoRAs',
		},
		{
			id: 'flux-dev-fill-with-loras',
			name: 'Flux Dev Fill with LoRAs',
		},
		{
			id: 'flux-pro',
			name: 'Flux Pro',
		},
		{
			id: 'flux-pro-1-1',
			name: 'Flux Pro 1.1 (Blueberry)',
		},
		{
			id: 'flux-pro-v1-1-redux',
			name: 'Flux Pro 1.1 Redux',
		},
		{
			id: 'flux-pro-v1-canny',
			name: 'Flux Pro Canny',
		},
		{
			id: 'flux-pro-v1-depth',
			name: 'Flux Pro Depth',
		},
		{
			id: 'flux-pro-v1-fill',
			name: 'Flux Pro Fill',
		},
		{
			id: 'flux-pro-v1-redux',
			name: 'Flux Pro Redux',
		},
		{
			id: 'flux-pro-v1-1-ultra',
			name: 'Flux Pro Ultra',
		},
		{
			id: 'flux-pro-v1-1-ultra-redux',
			name: 'Flux Pro Ultra Redux',
		},
		{
			id: 'flux-schnell',
			name: 'Flux Schnell',
		},
		{
			id: 'flux-lora-fast-training',
			name: 'Flux LoRa Fast Training',
		},
		{
			id: 'runway-gen-3-turbo',
			name: 'Runway Gen3 (turbo)',
		},
		{
			id: 'recraft-v3',
			name: 'Recraft v3',
		},
		{
			id: 'juggernaut-base-flux',
			name: 'Juggernaut Base Flux',
		},
		{
			id: 'juggernaut-base-flux-lora',
			name: 'Juggernaut Base Flux with LoRA',
		},
		{
			id: 'juggernaut-base-flux-redux',
			name: 'Juggernaut Base Flux Redux',
		},
		{
			id: 'juggernaut-pro-flux',
			name: 'Juggernaut Pro Flux',
		},
		{
			id: 'juggernaut-pro-flux-redux',
			name: 'Juggernaut Pro Flux Redux',
		},
		{
			id: 'juggernaut-lightning-flux',
			name: 'Juggernaut Lightning Flux',
		},
		{
			id: 'juggernaut-lightning-flux-redux',
			name: 'Juggernaut Lightning Flux Redux',
		},
		{
			id: 'kling-standard-1-0',
			name: 'Kling 1.0 (standard)',
		},
		{
			id: 'kling-standard-1-6',
			name: 'Kling 1.6 (standard)',
		},
		{
			id: 'kling-pro-1-0',
			name: 'Kling 1.0 (pro)',
		},
		{
			id: 'kling-pro-1-5',
			name: 'Kling 1.5 (pro)',
		},
		{
			id: 'kling-pro-1-6',
			name: 'Kling 1.6 (pro)',
		},
		{
			id: 'minimax-video',
			name: 'MiniMax Video',
		},
		{
			id: 'hunyuan-video',
			name: 'Hunyuan Video',
		},
		{
			id: 'luma-dream-machine',
			name: 'Luma Dream Machine',
		},
		{
			id: 'luma-dream-machine-ray-2',
			name: 'Luma Dream Machine Ray 2',
		},
		{
			id: 'veo2',
			name: 'Veo2',
		},
		{
			id: 'wan-video',
			name: 'Wan Video',
		},
		{
			id: 'wan-pro',
			name: 'Wan Pro',
		},
		{
			id: 'wan-effects',
			name: 'Wan Effects',
		},
		{
			id: 'pikaffects',
			name: 'Pikaffects',
		},
		{
			id: 'skyreels-video',
			name: 'Skyreels Video',
		},
		{
			id: 'ltx-video',
			name: 'LTX Video',
		},
		{
			id: 'haiper-25',
			name: 'Haiper 2.5',
		},
		{
			id: 'cogvideo-5b',
			name: 'CogVideoX-5B',
		},
		{
			id: 'mochi-v1',
			name: 'Mochi v1 Video',
		},
		{
			id: 'transpixar',
			name: 'Transpixar Video',
		},
		{
			id: 'pixverse-video',
			name: 'Pixverse Video',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.OCTOAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'stable-diffusion-xl',
			name: 'Stable Diffusion XL',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNWARE]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'generate',
			name: 'Generate',
		},
		{
			id: 'controlnet-preprocess',
			name: 'ControlNet Preprocess',
		},
		{
			id: 'controlnet-generation',
			name: 'ControlNet Generation',
		},
		{
			id: 'background-removal',
			name: 'Background Removal',
		},
		{
			id: 'emoji',
			name: 'Emoji Generator',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNPOD]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'fooocus',
			name: 'Foooocus',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'generate',
			name: 'Generate',
		},
		{
			id: 'edit',
			name: 'Edit',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'dalle',
			name: 'DallE',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNWAY]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'image_to_video',
			name: 'Image To Video',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.LUMAAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'video_generation',
			name: 'Video Generation',
		},
	],
};
