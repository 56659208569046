import { VCol } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.field.display.mode === 'TRAINER')?_c('div',[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field,"adminOnly":_vm.adminOnly},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"flex flex-column"},[_c('image-upload',{staticStyle:{"padding-bottom":"30px"},attrs:{"mode":_vm.IMAGE_UPLOAD_MODE.RUNNIT_UPLOAD,"multiple":"","disabled":_vm.isMaxImagesReached},on:{"on-upload-complete":_vm.onUploadComplete}}),(!_vm.value || !_vm.value.uploads || !_vm.value.uploads.length)?_c('trainer-upload-empty-state'):[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('base-button',{staticClass:"mr-2",attrs:{"secondary-primary":""},on:{"click":() => _vm.$emit('input', { uploads: [] })}},[_vm._v(" Clear All ")]),(_vm.maxImages)?_c('div',{staticStyle:{"font-size":"14px","color":"var(--grey-400)"}},[_vm._v(" "+_vm._s(_vm.value.uploads.length)+" / "+_vm._s(_vm.maxImages)+" images ")]):_vm._e()],1),_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade","tag":"div"}},_vm._l((_vm.value.uploads),function(upload,index){return _c(VCol,{key:`image-selector-${upload.id}-${index}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":12,"sm":6,"md":4,"lg":3}},[_c('runnit-image',{attrs:{"upload":upload,"clearable":"","is-thumb":"","no-click":""},on:{"on-clear":function($event){return _vm.removeUpload(index)}}})],1)}),1)]],2)]},proxy:true}],null,false,1416238029)}),_c(VTextField,{staticClass:"pl-2 pt-2 pr-0 pb-0 my-0 rules-only-validation-text-field",class:_vm.field.required ? 'required' : '',attrs:{"rules":[
			...(_vm.field.required ? [_vm.rules.requiredImagesInput] : []),
			_vm.validateMaxImages,
		],"value":_vm.imagesValue,"readonly":"","required":"","hide-details":"auto","single-line":""}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }