
import Vue from 'vue';
import RunnitCarousel from './RunnitCarousel.vue';
import { RUNNIT_NODE_DEF_TAG_TYPE, RunnitNodeDefTagType } from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from './constants';
import { mapState } from 'vuex';
export default Vue.extend({
  name: 'RunnitTagFilterCarousel',
  props: {
    tags: { type: Array, default: () => [] },
    selectedTagsMap: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState([
      'runnitState',
      'team',
      'user',
    ]),
    filteredTags () {
      const excludedTypes: RunnitNodeDefTagType[] = [
        RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE,
        RUNNIT_NODE_DEF_TAG_TYPE.INDUSTRY,
        RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE,
        RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY,
        RUNNIT_NODE_DEF_TAG_TYPE.INTERNAL,
      ];
      if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER || !this.team) {
        excludedTypes.push(RUNNIT_NODE_DEF_TAG_TYPE.TEAM);
      }
      return this.tags.filter(tag => this.user.isAdmin || !excludedTypes.includes(tag.type));
    },
  },
  components: {
    RunnitCarousel,
  },
});
