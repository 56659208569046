
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Runnit Mark Color Logo',
		},
		width: {
			type: [Number, String],
			default: 239,
		},
		height: {
			type: [Number, String],
			default: 239,
		},
		viewBox: {
			type: String,
			default: '0 0 239 239',
		},
	},
});
