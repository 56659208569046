
import { SELECTED_COLUMN_MENU_ITEMS, SELECTED_COLUMNS_MAP, } from '@/views/Runnits/constants';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import BaseStyledMenu from '../base/BaseStyledMenu.vue';
import GlassButton from '../base/GlassButton.vue';

export default Vue.extend({
	name: 'SelectedColumnsButton',
	props: {
		iconOnly: { type: Boolean, default: false },
		iconColor: { type: String, default: 'rgba(255, 255, 255, 0.7)' },
		useRunnitPageStyles: { type: Boolean, default: false },
	},
	data () {
		return {
			selectColumnsMap: SELECTED_COLUMNS_MAP,
			selectedColumnsMenuItems: SELECTED_COLUMN_MENU_ITEMS,
		}
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onSelectColumns (selectedColumns: number) {
			this.updateRunnitState({
				selectedColumns: selectedColumns,
			});
		},
	},
	components: {
		BaseStyledMenu,
		GlassButton,
	},
});
