import {BaseFile} from "@run-diffusion/shared";
import {storageRunnit1} from "@/firebase";
import { v4 as uuidv4 } from 'uuid';

export default async (userId: string, teamId: string | null | undefined, file: File, onProgress?: (progress: number) => void): Promise<BaseFile> => {
	// Upload file
	const { name, type, size } = file;
	// append hash to filename but before the extension
	const filename = appendUuidToFilename(name, uuidv4());

	const filePathPrefix = teamId
		? `runnitModels/teams/${teamId}/uploads/`
		: `runnitModels/users/${userId}/uploads/`

	const filePath: string = `${filePathPrefix}${filename}`;

	const fileRef = storageRunnit1.ref(filePath);
	const uploadTask = fileRef.put(file);

	await new Promise<void>((resolve, reject) => {
		uploadTask.on('state_changed',
			(snapshot) => {
				// Track upload progress if callback provided
				if (onProgress) {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					onProgress(progress);
				}
			},
			(error) => {
				// Handle upload error
				reject(error);
			},
			async () => {
				resolve();
			}
		);
	});

	return {
		name: filename,
		contentType: type || 'application/octet-stream',
		size: size,
		hashHex: null,
	};

}

function appendUuidToFilename(filename: string, uuid: string) {
	// Find the position of the last dot in the filename
	const dotIndex = filename.lastIndexOf('.');

	// If the filename has an extension (dot found and not the first character)
	if (dotIndex > 0 && dotIndex < filename.length - 1) {
		const baseName = filename.slice(0, dotIndex);
		const extension = filename.slice(dotIndex);
		return `${baseName}-${uuid}${extension}`;
	} else {
		// No recognized extension found, just append UUID
		return `${filename}-${uuid}`;
	}
}
