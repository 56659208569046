import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('base-styled-menu',{attrs:{"value":_vm.runnitState.selectedColumns,"menu-items":_vm.selectedColumnsMenuItems,"menu-width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px'},on:{"input":_vm.onSelectColumns},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [(_vm.useRunnitPageStyles)?_c('div',_vm._g({staticClass:"runnit-page-setting",style:({
						...(_vm.iconOnly && {
							background: 'transparent',
							border: 'none',
						}),
						margin: _vm.iconOnly ? '0 0 0 0' : '0 7px 0 0',
						padding: _vm.iconOnly ? '0' : '0 5px 0 8px',
						width: _vm.iconOnly ? '30px' : '70px',
					})},{ ...tooltipOn, ...on }),[_c('div',{staticStyle:{"color":"var(--grey-300)","padding":"0 3px 0 2px"}},[_vm._v(" "+_vm._s(_vm.selectColumnsMap[_vm.runnitState.selectedColumns])+" ")]),_c(VIcon,{style:({ color: _vm.iconColor })},[_vm._v("mdi-format-columns")]),_c(VIcon,{staticStyle:{"margin":"0 0 0 3px"},style:({ color: _vm.iconColor }),attrs:{"small":""}},[_vm._v("mdi-menu-down")])],1):_c('glass-button',_vm._g({staticClass:"image-gallery-action",attrs:{"small":""}},on),[_c('div',{staticStyle:{"color":"var(--grey-300)","padding":"0 3px 0 2px"}},[_vm._v(" "+_vm._s(_vm.selectColumnsMap[_vm.runnitState.selectedColumns])+" ")]),_c(VIcon,{staticStyle:{"font-size":"20px"}},[_vm._v("mdi-format-columns")]),_c(VIcon,{staticStyle:{"font-size":"20px","margin":"0 -5px 0 3px"}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)})]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" Number of Columns ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }