
import Vue from 'vue';
import {
	RunnitNodeDefTagType,
	READABLE_RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE_ICON,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from './constants';
import { mapState } from 'vuex';
import RunnitCarousel from './RunnitCarousel.vue';

export default Vue.extend({
	name: 'RunnitTagTypeTabs',
	props: {
		value: { type: String, default: null },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'team',
			'user',
		]),
		items () {
			return Object.values(RUNNIT_NODE_DEF_TAG_TYPE).filter((tagType: RunnitNodeDefTagType) => {
				const excludedTypes: RunnitNodeDefTagType[] = [
					RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE,
					RUNNIT_NODE_DEF_TAG_TYPE.INDUSTRY,
					RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE,
					RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY,
					RUNNIT_NODE_DEF_TAG_TYPE.INTERNAL,
				];
				if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER || !this.team) {
					excludedTypes.push(RUNNIT_NODE_DEF_TAG_TYPE.TEAM);
				}
				return this.user.isAdmin || !excludedTypes.includes(tagType);
			}).map((t: RunnitNodeDefTagType, i: number) => {
				return {
					id: i + 1,
					label: READABLE_RUNNIT_NODE_DEF_TAG_TYPE[t],
					tagType: t,
					icon: RUNNIT_NODE_DEF_TAG_TYPE_ICON[t]
				}
			})
		},
	},
	methods: {
		handleTabChange (category) {
			this.$emit('on-select', category)
		}
	},
	components: {
		RunnitCarousel
	},
});
