import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('base-styled-menu',{attrs:{"is-open":_vm.isMenuOpen,"value":_vm.promptMagicMode,"menu-items":_vm.promptMagicMenuItems,"menu-width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '350px'},on:{"is-open-input":function($event){_vm.isMenuOpen = $event},"input":_vm.onSelectPromptMagicMode},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_c('runnit-setting-helper-btn',_vm._g({staticStyle:{"margin":"0 7px 0 0"},attrs:{"tooltip":"Enhance your prompt with Prompt Magic"}},on),[_c(VIcon,{staticClass:"mr-1",staticStyle:{"font-size":"18px"},style:({ color: _vm.iconColor })},[_vm._v(_vm._s(_vm.currentIcon))]),_c('b',{staticClass:"mr-1",staticStyle:{"font-size":"12px"},style:({ color: _vm.iconColor })},[_vm._v(" "+_vm._s(_vm.promptMagicModeDisplay)+" ")]),_c(VIcon,{staticStyle:{"font-size":"18px","margin":"0 0 0 3px"},style:({ color: _vm.iconColor })},[_vm._v("mdi-menu-down")])],1)]}},{key:"appendContent",fn:function(){return [_c(VList,{staticStyle:{"position":"relative","margin-top":"15px","padding":"5px 0 10px 0"},style:({
					background: 'var(--grey-900)',
					width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '350px',
				})},[_c(VListItem,{staticClass:"subheader"},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticStyle:{"font-size":"13px","color":"var(--grey-500)"}},[_vm._v(" See Prompt Magic in action? ")])])],1)],1),_c(VListItem,{on:{"click":_vm.openPromptGenerator}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-auto-fix")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-size":"15px"}},[_c('b',[_vm._v("Enhance My Prompt")])]),_c(VListItemSubtitle,{staticStyle:{"font-size":"10px","letter-spacing":"0.4px","white-space":"normal","word-wrap":"break-word"}},[_vm._v(" Let Prompt Magic take your prompt and show you a few enhanced versions right now. ")])],1)],1)],1)]},proxy:true}])}),_c('prompt-generator',{attrs:{"prompt-value":_vm.promptValue,"prompt-field":_vm.promptField},on:{"on-use-enhanced-prompt":_vm.onUseEnhancedPrompt},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_c('div',_vm._g({},on))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }