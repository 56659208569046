import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.textareaOnly)?_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field,"adminOnly":_vm.adminOnly},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex full-width justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('runnit-setting-helper-btn',{staticClass:"mr-1 ml-4",attrs:{"min-width":"35px","disabled":_vm.loadingPrompt,"loading":_vm.loadingPrompt,"tooltip":"Generate random prompt"},on:{"click":_vm.handleGenerateRandomPrompt}},[_c(VIcon,{staticStyle:{"font-size":"18px","color":"var(--grey-450)"}},[_vm._v(" mdi-dice-multiple ")])],1),_c(VSlideXReverseTransition,[(_vm.promptStringValue)?_c('runnit-setting-helper-btn',{staticClass:"mr-1",attrs:{"min-width":"35px","disabled":_vm.loadingPrompt,"tooltip":"Clear prompt"},on:{"click":function($event){return _vm.onPromptStringValueInput(null)}}},[_c(VIcon,{staticStyle:{"font-size":"18px","color":"var(--grey-450)"}},[_vm._v(" mdi-cancel ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('prompt-magic-button',{attrs:{"prompt-value":_vm.value,"prompt-field":_vm.field},on:{"on-prompt-input":function($event){return _vm.$emit('input', $event)}}})],1)])]},proxy:true}],null,false,4266141369)}):_vm._e(),_c(VTextarea,{class:{
			required: !!_vm.field.required,
		},attrs:{"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"dense":"","filled":"","flat":"","solo-inverted":"","auto-grow":"","rows":"3","loading":_vm.loadingPrompt,"disabled":_vm.loadingPrompt,"hide-details":"auto","counter":_vm.promptStringValue && _vm.promptStringValue.length >= _vm.DEFAULT_SHOW_TEXT_COUNTER_AT ? _vm.DEFAULT_TEXT_MAX_LENGTH : null,"rules":_vm.field.required ? _vm.rules.requiredTextField : _vm.rules.optionalTextField,"value":_vm.promptStringValue},on:{"input":_vm.onPromptStringValueInput}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }