
import Vue from 'vue';
import lottie from 'lottie-web';

export default Vue.extend({
	data() {
		return {
			animInstance: null as any
		};
	},
	mounted() {
		this.initAnimation();
	},
	beforeDestroy() {
		// Clean up animation when component is destroyed
		if (this.animInstance) {
			this.animInstance.destroy();
		}
	},
	methods: {
		initAnimation() {
			const container = this.$refs.container as HTMLElement;
			
			// Destroy previous instance if it exists
			if (this.animInstance) {
				this.animInstance.destroy();
			}
			
			// Create new animation instance with proper sizing
			this.animInstance = lottie.loadAnimation({
				container: container,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: require('../assets/runnit-lottie-animation-colored.json')
			});
			
			// Force correct size
			this.resizeAnimation();
		},
		resizeAnimation() {
			const container = this.$refs.container as HTMLElement;
			if (container) {
				// Set container dimensions
				container.style.width = `${this.width}px`;
				container.style.height = `${this.height}px`;
				
				// Force svg to match container size if animation is loaded
				if (this.animInstance && container.querySelector('svg')) {
					const svg = container.querySelector('svg');
					if (svg) {
						svg.setAttribute('width', `${this.width}px`);
						svg.setAttribute('height', `${this.height}px`);
						svg.style.width = `${this.width}px`;
						svg.style.height = `${this.height}px`;
					}
				}
			}
		}
	},
	watch: {
		width() {
			this.$nextTick(() => {
				this.resizeAnimation();
			});
		},
		height() {
			this.$nextTick(() => {
				this.resizeAnimation();
			});
		}
	},
	props: {
		// iconName: {
		// 	type: String,
		// 	default: 'Loading Animation',
		// },
		// width: {
		// 	type: [Number, String],
		// 	default: 200,
		// },
		height: {
			type: [Number, String],
			default: 200,
		},
		width: {
			type: [Number, String],
			default: 200,
		},
		// viewBox: {
		// 	type: String,
		// 	default: '0 0 100 100',
		// },
		// fill: {
		// 	type: String,
		// 	default: '#6f86ff',
		// },
	},
});
