"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLUB_TOKEN_ADD_ON_INFO = exports.CLUB_LIMIT_ADD_ON_INFO = exports.CLUB_OFFER_PLAN_INFO = void 0;
exports.CLUB_OFFER_PLAN_INFO = {
    ['CC-001']: {
        club: 'CC-001',
        productId: 'prod_MzTJ7reVi7aBLZ',
    },
    ['CC-002']: {
        club: 'CC-002',
        productId: 'prod_QzlQdITQKVCeeW',
    },
    ['FREE-001']: {
        club: 'FREE-001',
        productId: null,
    },
    ['RUNHOB-001']: {
        club: 'RUNHOB-001',
        productId: 'prod_Ql30D9oNv9lphX',
    },
    ['RUNPRO-001']: {
        club: 'RUNPRO-001',
        productId: 'prod_QkrQyBFiY1B3G6',
    },
};
exports.CLUB_LIMIT_ADD_ON_INFO = {
    ['LIMITFREE-001']: {
        id: 'LIMITFREE-001',
        productId: null,
    },
    ['LIMITRUNHOB-001']: {
        id: 'LIMITRUNHOB-001',
        productId: 'prod_QmeXgXme6DG2sj',
    },
    ['LIMITRUNPRO-001']: {
        id: 'LIMITRUNPRO-001',
        productId: 'prod_QmeWIPlcQ48ux3',
    },
    ['LIMITRUNPRO-002']: {
        id: 'LIMITRUNPRO-002',
        productId: 'prod_QlBkIGC6nW9DJW',
    },
};
exports.CLUB_TOKEN_ADD_ON_INFO = {
    ['TOKENFREE-001']: {
        id: 'TOKENFREE-001',
        productId: null,
    },
    ['TOKENRUNHOB-001']: {
        id: 'TOKENRUNHOB-001',
        productId: 'prod_QmeggHBQdw6r6S',
    },
    ['TOKENRUNPRO-001']: {
        id: 'TOKENRUNPRO-001',
        productId: 'prod_QlC5jlJcgKY5Ea',
    },
    ['TOKENRUNPRO-002']: {
        id: 'TOKENRUNPRO-002',
        productId: 'prod_Qmed1rj5GWyWrS',
    },
    ['TOKENRUNPRO-003']: {
        id: 'TOKENRUNPRO-003',
        productId: 'prod_RfM9vTdP5xysk2',
    },
    ['TOKENRUNPRO-004']: {
        id: 'TOKENRUNPRO-004',
        productId: 'prod_RoIk1zc8b3CLj8',
    },
};
