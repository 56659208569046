
import Vue from 'vue';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { mapActions, mapState } from 'vuex';
import {
	RunnitNodeRun,
	RunnitNodeRunResult,
	RUNNIT_NODE_RUN_RESULT_TYPE,
	RUNNIT_NODE_RUN_STATE,
	RUNNIT_NODE_FIELD_TYPE,
	getRunnitNodeRunResultFilePath,
} from '@run-diffusion/shared';
import { SELECTED_IMAGE_MODE } from '@/views/Runnits/constants';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import GlassButton from '@/components/base/GlassButton.vue';
import ImageInfo from '@/components/ImageGallery/ImageInfo.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import RunnitResult from './RunnitResult.vue';
import { db, storage } from '@/firebase';
import { ROUTER } from '@/router/constants';
/* global firestore */
import firebase from 'firebase/app';
import FieldValue = firebase.firestore.FieldValue;
import RunnitDialog from './base/RunnitDialog.vue';
import ListItem from '@/components/base/ListItem.vue';
import BaseToggle from '@/components/base/BaseToggle.vue';
import { RunnitsCRUDMixin } from '@/mixins/RunnitsCRUDMixin';

interface RunnitNodeRunResultWithNodeRun {
	nodeRun: RunnitNodeRun;
	nodeRunResult: RunnitNodeRunResult;
}

export default Vue.extend({
	name: 'RunnitImageInfoCarouselDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		nodeRun: { type: Object, default: null },
		nodeRuns: { type: Array, default: null },
		nodeRunResult: { type: Object, default: null },
		resultFilter: { type: Function, default: (result) => true }, // if true allow the result to be displayed
	},
	data () {
		return {
			RUNNIT_NODE_RUN_RESULT_TYPE,
			RUNNIT_NODE_RUN_STATE,
			// Observed dimensions
			dimensions: {
				width: 0,
				height: 0,
			},
			vuetify: this.$vuetify,
			touchStartX: 0,
			touchEndX: 0,

			deleteConfirmOpen: false,
			deletingImage: false,
			downloadingImage: false,
			updatingPublicStatus: false,
			shareDialogOpen: false,
			srcIsFetching: false,
		};
	},
	mixins: [
		RunnitBulkActionsMixin,
		RunnitsCRUDMixin,
	],
	created () {
		// Add event listeners for keydown when the component is created
		document.addEventListener('keydown', this.handleKeyDown);
		// Add event listeners for touch events
		document.addEventListener('touchstart', this.handleTouchStart);
		document.addEventListener('touchend', this.handleTouchEnd);
	},
	destroyed () {
		// Remove event listeners for keydown when the component is destroyed
		document.removeEventListener('keydown', this.handleKeyDown);
		// Remove event listeners for touch events
		document.removeEventListener('touchstart', this.handleTouchStart);
		document.removeEventListener('touchend', this.handleTouchEnd);
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
			'team',
			'teamUsers',
		]),
		isPublic () {
			return this._get(this.nodeRunResult, 'isPublic');
		},
		computedMaxWidth () {
			let maxWidth: string = '90%';

			if (this.$vuetify.breakpoint.mdAndUp) {
				maxWidth = '85%';
			}

			if (this.$vuetify.breakpoint.width >= 2130) {
				maxWidth = '1810px';
			}

			return maxWidth;
		},
		resultsWithNodeRun () {
			let results: RunnitNodeRunResultWithNodeRun[] = [];
			if (this.runnitState.selectedImageMode === SELECTED_IMAGE_MODE.TILED) {
				// Sort nodeRuns by creation date to maintain consistent order
				const sortedNodeRuns = [...(this.nodeRuns || [])].sort((a, b) => {
					return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
				});

				sortedNodeRuns.forEach((nodeRun) => {
					// Sort results within each nodeRun by creation date
					const sortedResults = [...(nodeRun.results || [])].sort((a, b) => {
						return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
					});

					sortedResults.forEach((result) => {
						if (this.resultFilter(result)) {
							results.push({
								nodeRun,
								nodeRunResult: result,
							});
						}
					});
				});
			} else if (this.runnitState.selectedImageMode === SELECTED_IMAGE_MODE.INFO) {
				// Sort results by creation date for INFO mode
				const sortedResults = [...(_get(this.nodeRun, 'results') || [])].sort((a, b) => {
					return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
				});

				sortedResults.forEach((result) => {
					if (this.resultFilter(result)) {
						results.push({
							nodeRun: this.nodeRun,
							nodeRunResult: result,
						});
					}
				});
			}

			return results;
		},
		currNodeRunResultIndex () {
			if (!this.resultsWithNodeRun.length) return null;
			let foundIndex: number = null;
			this.resultsWithNodeRun.some(({ nodeRunResult }, index: number) => {
				if (nodeRunResult.uuid === _get(this.nodeRunResult, 'uuid')) {
					foundIndex = index;
					return true;
				}
				return false;
			});
			return foundIndex;
		},
		resultIndexInNodeRun () {
			return (this.nodeRun?.results || []).findIndex((result) => result.uuid === this.nodeRunResult.uuid);
		},
		canManageSharing (): boolean {
			if (!this.user || !this.nodeRun) return false;

			// Check if user is admin
			if (this.user.isAdmin) return true;

			// Check if user owns the runnitNodeRun
			if (this.nodeRun.userId === this.user.id) return true;

			// Check if user is team admin for the runnitNodeRun's team
			if (
				this.nodeRun.teamId && // teamId is set
				this.user.teamIds?.[this.nodeRun.teamId] && // user is in team
				this.team.id === this.nodeRun.teamId && // team is the same as the nodeRun's team
				this.isTeamAdmin // user is a team admin
			) {
				return true;
			}

			return false;
		},
		canBeFeatured (): boolean {
			if (!this.nodeRun || !this.nodeRun.nodeDef) return false;

			// Check if it's a team tool
			if (this.nodeRun.nodeDef?.teamIds && !_isEmpty(this.nodeRun.nodeDef.teamIds)) return false;

			// Check if any model was used
			const modelFieldDefUuid: string = this.getFieldDefUuidByFieldType(this.nodeRun.nodeDef.fields, RUNNIT_NODE_FIELD_TYPE.MODEL_SINGLE_SELECT);
			const model = this.nodeRun.inputs[modelFieldDefUuid];
			if (model) return false;

			return true;
		},
	},
	methods: {
		...mapActions([
			'incrementImageZoomResetTrigger',
			'updateSnackbar',
			'updateRunnitState',
		]),
		setOpen (val: boolean) {
			if (this.value !== val) {
				this.$emit('input', !!val);
			}

			if (!val) {
				this.incrementImageZoomResetTrigger();
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onSelectionChosen () {
			this.onCancel();
		},
		hasPrevious () {
			return !(this.currNodeRunResultIndex <= 0)
		},
		onPrevClick () {
			if (!this.hasPrevious()) return;

			this.$emit('on-prev-or-next-result', this.resultsWithNodeRun[this.currNodeRunResultIndex - 1]);
		},
		hasNext () {
			return !(this.currNodeRunResultIndex >= (this.resultsWithNodeRun.length - 1));
		},
		onNextClick () {
			if (!this.hasNext()) return;

			this.$emit('on-prev-or-next-result', this.resultsWithNodeRun[this.currNodeRunResultIndex + 1]);
		},
		handleKeyDown (event) {
			switch (event.key) {
				case 'ArrowLeft':
					this.handleLeftArrow();
					break;
				case 'ArrowRight':
					this.handleRightArrow();
					break;
			}
		},
		handleLeftArrow () {
			this.onPrevClick();
		},
		handleRightArrow () {
			this.onNextClick();
		},
		handleTouchStart (event: TouchEvent) {
			this.touchStartX = event.changedTouches[0].screenX;
		},
		handleTouchEnd (event: TouchEvent) {
			this.touchEndX = event.changedTouches[0].screenX;
			this.handleSwipe();
		},
		handleSwipe () {
			const swipeThreshold = 50; // Minimum distance (in pixels) to trigger a swipe
			const swipeDistance = this.touchEndX - this.touchStartX;

			if (Math.abs(swipeDistance) > swipeThreshold) {
				if (swipeDistance > 0) {
					this.handleRightSwipe();
				} else {
					this.handleLeftSwipe();
				}
			}
		},
		handleLeftSwipe () {
			this.onNextClick();
		},
		handleRightSwipe () {
			this.onPrevClick();
		},
		async handleDownload () {
			try {
				this.downloadingImage = true;
				const { imageUrl, fileName } = await this.fetchSrc(this.nodeRunResult, 'nodeRunResult');
				if (!imageUrl || !fileName) {
					throw new Error('No image url or file name');
				}
				this.downloadImageByUrl(imageUrl, fileName);
			} catch (err) {
				console.error('Error downloading image', err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Issue downloading image`,
					show: true,
					timeout: 30000,
				});
			} finally {
				this.downloadingImage = false;
			}
		},
		setDeleteImageDialog (isOpen) {
			this.deleteConfirmOpen = isOpen;
			if (!isOpen) {
				this.$refs.doubleConfirmDangerDialog.reset();
			}
		},
		async handleDelete () {
			this.deletingImage = true;
			try {
				const { success, count, reason } = await this.deleteNodeRunResultBatch([this.nodeRunResult], this.nodeRun.id);
				if (!success) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: `Issues deleting image: ${reason}`,
						show: true,
						timeout: 30000,
					});
				} else {
					this.updateRunnitState({
						deletedImages: {
							...this.runnitState.deletedImages,
							nodeRunResults: [...this.runnitState.deletedImages.nodeRunResults, this.nodeRunResult],
						}
					});

					if (this.hasNext()) {
						this.onNextClick();
					} else if (this.hasPrevious()) {
						this.onPrevClick();
					} else {
						this.onCancel();
					}
				}
			} catch (err) {
				console.error('Error deleting image', err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Issues deleting image`,
					show: true,
					timeout: 30000,
				});
			} finally {
				this.deletingImage = false;
				this.setDeleteImageDialog(false);
			}
		},
		async handlePublicToggle (setAsPublic: boolean) {
			if (this.nodeRun.featured) return;
			try {
				this.updatingPublicStatus = true;
				await db.runTransaction(async (transaction) => {
					if (this._get(this.nodeRunResult, `isPublic`) && !setAsPublic) { // is currently public
						const noOtherResultsArePublic = !this.nodeRun.results.find((result: RunnitNodeRunResult) => result.uuid !== this.nodeRunResult.uuid && result.isPublic);
						const updatedResults = this.nodeRun.results.map((result: RunnitNodeRunResult) => {
							if (result.uuid === this.nodeRunResult.uuid) {
								return _omit(result, ['isPublic']);
							}
							return result;
						})
						transaction.update(
							db.doc(`runnitNodeRuns/${this.nodeRun.id}`),
							{
								...(noOtherResultsArePublic && { isPublic: FieldValue.delete() }),
								results: updatedResults
							},
						);
						// update local nodeRun state
						if (noOtherResultsArePublic) {
							delete this.nodeRunResult.isPublic;
						}
						this.nodeRun.results = updatedResults;
					} else if (setAsPublic) { // not currently public
						const updatedResults = this.nodeRun.results.map((result: RunnitNodeRunResult) => {
							if (result.uuid === this.nodeRunResult.uuid) {
								return { ...result, isPublic: true };
							}
							return result;
						});
						transaction.update(
							db.doc(`runnitNodeRuns/${this.nodeRun.id}`),
							{
								isPublic: true,
								results: updatedResults
							}
						);
						// update local nodeRun state
						this.nodeRunResult.isPublic = true;
						this.nodeRun.results = updatedResults;
					}
				});
			} catch (error) {
				console.error('Error changing public state', error);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Failed to update sharing status',
					show: true,
					timeout: 5000,
				});
			} finally {
				this.updatingPublicStatus = false;
				this.$emit('is-public-updated', { nodeRunResult: this.nodeRunResult, nodeRun: this.nodeRun });
			}
		},
		setShareDialogOpen (open: boolean) {
			this.shareDialogOpen = open;
		},
		getShareUrl (source?: string) {
			const remixUrl = this.$router.resolve({
				name: ROUTER.RUNNIT_REMIX,
				query: {
					rnrid: this.nodeRun.id,
					ruuid: this.nodeRunResult.uuid,
					...(source && {
						utm_source: source, // Identifies the traffic source (e.g. google, facebook, newsletter)
						utm_medium: 'remix-link', // Identifies the medium (e.g. email, banner, social media)
						utm_campaign: this.user.uuId, // Identifies the campaign name
						utm_content: `${this.nodeRun.id}-${this.nodeRunResult.uuid}`, // Used to differentiate between multiple URLs
						// utm_term: this.nodeRunResult.type, // Used to differentiate between multiple keywords in paid search campaigns (e.g. "blue dress" vs "red dress")
					}),
				},
			}).href;
			return `${window.location.origin}${remixUrl}`;
		},
		openPublicLink () {
			if (!this.nodeRunResult.isPublic) return;
			window.open(this.getShareUrl(), '_blank');
		},
		async copyPublicLink (source: string) {
			this.handlePublicToggle(true);
			if (!this.nodeRunResult.isPublic && !this.nodeRun.featured) return;
			const shareUrl = this.getShareUrl(source);
			try {
				await navigator.clipboard.writeText(shareUrl);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Share link copied to clipboard!',
					show: true,
					timeout: 3000,
				});
			} catch (error) {
				console.error('Error copying to clipboard:', error);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Failed to copy share link',
					show: true,
					timeout: 5000,
				});
			}
		},
		async shareToLinkedIn () {
			await this.handlePublicToggle(true);
			if (!this.nodeRunResult.isPublic && !this.nodeRun.featured) return;
			const shareUrl = this.getShareUrl('linkedIn');
			const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
			window.open(url, '_blank');
		},
		async shareToX () {
			await this.handlePublicToggle(true);
			if (!this.nodeRunResult.isPublic && !this.nodeRun.featured) return;
			const shareUrl = this.getShareUrl('x');
			const text = `Check out what I generated with Runnit by RunDiffusion! You can view and remix it here:`;
			const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(shareUrl)}`;
			window.open(url, '_blank');
		},
		// async shareToPinterest () {
		// 	await this.handlePublicToggle(true);
		// 	if (!this.nodeRunResult.isPublic && !this.nodeRun.featured) return;
		// 	const shareUrl = this.getShareUrl('pinterest');
		// 	const text = `Check out what I generated with Runnit by RunDiffusion! You can view and remix it here:`;

		// 	// Construct permanent URL
		// 	// const { filePath } = getRunnitNodeRunResultFilePath(this.nodeRunResult);
		// 	// const permanentUrl = `https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/${encodeURIComponent(filePath)}?alt=media`;
		// 	// const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}&description=${encodeURIComponent(text)}&media=${encodeURIComponent(permanentUrl)}`;

		// 	const {imageUrl} = await this.fetchSrc(this.nodeRunResult);
		// 	const decodedImageUrl = decodeURIComponent(imageUrl);
		// 	const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}&description=${encodeURIComponent(text)}&media=${encodeURIComponent(decodedImageUrl)}`;
		// 	window.open(url, '_blank');
		// },
		async shareToReddit () {
			await this.handlePublicToggle(true);
			if (!this.nodeRunResult.isPublic && !this.nodeRun.featured) return;
			const shareUrl = this.getShareUrl('reddit');
			const text = `Check out what I generated with Runnit by RunDiffusion! You can view and remix it here:`;
			const url = `https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(text)}`;
			window.open(url, '_blank');
		},
		async fetchSrc (input: RunnitNodeRunResult) {
			const nodeRunResult: RunnitNodeRunResult = input as RunnitNodeRunResult;

			let filePath, fileName, imageUrl;
			try {
				const filePathData: { filePath: string } = getRunnitNodeRunResultFilePath(nodeRunResult);
				filePath = filePathData.filePath;
				fileName = nodeRunResult.file.name

				if (!fileName || !filePath) {
					throw new Error('cannot find image');
				}
				const fileRef: any = storage.ref(filePath);
				imageUrl = await fileRef.getDownloadURL();
			} catch (err) {
				console.error('Error fetching image src', err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error fetching image src`,
					show: true,
					timeout: 30000,
				});
				throw err;
			}
			return { fileName, imageUrl };
		},
	},
	components: {
		GlassButton,
		ImageInfo,
		RunnitResult,
		DoubleConfirmDangerDialog,
		RunnitDialog,
		ListItem,
		BaseToggle,
	},
});
