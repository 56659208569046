import { render, staticRenderFns } from "./ModelTagTypeSelect.vue?vue&type=template&id=243fd39c&scoped=true"
import script from "./ModelTagTypeSelect.vue?vue&type=script&lang=ts"
export * from "./ModelTagTypeSelect.vue?vue&type=script&lang=ts"
import style0 from "./ModelTagTypeSelect.vue?vue&type=style&index=0&id=243fd39c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243fd39c",
  null
  
)

export default component.exports