import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-settings-label",style:({
		..._vm.labelStyles,
	})},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"runnit-settings-label-text",class:{
				'deep-orange--text': _vm.adminOnly
			},style:({
				...(_vm.$vuetify.breakpoint.smAndDown && {
					fontSize: '13px !important',
				}),
			})},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]),(_vm.field.tooltip && _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"runnit-settings-label-tooltip"},[_c(VTooltip,{attrs:{"top":"","open-delay":"500","on-mobile":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticStyle:{"font-size":"18px","color":"var(--grey-600)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,3787733311)},[_c('div',{staticClass:"tooltip-max-width",domProps:{"innerHTML":_vm._s(_vm.field.tooltip)}})])],1):_vm._e()]),_c('div',{staticClass:"runnit-settings-actions"},[_vm._t("actions")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }