
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from "vuex";
import { RUNNITS_OWNER_SELECTION, TRAINING_QUALITY_CARDS, TRAINING_TYPE_CARDS } from "@/views/Runnits/constants";
import {setRunnitsTeamAccessLevel} from "@/utils";
import {
	RUNNITS_ACCESS_LEVEL,
	RunnitsAccessLevel,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RunnitNodeDefTag,
	Model,
} from "@run-diffusion/shared";
import {ModelCRUDMixin} from "@/mixins/ModelCRUDMixin";
import {RunnitsCRUDMixin} from "@/mixins";
import OfferingCard from "@/components/OfferingCard.vue";
import ModelToolUseDialog from "@/views/Runnits/pages/models/ModelToolUseDialog.vue";
import { ROUTER } from "@/router/constants";
import ModelTagTypeTabs from './ModelTagTypeTabs.vue';
import { fuzzyFilter } from '@/utils/fuzzySearch';
import TrainerUploadEmptyState from '@/views/Runnits/pages/models/TrainerUploadEmptyState.vue';
import EmptyState from "@/components/states/EmptyState.vue";
import LoadingState from "@/components/states/LoadingState.vue";

export default Vue.extend({
	name: 'RunnitModelsListPage',
	computed: {
		...mapState([
			'user',
			'team',
			'modelsState',
			'runnitState',
			'loadingModels',
			'publicRunnitNodeDefTagsMap',
		]),
		...mapGetters('modelUpload', [
			'getUploading',
			'allUploads'
		]),
        uploadingModelsForEnvironment() {
			return this.getUploading.filter(upload =>
				this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM
					? upload.model.teamId === this.team.id && upload.model.accessLevel === this.runnitState.runnitsAccessLevel
					: !upload.model.teamId
			);
		},
		filteredModels() {
			return this.modelsState.models.filter(model => {
				// Filter by selected type
				if (this.selectedTrainingTypeTagId !== 'ALL') {
					if (!model.tags || !model.tags[this.selectedTrainingTypeTagId]) {
						return false;
					}
				}

				return true;
			}).filter(model => {
				if (!this.modelSearchValue) return true;
				return fuzzyFilter(
					[model],
					this.modelSearchValue,
					[
						{ name: 'name', weight: 0.7 },
						{ name: 'description', weight: 0.3 }
					]
				).length > 0;
			});
		},
		noModelsYet () {
			return !!(
				!this.filteredModels.length &&
				!this.uploadingModelsForEnvironment.length
			);
		},
	},
	mixins: [RunnitsCRUDMixin, ModelCRUDMixin],
	components: {
		LoadingState,
		EmptyState,
		TrainerUploadEmptyState,
		ModelToolUseDialog,
		OfferingCard,
		ModelTagTypeTabs,
	},
	props: {},
	watch: {
		isModelToolUseDialogOpen (newVal) {
			if (!newVal) {
				this.modelSelected = null;
			}
		},
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
			RUNNITS_ACCESS_LEVEL,
			RUNNITS_OWNER_SELECTION,
			TRAINING_TYPE_CARDS,
			loadingRunnits: false,
			modelList: [],
			isModelToolUseDialogOpen: false,
			modelSelected: null,
			selectedTrainingTypeTagId: 'ALL',
			modelSearchValue: '',
		};
	},
	methods: {
		...mapActions([
			'bindModels',
			'updateSnackbar',
			'updateModelState',
			'updateRunnitState',
		]),
		...mapActions('modelUpload', [
			'uploadModel',
		]),
		onModelsAccessLevelChange(accessLevel: RunnitsAccessLevel) {
			setRunnitsTeamAccessLevel(accessLevel);
			this.updateRunnitState({
				runnitsAccessLevel: accessLevel,
			});
		},
		onEditModelSettings(model) {
			if (model) {
				this.updateModelState({
					modelDraft: model,
					modelDialog: {
						...this.modelsState.modelDialog,
						open: true,
						mode: 'edit',
					},
				});
			}
		},
		onModelSelect (model) {
			this.modelSelected = model;
			this.isModelToolUseDialogOpen = true;
		},
		async goToTrainModel() {
			await this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_TRAIN_MODEL_OPTIONS });
		},
		onModelTypeSelect(tagId: string) {
			this.selectedTrainingTypeTagId = tagId;
		},
		handleSearchInput(searchValue: string) {
			this.modelSearchValue = searchValue;
		},
		getModelEstimatedTrainTime (model: Model) {
			const tags: RunnitNodeDefTag[] = Object.keys((model.tags || {})).map((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]);
			const qualityTag = tags.find((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY);
			if (qualityTag) {
				const qualityCard = TRAINING_QUALITY_CARDS[qualityTag.id];
				return qualityCard?.timeEstimate || '';
			} else {
				return 'several';
			}
		},
	}
});
