
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RUNNIT_NODE_DEF_TOOL_TYPE, RUNNITS_ACCESS_LEVEL } from '@run-diffusion/shared';
import { RunnitsCRUDMixin } from '@/mixins';
import OfferingCard from '@/components/OfferingCard.vue';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import LoadingState from '@/components/states/LoadingState.vue';
import RunnitBoardOnboardingDialog from '@/views/Runnits/pages/RunnitBoardOnboadingDialog.vue';
import { setRunnitsTeamAccessLevel } from "@/utils";
import RunnitBoardOnboardingBanner from './RunnitBoardOnboardingBanner.vue';

export default Vue.extend({
	name: 'RunnitBoardPage',
	mixins: [RunnitsCRUDMixin],
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
			RUNNITS_OWNER_SELECTION,
			RUNNITS_ACCESS_LEVEL,
			showOnboardingDialog: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitsMap',
			'runnitState',
			'loadingRunnits',
		]),
		...mapGetters([
			'runnitsLimitMap',
		]),
		showExplanationContainer(): boolean {
			return !(
                this.runnitState.runnitsOwnerSelection !== RUNNITS_OWNER_SELECTION.TEAM ||
				this.runnitState.runnitsAccessLevel === RUNNITS_ACCESS_LEVEL.PRIVATE
			);
		}
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
			'updateRunnitState',
			'unbindRunnits',
			'bindRunnits',
		]),
		async onAccessLevelChange(accessLevel) {
			setRunnitsTeamAccessLevel(accessLevel);
			await this.unbindRunnits();
			await this.updateRunnitState({
				runnitsAccessLevel: accessLevel,
			});
			await this.bindRunnits({
				userId: this.user.id,
				teamId: this.team.id,
				accessLevel,
			});
		},
	},
	components: {
		LoadingState,
		OfferingCard,
		RunnitBoardOnboardingDialog,
		RunnitBoardOnboardingBanner,
	},
});
