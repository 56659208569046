import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width"},[_c(VTooltip,{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({class:{
					'px-8': !_vm.$vuetify.breakpoint.xsOnly,
					'px-3': _vm.$vuetify.breakpoint.xsOnly
				},attrs:{"disabled":_vm.runnitButtonDisabled,"loading":_vm.runnitState.isQueuingDraftRunnitNodeRun,"super-primary":"","large":"","block":""},on:{"click":_vm.onGenerateClick}},on),[_c('div',{staticClass:"d-flex align-center justify-center full-width",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-flex align-center full-width"},[_c('div',{staticClass:"generation-label"},[_c('div',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.actionText)+" ")]),_c('div',{staticClass:"ml-3 d-flex align-center justify-center"},[_c('tokens-s-v-g',{staticStyle:{"margin":"0 3px 0 0"},attrs:{"fill":_vm.runnitButtonDisabled ? 'rgba(255, 255, 255, 0.3)' : '#000000'}}),_c('div',{staticClass:"total-run-cost",style:({
										color: _vm.runnitButtonDisabled ? 'rgba(255, 255, 255, 0.3)' : '#000000',
									})},[_vm._v(" "+_vm._s(_vm._f("currencyTokens")(_vm._f("hundredthsToTokens")(_vm.totalRunCost)))+" ")])],1)])])])])]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Shift + Enter")])]),(_vm.selectedNode && _vm.selectedNode.nodeDef.appType === _vm.RUNNIT_NODE_DEF_TOOL_APP_TYPE.TRAINER)?_c('runnit-model-training-dialog',{attrs:{"open":_vm.isModelTrainingDialogOpen}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }