
import Vue from 'vue';
import MenuOrBottomSheet from '../../../../../components/base/MenuOrBottomSheet.vue';
import { WhatsNewUpdate } from '../types';
import moment from 'moment-timezone';

export default Vue.extend({
	name: 'VersionSelector',
	components: {
		MenuOrBottomSheet
	},
	props: {
		versions: {
			type: Array as () => WhatsNewUpdate[],
			required: true
		},
		selectedVersionIndex: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			isOpen: false
		};
	},
	computed: {
		selectedVersion(): string {
			if (this.versions.length === 0) return '';
			const version = this.versions[this.selectedVersionIndex];
			return this.formatDate(version.date);
		}
	},
	methods: {
		selectVersion(index: number, close: () => void) {
			this.$emit('version-selected', index);
			close();
		},
		formatDate(date: string): string {
			if (!date) return '';
			return moment(date).format('MMMM D, YYYY');
		}
	}
});
