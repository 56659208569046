
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Announcement, ANNOUNCEMENT_TYPE} from '@run-diffusion/shared';
import CreatorsClubPromoTeaser from '@/components/PromoBanners/CreatorsClubPromoTeaser.vue';
import AppOffersList from '@/views/Sessions/AppOffersList/AppOffersList.vue';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import RunnitsPromoCard from '@/views/Runnits/RunnitsPromoCard.vue';
import MasterClassesPromoBanner from '@/components/PromoBanners/MasterClassesPromoBanner.vue';
import { ROUTER } from '@/router/constants';
import { getPlatformHomePreference, PlatformHomePreference, setPlatformHomePreference } from '@/utils';

export default Vue.extend({
	name: 'Sessions',
	data () {
		return {
			selectedStorage: null,
			dismissedNoNewSessionsAnnouncements: false,
		};
	},
	computed: {
		...mapState([
			'upsellDialog',
			'user',
			'team',
			'announcements',
			'stableDiffusionModel',
			'appQueryParamsData',
		]),
		...mapGetters([
			'is90DaysNoActivityNewUserFlow',
			'isNewUserTrialFlow',
		]),
		noNewSessionsAnnouncements () {
			return this.announcements.filter((announcement: Announcement) => (
				announcement.type === ANNOUNCEMENT_TYPE.NO_NEW_SESSIONS
			));
		},
	},
	mounted () {
		this.initCreatorsClubDialog();

		this.updateToolbar({
			insideRunnits: false,
		});

		const platformHomePreference: PlatformHomePreference = getPlatformHomePreference();
		if (
			platformHomePreference === 'RUNNIT' ||
			(
				!platformHomePreference && (
					this.is90DaysNoActivityNewUserFlow || // They have been gone long enough, that they should be considered a new user
					this.isNewUserTrialFlow // They are a new user
				)
			)
		) {
			// Automatically redirect to Runnit
			setPlatformHomePreference('RUNNIT');
			this.routerReplace(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
		}
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'updateAppQueryParamsData',
			'setUpsellDialog',
		]),
		initCreatorsClubDialog () {
			if (!this.upsellDialog.creatorsClub && this.user && this.appQueryParamsData && this.appQueryParamsData.creatorsClubSignUp) {
				// Pop Creator's Club promotional dialog
				this.setUpsellDialog({ creatorsClub: true });
				// Reset creatorsClubSignUp to false
				this.updateAppQueryParamsData({ creatorsClubSignUp: false });
			}
		},
	},
	components: {
		MasterClassesPromoBanner,
		RunnitsPromoCard,
		JoinOurDiscord,
		AppOffersList,
		CreatorsClubPromoTeaser,
	},
});
