
import Vue from 'vue';
import { ROUTER } from '@/router/constants';
import { TeamUserMixin } from '@/mixins';
import { mapState } from 'vuex';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import TeamPicker from '@/components/TeamPicker.vue';
import BackButton from '@/components/base/BackButton.vue';
import TeamPlanSignup from '@/views/Team/TeamPlanSignup.vue';
import EditTeamDialog from '@/views/Team/EditTeamDialog.vue';
import AdminTeamStats from '@/components/AdminStats/AdminTeamStats.vue';
import PageTopNavigation from '@/components/base/PageTopNavigation.vue';

export default Vue.extend({
	name: 'Team',
	mixins: [
		TeamUserMixin,
	],
	data () {
		return {
			isStillLoadingInitialPage: false,

			forceCreateNewTeam: false,

			editTeamDialogOpen: false,
		};
	},
	created () {
		if (this.team && this.team.id) {
			this.isStillLoadingInitialPage = false;
		}

		// Make sure to set it to false after providing enough time to check to see if there is a team or not
		setTimeout(() => {
			this.isStillLoadingInitialPage = false;
		}, 1000);
	},
	computed: {
		...mapState([
			'isAdminProxyingUser',
			'user',
			'team',
			'toolbar',
			'loginQueryParamsData',
		]),
		tabsData () {
			return [
				{ name: 'Home', route: ROUTER.TEAM_MEMBERS },
				{ name: 'Spaces', route: ROUTER.SPACES },
				{ name: 'Workshops', route: ROUTER.WORKSHOPS },
				...(this._get(this.team, 'features.nsfwThreshold') ? [
					{ name: 'Runnit Settings', route: ROUTER.TEAM_RUNNIT_SETTINGS },
				] : []),
			];
		},
	},
	watch: {
		team (newVal, oldVal) {
			if (newVal !== oldVal && newVal) {
				this.isStillLoadingInitialPage = false;
			}
		}
	},
	methods: {
		goToTeamReporting () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.IMAGE_GENERATIONS_REPORT });
		},
		onTeamPlanSignupBack () {
			if (this.forceCreateNewTeam) {
				this.forceCreateNewTeam = false;
			} else {
				this.goHome();
			}
		},
		goHome () {
			if (this.team && this.team.isOnboarding) {
				// unbind so that if they had belonged to more than one team, that the team page will re-query and make them reselect it when revisited
				this.unbindTeam();
				this.unbindStripeTeamsCustomer();
				this.unbindTeamBalanceAccount();
				this.unbindWorkspaces();
			}

			const hasCurrentSessionId: boolean = !!(this._get(this.toolbar, 'session.id'));

			if (hasCurrentSessionId) {
				this.routerPush(this.$route, this.$router, { name: ROUTER.LAUNCH, params: { sessionId: this._get(this.toolbar, 'session.id') } });
			} else {
				this.routerPush(this.$route, this.$router, { name: ROUTER.SESSIONS });
			}
		},
		onNewTeamBtnSelect () {
			this.forceCreateNewTeam = true;
		},
		handleEditTeamName () {
			this.editTeamDialogOpen = true;
		},
	},
	components: {
		PageTopNavigation,
		AdminTeamStats,
		TeamPicker,
		EditTeamDialog,
		TeamPlanSignup,
		LoadingSVG,
		BackButton,
	},
});
