import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-info-fields-outer-container",style:({
		...(!_vm.insideNode && {
			margin: '0 0 10px 0',
		}),
		...(_vm.insideNode && {
			margin: '0 0 20px 0',
		}),
	})},[_c('div',{staticClass:"image-attribution",style:({
			...(!_vm.insideNode && {
				margin: '30px 0 12px 0',
			}),
			...(_vm.insideNode && {
				margin: '30px 0 -12px 0',
			}),
			..._vm.imageAttributionStyles,
		})},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"overflow":"hidden"}},[(!_vm._get(_vm.nodeRun, `results[${+_vm.index}].featured`))?[_c('div',{staticClass:"first-letter-of-email"},[_vm._v(" "+_vm._s(_vm.firstLetterOfEmail)+" ")]),_c('div',{staticClass:"image-creator-label",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.nodeRun.email)+" ")])]:_vm._e()],2),(_vm.$slots.nextToImageAttribution)?_c('div',{staticClass:"d-flex align-center justify-end"},[_vm._t("nextToImageAttribution")],2):_vm._e()]),_c('div',{staticClass:"image-info-fields-top-section",style:({
			...(!_vm.insideNode && {
				background: 'var(--grey-875)',
			}),
			...(_vm.insideNode && {
				background: 'var(--grey-875)',
				margin: '25px -6px 0 -6px',
			}),
			...(_vm.isFirstIndex || _vm.insideCarousel && {
				marginTop: '10px !important',
			}),
		})},[_c('div',{staticClass:"d-flex align-center"},[(_vm.numImgs)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"image-info-number-images"},on),[_c(VIcon,{staticStyle:{"font-size":"16px","color":"var(--grey-500)"}},[_vm._v("mdi-image-multiple-outline")]),_c('div',{staticClass:"image-info-number-images-number"},[_vm._v(" "+_vm._s(_vm.numImgs)+" ")])],1)]}}],null,false,2901242283)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" "+_vm._s(_vm.numImgs)+" generations in batch ")])]):_vm._e(),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.nodeRun.createdAt.toMillis(),'MMM D, YYYY [@] hh:mm a'))+" ")])],1),(_vm.hasFeaturedAccess)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"small":"","icon":"","disabled":!_vm.canBeFeatured},on:{"click":function($event){return _vm.onFeaturedStarClick()}}},on),[(_vm.settingFeaturedState)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"20","width":"2","color":"deep-orange"}}):(_vm._get(_vm.nodeRun, `results[${+_vm.index}].featured`))?_c(VIcon,{attrs:{"color":"deep-orange"}},[_vm._v("mdi-star")]):_c(VIcon,{attrs:{"color":"deep-orange"}},[_vm._v("mdi-star-outline")])],1)]}}],null,false,2693672857)},[_c('div',[_vm._v("Mark this generation to be in the featured section on the Runnits homepage.")])]):_vm._e()],1),_c('image-info-fields',{attrs:{"fields":_vm.nodeRun.fields,"nodeRun":_vm.nodeRun,"insideDrawer":_vm.insideDrawer,"insideNode":_vm.insideNode,"insideCarousel":_vm.insideCarousel,"ignoreTruncation":_vm.ignoreTruncation,"index":_vm.index,"imageAttributionStyles":_vm.imageAttributionStyles,"resultUuid":_vm._get(_vm.nodeRun, `results[${+_vm.index}].uuid`)},on:{"close-carousel":function($event){return _vm.$emit('close-carousel', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }