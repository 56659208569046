import Fuse from 'fuse.js';

export const createFuzzySearch = <T>(
    items: T[],
    keys: Array<{ name: string; weight: number }>,
) => {
    const options = {
        includeScore: true,
        threshold: 0.4,
        distance: 100,
        keys,
    };

    return new Fuse(items, options);
};

export const fuzzyFilter = <T>(
    items: T[],
    searchText: string,
    keys: Array<{ name: string; weight: number }>,
): T[] => {
    if (!searchText) return items;

    const fuse = createFuzzySearch(items, keys);
    const results = fuse.search(searchText);

    return results.map(result => result.item);
};
