
import Vue from 'vue';
import StackLayers from '@/components/base/StackLayers.vue';
import { DISCORD_INVITE_LINK } from '@/utils';
import { mapActions, mapState } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';
import { functions } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';
import DialogOrBottomSheet from '@/components/base/DialogOrBottomSheet.vue';

export default Vue.extend({
	name: 'GetSupportDialog',
	props: {
		value: { type: Boolean, default: false },
		insideRunnits: { type: Boolean, default: false },
	},
	data () {
		return {
			isOpeningChatSupportWidgetTimeout: null,
			isOpeningChatSupportWidget: false,
			showChatSupportWidgetIssueWarning: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'isAdminProxyingUser',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		onInput (val: boolean) {
			this.$emit('input', !!val);
		},
		openLearnSite () {
			window.open('https://learn.rundiffusion.com', '_blank').focus();
		},
		onJoinDiscordServer () {
			window.open(DISCORD_INVITE_LINK, '_blank').focus();
		},
		async onStartChatSupport () {
			if (this.isAdminProxyingUser) {
				alert('You cannot open a support ticket because you are pretending as a user');
				return;
			}

			(window as any).HubSpotConversations?.widget?.close();
			this.isOpeningChatSupportWidget = true;
			if (this.isOpeningChatSupportWidgetTimeout) clearTimeout(this.isOpeningChatSupportWidgetTimeout);
			this.isOpeningChatSupportWidgetTimeout = setTimeout(() => {
				this.isOpeningChatSupportWidget = false;
				this.showChatSupportWidgetIssueWarning = true;
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error opening chat window. If error persists, please reach out to us at support@rundiffusion.com',
					show: true,
					timeout: 20000,
				});
			}, 20000);

			// Create HubSpot identification token
			let token: string = null;
			try {
				const functionRef = functions
					.httpsCallable('getSupportChatIdToken');
				const { success, token: _token } = (await functionRef({
					teamId: this.team ? this.team.id : null,
				})).data;
				if (success && _token) token = _token;
			} catch (e) {
				console.error(e);
			}

			// Settings
			(window as any).hsConversationsSettings = {
				loadImmediately: false,
				identificationEmail: this.user.email,
				...(token && {
					identificationToken: token,
				}),
			};

			// Handlers
			(window as any).hsConversationsOnReady = [
				() => {
					// Load and open the widget
					(window as any).HubSpotConversations?.widget?.load();
					(window as any).HubSpotConversations?.widget?.open();

					// Identify the user when conversation starts
					(window as any).HubSpotConversations.on('widgetLoaded', () => {
						if (this.isOpeningChatSupportWidgetTimeout) clearTimeout(this.isOpeningChatSupportWidgetTimeout);
						this.isOpeningChatSupportWidget = false;
					});

					// Listen for the widget closing and remove it
					(window as any).HubSpotConversations.on('widgetClosed', () => {
						// (window as any).HubSpotConversations?.widget?.clear({ resetWidget: true });
						(window as any).HubSpotConversations?.widget?.remove();
					});
				},
			];

			// Initialize script only if it's not already loaded
			if (!(window as any).HubSpotConversations) {
				const script = document.createElement('script');
				script.src = 'https://js.hs-scripts.com/48248774.js'; // Replace with your HubSpot ID
				script.async = true;
				script.defer = true;
				document.body.appendChild(script);
			} else {
				(window as any).HubSpotConversations?.widget?.refresh();
				(window as any).HubSpotConversations?.widget?.open();
			}
		},
	},
	components: {
		ListItem,
		StackLayers,
		DialogOrBottomSheet,
	},
});
