
import Vue from 'vue';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { functions } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import {
	SNACKBAR_STATUS,
	RUNNITS_PUBLISHED_STATE,
	RunnitsPublishedState,
} from '@/constants/constants';
import { FEATURED_RUNNIT_NODE_DEF_IDS, RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import {
	RunnitNodeDef,
	RunnitNodeDefToolType,
	UserRunnitNodeDefInfo,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE_SORT,
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_TYPE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
	User,
	RunnitNodeDefTag,
} from '@run-diffusion/shared';
import OfferingCard from '@/components/OfferingCard.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import EmptyStateCircleBackground from '@/components/states/EmptyStateCircleBackground.vue';
import RunnitDuplicateEditNodeDefInternalEditor from '@/views/Runnits/RunnitSettings/internalAdminOnly/RunnitDuplicateEditNodeDefInternalEditor.vue';
import { RunnitsCRUDMixin } from '@/mixins';
import { ROUTER } from '@/router/constants';
import DiagonalStackedSquaresSVG from '@/assets/DiagonalStackedSquaresSVG.vue';
import RunnitTagTypeSelect from './RunnitTagTypeSelect.vue';
import RunnitTagFilter from './RunnitTagFilter.vue';
import { fuzzyFilter } from '@/utils/fuzzySearch';
import DialogOrBottomSheet from '@/components/base/DialogOrBottomSheet.vue';
import RunnitNavTabs from '@/views/Runnits/base/RunnitNavTabs.vue';


export type ToolsDialogNav = 'TOOLS' | 'APPS';
export const TOOLS_DIALOG_NAV: Record<ToolsDialogNav, ToolsDialogNav> = {
	'TOOLS': 'TOOLS',
	'APPS': 'APPS',
};

export default Vue.extend({
	name: 'RunnitToolsLibraryDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '1100px' },
		singleToolMode: { type: Boolean, default: false },
	},
	mixins: [
		RunnitsCRUDMixin,
	],
	data () {
		return {
			TOOLS_DIALOG_NAV,
			RUNNITS_PUBLISHED_STATE,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			FEATURED_RUNNIT_NODE_DEF_IDS,
			open: false,
			toolSearchValue: '',
			selectedTypes: [],

			filteredRunnitNodeDefs: [],

			nodeDefInternalEditorDialog: {
				open: false,
				selectedNodeDef: null,
			},

			publishedStateFilter: RUNNITS_PUBLISHED_STATE.PUBLISHED,
			showRunnitUpsell: false,

			filtersCollapsed: false,
			selectedTagsFilterMap: {},

			nodeDefsByTagMap: {},

			isAllTypesSelected: null,

			currNavTab: null,
			toolsDialogNavTabs: [
				{
					id: TOOLS_DIALOG_NAV.TOOLS,
					label: 'Tools',
					icon: 'mdi-tools',
				},
				{
					id: TOOLS_DIALOG_NAV.APPS,
					label: 'Apps',
					icon: 'mdi-apps',
				},
			],
		};
	},
	computed: {
		...mapState([
			'user',
			'runnits',
			'loadingRunnits',
			'loadingRecentUserRunnitNodeDefInfos',
			'recentUserRunnitNodeDefInfos',
			'runnitState',
			'publicRunnitNodeDefs',
			'loadingPublicRunnitNodeDefs',
			'teamRunnitNodeDefs',
			'loadingTeamRunnitNodeDefs',
			'publicRunnitNodeDefTagsMap',
			'teamRunnitNodeDefTagsMap',
		]),
		typedUser (): User {
			return this.user as User;
		},
		visibleRunnitNodeDefInfos () {
			return this.recentUserRunnitNodeDefInfos.filter((toolInfo: UserRunnitNodeDefInfo) => !toolInfo.nodeDef.isDeleted &&
				(
					this.typedUser &&
					toolInfo.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT &&
					(
						this.typedUser.isAdmin ||
						toolInfo.nodeDef.isPublished
					)) &&
				(
					!toolInfo.nodeDef.teamIds || // No team means it's available to anyone
					(
						this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
						this.team &&
						toolInfo.nodeDef.teamIds[this.team.id]
					))
			).slice(0, 5);
		},
		visibleRunnitNodeDefs () {
			return _sortBy(this.filteredRunnitNodeDefs.filter((nodeDef) => {
				return this.filteredTagTypes.some(tag => {
					return !nodeDef.tags || nodeDef.tags[tag];
				})
			}), 'title');
		},
		tagsOnSelectedType () {
			return this.sortedTagType.map((tagId) => (this.tagsById[tagId])).filter(t => t);
		},
		noFilterTagsSelected () {
			return !Object.keys(this.selectedTagsFilterMap).length ||
				Object.keys(this.selectedTagsFilterMap).every(tagId => !this.selectedTagsFilterMap[tagId]);
		},
		filteredTagTypes () {
			const hiddenTagTypes = [RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE];
			return this.sortedTagType.filter(tagId => (
				(this.noFilterTagsSelected && !hiddenTagTypes.includes(this.tagsById[tagId].type)) ||
				this.selectedTagsFilterMap[tagId]
			));
		},
		sortedTagType () {
			return _sortBy(Object.keys(this.nodeDefsByTagMap), [
				(tagId) => { return RUNNIT_NODE_DEF_TAG_TYPE_SORT[this._get(this.tagsById, `[${tagId}].type`)] },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].sortOrder`) },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].label`) }])
				.filter(tagId => this.selectedTypes.some(type => (
					this._get(this.tagsById, `[${tagId}].type`) === type ||
					tagId === 'uncategorized' ||
					type === RUNNIT_NODE_DEF_TAG_TYPE.TEAM
				)));
		},
		tagsById (): Record<string, RunnitNodeDefTag> {
			return {
				'uncategorized': { label: 'Uncategorized', id: 'uncategorized', type: 'uncategorized' },
				...this.publicRunnitNodeDefTagsMap,
				...this.teamRunnitNodeDefTagsMap,
			} as Record<string, RunnitNodeDefTag>;
		},
		tabTagsMap (): Record<ToolsDialogNav, string> {
			const internalTags: RunnitNodeDefTag[] = Object.values(this.tagsById).filter((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.INTERNAL) as RunnitNodeDefTag[];
			const appTag: RunnitNodeDefTag = internalTags.find((tag: RunnitNodeDefTag) => tag.label === 'App');
			const toolTag: RunnitNodeDefTag = internalTags.find((tag: RunnitNodeDefTag) => tag.label === 'Tool');

			return {
				[TOOLS_DIALOG_NAV.TOOLS]: toolTag.id || null,
				[TOOLS_DIALOG_NAV.APPS]: appTag.id || null,
			} as Record<ToolsDialogNav, string>;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				this.open = !!newVal;
			},
		},
		filteredRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[]) {
				this.mapNodeDefsToTag(newVal);
			}
		},
		open: {
			immediate: true,
			handler (newVal: boolean) {
				if (
					newVal && // the dialog is open
					!this.showRunnitUpsell && // the upsell is not already open
					this.singleToolMode && // not adding a tool to an existing runnit
					!this.runnits.length && // the user does not already have a runnit
					this.loadingRunnits === false && // done loading runnits
					this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER && // on personal runnits view, not team
					this.loadingRecentUserRunnitNodeDefInfos === false && // done loading recents
					this.recentUserRunnitNodeDefInfos.length >= 3 // the user has opened at least 3 tools
				) {
					setTimeout(() => {
						this.showRunnitUpsell = true;
					}, 1000);
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
			this.toolSearchValue = '';
		},
		onSelectionChosen () {
			this.onCancel();
		},
		handleMultiSelectFilter (selectedTypes: RunnitNodeDefToolType[]) {
			this.selectedTypes = selectedTypes;
			this.restTagFilterMap();
			this.applyFilters();
			if (this.$vuetify.breakpoint.smAndDown) {
				this.toggleFilterCollapse();
			}
		},
		handleSearchInput (searchValue) {
			this.toolSearchValue = searchValue;
			this.applyFilters();
		},
		handlePublishedFilterChange (publishedState: RunnitsPublishedState) {
			this.publishedStateFilter = publishedState;
			this.applyFilters();
		},
		applyFilters () {
			this.filteredRunnitNodeDefs = [];
			[
				...this.publicRunnitNodeDefs,
				...this.teamRunnitNodeDefs,
			].forEach((runnitNodeDef: RunnitNodeDef) => {
				let matchesSearch: boolean, matchesPublishedState: boolean, matchesSelectedTypes: boolean, matchesTeam: boolean, matchesNavTab: boolean;

				if (this.currNavTab === TOOLS_DIALOG_NAV.APPS) {
					matchesNavTab = runnitNodeDef.tags[this.tabTagsMap[TOOLS_DIALOG_NAV.APPS]];
				} else if (this.currNavTab === TOOLS_DIALOG_NAV.TOOLS) {
					matchesNavTab = runnitNodeDef.tags[this.tabTagsMap[TOOLS_DIALOG_NAV.TOOLS]] || !runnitNodeDef.tags[this.tabTagsMap[TOOLS_DIALOG_NAV.APPS]];
				} else {
					matchesNavTab = false;
				}

				if (!this.toolSearchValue) {
					matchesSearch = true;
				} else {
					matchesSearch = fuzzyFilter(
						[runnitNodeDef],
						this.toolSearchValue,
						[
							{ name: 'title', weight: 0.7 },
							{ name: 'description', weight: 0.3 }
						]
					).length > 0;
				}

				if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.PUBLISHED) {
					matchesPublishedState = !!runnitNodeDef.publishedAt;
				} else if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.NOT_PUBLISHED) {
					matchesPublishedState = !runnitNodeDef.publishedAt;
				} else {
					matchesPublishedState = false;
				}

				if (this.selectedTypes && this.selectedTypes.length) {
					// if there are no set tag types then it is uncategorized and should be visible with any tag filtering
					matchesSelectedTypes = this.selectedTypes.find(type => (
						// For the team filter we also check the nodeDef type because it matches the team tag
						type !== RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
						runnitNodeDef.type === type
					) && (
							!runnitNodeDef.tagTypes ||
							_isEmpty(runnitNodeDef.tagTypes) ||
							Object.values(runnitNodeDef.tagTypes).every(applied => !applied) ||
							(
								type === RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
								runnitNodeDef.tagTypes[type]
							)
						));
				} else {
					matchesSelectedTypes = false;
				}

				const hasTeam = runnitNodeDef.teamIds && Object.keys(runnitNodeDef.teamIds).some((teamId) => runnitNodeDef.teamIds[teamId]);
				if (hasTeam && this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team) {
					matchesTeam = runnitNodeDef.teamIds[this.team.id];
				} else {
					matchesTeam = !hasTeam;
				}

				if (matchesSearch && matchesPublishedState && matchesSelectedTypes && matchesTeam && matchesNavTab) {
					this.filteredRunnitNodeDefs.push(runnitNodeDef);
				}
			});

			this.filteredRunnitNodeDefs = _sortBy(this.filteredRunnitNodeDefs, 'sortOrder');
		},
		async onToolCardClick (runnitNodeDef: RunnitNodeDef) {
			if (this.singleToolMode) {
				await this.onAddRunnitClick(runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL);
				this.onCancel();
				return;
			}
			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error adding a tool to this runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};
			try {
				this.onCancel();
				this.$emit('on-adding-tool-loading', true);
				const functionRef = functions
					.httpsCallable('addNodeToRunnit');
				const { success } = (await functionRef({
					runnitId: this.$route.params.runnitId,
					nodeDefId: runnitNodeDef.id,
				})).data;
				if (!success) {
					onError(new Error('addNodeToRunnit returned success: false'));
				}
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'New tool added to runnit!',
					show: true,
				});
			} catch (e) {
				onError(e);
			} finally {
				this.$emit('on-adding-tool-loading', false);
			}
		},
		toggleAdminNodeDefEditor (selectedNodeDef: RunnitNodeDef) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: true,
				selectedNodeDef,
			};
		},
		onEditDialogClose (open: boolean) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: !!open,
			};
			this.applyFilters();
		},
		routeToPrivateRunnits () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_BOARDS });
			this.setOpen(false);
		},
		restTagFilterMap () {
			this.selectedTagsFilterMap = {};
		},
		toggleTagFilter (tag) {
			this.selectedTagsFilterMap = {
				...this.selectedTagsFilterMap,
				[tag.id]: !this.selectedTagsFilterMap[tag.id],
			};
		},
		async mapNodeDefsToTag (nodeDefs) {
			this.nodeDefsByTagMap = (nodeDefs || []).reduce((map, nodeDef) => {
				Object.keys(nodeDef.tags || []).map((tagId) => {
					if (!map[tagId]) {
						map[tagId] = [];
					}
					map[tagId].push(nodeDef);
				})
				if (!nodeDef.tags || _isEmpty(nodeDef.tags)) {
					if (!map['uncategorized']) {
						map['uncategorized'] = [];
					}
					map['uncategorized'].push(nodeDef);
				}
				return map;
			}, {});
		},
		toggleFilterCollapse () {
			this.filtersCollapsed = !this.filtersCollapsed;
		},
		handleNavTabChange (navTab: ToolsDialogNav) {
			this.currNavTab = navTab;
			this.applyFilters();
		},
	},
	components: {
		GlassButton,
		LoadingState,
		OfferingCard,
		ComplexBackground,
		EmptyStateCircleBackground,
		RunnitDuplicateEditNodeDefInternalEditor,
		DiagonalStackedSquaresSVG,
		RunnitTagTypeSelect,
		RunnitTagFilter,
		DialogOrBottomSheet,
		RunnitNavTabs,
	},
});
