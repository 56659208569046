var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.featuredTool)?_c('div',{staticClass:"runnits-promo",class:{ 'runnits-promo-small': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"runnits-promo-cards-container",class:{ 'cards-container-small': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"runnits-promo-cards"},[_c('div',{staticClass:"shadow-3-3",staticStyle:{"width":"100%","height":"100%","border-radius":"var(--global-border-radius)"}},[_c('offering-card',{attrs:{"title":_vm.featuredTool.title,"image-url":_vm.featuredTool.imageUrl,"avatar":_vm.featuredTool.avatar,"description":_vm.featuredTool.description,"tool-type":_vm.featuredTool.type,"image-only":"","display-only":"","image-height-width":_vm.$vuetify.breakpoint.smAndDown ? 255 : 210}})],1),_c('div',{staticClass:"glass-square glass-square-1"}),_c('div',{staticClass:"glass-square glass-square-4"})])]),_c('div',{staticClass:"runnits-promo-text-container"},[_c('div',{staticClass:"runnits-promo-title",style:({
				...(_vm.$vuetify.breakpoint.xsOnly && {
					fontSize: '25px',
					margin: _vm.showButton ? '0 0 10px 0' : '30px 0 5px 0',
					lineHeight: '1.2',
				}),
				...(_vm.$vuetify.breakpoint.smOnly && {
					fontSize: '25px',
					margin: '-10px 0 10px 0',
				}),
				...(_vm.$vuetify.breakpoint.mdOnly && {
					fontSize: '28px',
					margin: '0 0 10px 0',
				}),
				...(_vm.$vuetify.breakpoint.lgAndUp && {
					fontSize: '32px',
					margin: '0 0 10px 0',
				}),
			})},[_vm._v(" "+_vm._s(_vm.featuredTool.title)+" ")]),_c('div',{staticClass:"runnits-promo-subtitle",style:({
				...(_vm.$vuetify.breakpoint.xsOnly && {
					fontSize: '12px',
					margin: '0 0 15px 0',
					padding: '0 0 0 0',
				}),
				...(_vm.$vuetify.breakpoint.smOnly && {
					fontSize: '15px',
					margin: '0 0 15px 0',
				}),
				...(_vm.$vuetify.breakpoint.mdOnly && {
					fontSize: '16px',
					margin: '0 0 20px 0',
					maxWidth: '400px',
				}),
				...(_vm.$vuetify.breakpoint.lgAndUp && {
					fontSize: '18px',
					margin: '0 0 20px 0',
				}),
			})},[_vm._v(" "+_vm._s(_vm.featuredTool.description)+" ")]),(_vm.showButton)?_c('div',{staticClass:"runnits-promo-button"},[_c('base-button',{staticStyle:{"padding":"0 40px"},attrs:{"large":"","super-primary":""},on:{"click":_vm.goToRunnitsHome}},[_vm._v(" Try It ")])],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }