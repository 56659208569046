import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dialog-or-bottom-sheet',{attrs:{"value":_vm.value,"max-width":1200,"bottom-sheet-on-small":true,"overlay-opacity":0.80,"scrollable":true},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._t("activator")]},proxy:true},{key:"content",fn:function(){return [_c(VCard,[_c(VCardTitle,{staticClass:"px-8 py-6",staticStyle:{"position":"relative","border-bottom":"1px solid var(--grey-850)","border-top-right-radius":"var(--global-border-radius)","border-top-left-radius":"var(--global-border-radius)"}},[_c('div',{staticClass:"full-width d-flex justify-space-between"},[_c('span',{staticClass:"dialog-title"},[_vm._v("Learn RunDiffusion / Get Support")]),_c('base-button',{attrs:{"secondary":"","icon":"","small":""},on:{"click":function($event){return _vm.onInput(false)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)]),_c(VCardText,{style:({
				padding: _vm.$vuetify.breakpoint.smAndDown ? '24px 30px 0 30px' : '24px 55px 0 55px'
			})},[_c(VRow,{staticClass:"support-container-row py-5",staticStyle:{"margin-top":"23px"}},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"position":"relative"}},[_c('stack-layers',{attrs:{"layer-one-styles":"\n\t\t\t\t\t\t\t\t\tposition: absolute;\n\t\t\t\t\t\t\t\t\tleft: -30px;\n\t\t\t\t\t\t\t\t\ttop: -50px;\n\t\t\t\t\t\t\t\t","layer-two-styles":"\n\t\t\t\t\t\t\t\t\tposition: relative;\n\t\t\t\t\t\t\t\t\tleft: 0;\n\t\t\t\t\t\t\t\t\ttop: 20px;\n\t\t\t\t\t\t\t\t"},scopedSlots:_vm._u([{key:"layer-1",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{attrs:{"alt":"Background Blobs","width":_vm.$vuetify.breakpoint.smAndUp ? '300px' : '200px',"src":require("../../../assets/layer2Blobs-PurpleBlue.svg")}}):_vm._e()]},proxy:true},{key:"layer-2",fn:function(){return [_c('img',{attrs:{"alt":"Learn RunDiffusion","width":_vm.$vuetify.breakpoint.smAndUp ? '150px' : '125px',"src":require("../../../assets/help-chat.png")}})]},proxy:true}])})],1)]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"d-flex justify-center flex-column full-height py-6",class:{
								'px-6': _vm.$vuetify.breakpoint.smAndDown,
							}},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"22px","color":"white","font-weight":"bold"}},[_vm._v(" Chat With Support ")]),_c('div',{staticClass:"pt-2 pr-4",staticStyle:{"font-size":"17px"}},[_c('b',[_vm._v("Reasons to reach out")]),_vm._v(": Bug Reports, Account Issues, Stuck Sessions, Partnership Requests. Our team will respond within "),_c('b',[_vm._v("24 hours (Monday–Friday)")]),_vm._v(". Weekend tickets will be answered on "),_c('b',[_vm._v("Monday")]),_vm._v(". You'll receive a "),_c('b',[_vm._v("webchat response and/or an email")]),_vm._v(" response. "),_c('div',{staticStyle:{"margin":"30px 0 10px 0"}},[_vm._v(" For quick help: ")]),_c('div',{staticClass:"ml-5 mb-10"},[_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Master Class: "),_c('a',{staticStyle:{"word-break":"break-all"},attrs:{"href":"https://masterclass.rundiffusion.com/courses","target":"_blank"}},[_vm._v("masterclass.rundiffusion.com/courses")])]),_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Guides & Tutorials: "),_c('a',{attrs:{"href":"https://learn.rundiffusion.com","target":"_blank"}},[_vm._v("learn.rundiffusion.com")])])],1),_vm._v(" We appreciate your patience. ")])])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"full-height d-flex align-center justify-end",class:{
								'pb-6 pt-1': _vm.$vuetify.breakpoint.smAndDown,
							}},[_c('div',{staticClass:"d-flex flex-column"},[_c('base-button',{style:({
										padding: _vm.$vuetify.breakpoint.xlOnly ? '0 60px' : '0 35px',
									}),attrs:{"call-to-action":"","large":_vm.$vuetify.breakpoint.lgAndUp,"loading":_vm.isOpeningChatSupportWidget,"disabled":_vm.isOpeningChatSupportWidget},on:{"click":_vm.onStartChatSupport}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chat-question-outline")]),_c('b',[_vm._v("Open")])],1),(_vm.showChatSupportWidgetIssueWarning)?_c(VAlert,{staticClass:"text-left mt-5",attrs:{"shaped":"","outlined":"","color":"warning"}},[_vm._v(" Some Adblockers, VPNs, firewalls, etc. may prevent you from using the support chat. Please temporarily turn them off and try again. ")]):_vm._e()],1)])])],1),_c(VRow,{staticClass:"support-container-row"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"position":"relative"}},[_c('stack-layers',{attrs:{"layer-one-styles":"\n\t\t\t\t\t\t\t\t\tposition: absolute;\n\t\t\t\t\t\t\t\t\tleft: 4px;\n\t\t\t\t\t\t\t\t\ttop: -20px;\n\t\t\t\t\t\t\t\t","layer-two-styles":"position: relative"},scopedSlots:_vm._u([{key:"layer-1",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{attrs:{"alt":"Background Blobs","width":_vm.$vuetify.breakpoint.smAndUp ? '250px' : '200px',"src":require("../../../assets/layer2Blobs-PurpleBlue.svg")}}):_vm._e()]},proxy:true},{key:"layer-2",fn:function(){return [_c('img',{attrs:{"alt":"Learn RunDiffusion","width":_vm.$vuetify.breakpoint.smAndUp ? '200px' : '150px',"src":require("../../../components/Toolbar/assets/animated-discord.gif")}})]},proxy:true}])})],1)]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"d-flex justify-center flex-column full-height py-6",class:{
								'px-6': _vm.$vuetify.breakpoint.smAndDown,
							}},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"22px","color":"white","font-weight":"bold"}},[_vm._v(" Join Our Discord ")]),_c('div',{staticClass:"pr-4",staticStyle:{"font-size":"17px"}},[_vm._v(" We have one of the fastest growing Discord communities in the creative generative AI space. Come ask any question and learn from the best! ")])])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"full-height d-flex align-center justify-end",class:{
								'pb-6 pt-1': _vm.$vuetify.breakpoint.smAndDown,
							}},[_c('base-button',{style:({
									padding: _vm.$vuetify.breakpoint.xlOnly ? '0 60px' : '0 35px',
								}),attrs:{"call-to-action":"","large":_vm.$vuetify.breakpoint.lgAndUp},on:{"click":_vm.onJoinDiscordServer}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_c('b',[_vm._v("Open")])],1)],1)])],1),_c(VRow,{staticClass:"support-container-row"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center",class:{
								'pt-8': _vm.$vuetify.breakpoint.smAndDown,
							},staticStyle:{"position":"relative"}},[_c('stack-layers',{attrs:{"layer-one-styles":"\n\t\t\t\t\t\t\t\t\tposition: absolute;\n\t\t\t\t\t\t\t\t\tleft: 4px;\n\t\t\t\t\t\t\t\t\ttop: -20px;\n\t\t\t\t\t\t\t\t","layer-two-styles":"position: relative"},scopedSlots:_vm._u([{key:"layer-1",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{attrs:{"alt":"Background Blobs","width":_vm.$vuetify.breakpoint.smAndUp ? '250px' : '200px',"src":require("../../../assets/layer2Blobs-PurpleBlue.svg")}}):_vm._e()]},proxy:true},{key:"layer-2",fn:function(){return [_c('img',{attrs:{"alt":"Learn RunDiffusion","width":_vm.$vuetify.breakpoint.smAndUp ? '200px' : '150px',"src":require("../../../assets/education-startup.png")}})]},proxy:true}])})],1)]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"d-flex justify-center flex-column full-height py-6",class:{
								'px-6': _vm.$vuetify.breakpoint.smAndDown,
							}},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"22px","color":"white","font-weight":"bold"}},[_vm._v(" Learn RunDiffusion ")]),_c('div',{staticClass:"pr-4",staticStyle:{"font-size":"17px"}},[_vm._v(" Read how-to articles in our help center! If you have a question about the applications we run for you or how to manage your account, check here first. ")])])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"full-height d-flex align-center justify-end",class:{
								'pb-6 pt-1': _vm.$vuetify.breakpoint.smAndDown,
							}},[_c('base-button',{style:({
									padding: _vm.$vuetify.breakpoint.xlOnly ? '0 60px' : '0 35px',
								}),attrs:{"call-to-action":"","large":_vm.$vuetify.breakpoint.lgAndUp},on:{"click":_vm.openLearnSite}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_c('b',[_vm._v("Open")])],1)],1)])],1),(!_vm.insideRunnits)?_c(VRow,{staticClass:"support-container-row"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-center pt-6 pb-3 px-3"},[_c('img',{staticClass:"full-width global-border-radius",attrs:{"alt":"Where are application tutorials located","src":require("./assets/where-app-tutorials-btn.png")}})])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-center flex-column full-height py-6"},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"22px","color":"white","font-weight":"bold"}},[_vm._v(" Walkthrough Tutorials ")]),_c('div',{staticStyle:{"font-size":"17px"}},[_vm._v(" We catered a list of tutorials for each application you can launch on RunDiffusion. See screenshot to learn where this button is located. For example, watch step-by-step video about how to use ControlNet in Automatic1111. ")])])])],1):_vm._e(),_c('div',{staticClass:"full-width d-flex justify-start mt-12 pb-12"},[_c('base-button',{staticClass:"mr-4",attrs:{"secondary":""},on:{"click":function($event){return _vm.onInput(false)}}},[_vm._v(" Close ")])],1)],1)],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }