
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import { Session, User, Workshop, WorkshopSession, WORKSHOP_SESSION_QUEUE_ITEM_ADMISSION } from '@run-diffusion/shared';
import moment from 'moment-timezone';
import _find from 'lodash/find';
import { db } from '@/firebase';
import InfoMessageSmall from '@/components/base/InfoMessageSmall.vue';
import RunDiffusionWithRunnitMarkLogo from '@/assets/RunDiffusionWithRunnitMarkLogo.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'WorkshopWaitingLobby',
	props: {},
	data () {
		return {
			workshopId: null,
			workshopSessionId: null,

			workshopStartAtMoment: null,
		};
	},
	computed: {
		...mapState([
			'currSessions',
			'user',
			'realTimeMoment',
			'lobbyWorkshop',
			'lobbyWorkshopSession',
			'loadingLobbyWorkshop',
		]),
		isPreviewMode () {
			return !!this.$route.query.preview;
		},
		timeLeftData () {
			if (!this.lobbyWorkshop || !this.workshopStartAtMoment) {
				return null;
			}

			// Number representation
			let hrDiff: number = this.workshopStartAtMoment.diff(this.realTimeMoment, 'hours');
			let minDiff: number = this.workshopStartAtMoment.diff(this.realTimeMoment, 'minutes') % 60;
			let secDiff: number = this.workshopStartAtMoment.diff(this.realTimeMoment, 'seconds') % 60;
			hrDiff = Math.max(hrDiff, 0);
			minDiff = Math.max(minDiff, 0);
			secDiff = Math.max(secDiff, 0);

			// String representation
			let hrStr: string = `${hrDiff}`;
			let minStr: string = `${minDiff}`;
			let secStr: string = `${secDiff}`;
			hrStr = hrStr.length <= 1 ? `0${hrStr}` : hrStr;
			minStr = minStr.length <= 1 ? `0${minStr}` : minStr;
			secStr = secStr.length <= 1 ? `0${secStr}` : secStr;

			return {
				hours: hrStr,
				minutes: minStr,
				seconds: secStr,
				isNow: !hrDiff && !minDiff && !secDiff,
			};
		},
	},
	watch: {
		'$route.params.workshopId': {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.workshopId = newVal;
					if (this.workshopId) {
						this.bindLobbyWorkshop(this.workshopId);
					} else {
						this.unbindLobbyWorkshop();
					}
				}
			},
		},
		'$route.params.workshopSessionId': {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.workshopSessionId = newVal;
					if (this.workshopSessionId) {
						this.bindLobbyWorkshopSession({
							workshopId: this.workshopId,
							workshopSessionId: this.workshopSessionId,
						});
					} else {
						this.unbindLobbyWorkshopSession();
					}
				}
			},
		},
		lobbyWorkshop: {
			immediate: true,
			handler (newVal: Workshop) {
				if (newVal) {
					this.workshopStartAtMoment = moment(newVal.startAt.toMillis());
				}
			},
		},
		lobbyWorkshopSession: {
			immediate: true,
			handler (newVal: WorkshopSession) {
				if (newVal) {
					this.queueUserForWorkshopSession(newVal, this.user);
				}
			},
		},
		user: {
			immediate: true,
			handler (newVal: User) {
				if (newVal) {
					this.queueUserForWorkshopSession(this.lobbyWorkshopSession, newVal);
				}
			},
		},
		currSessions: {
			immediate: true,
			handler (newVal: Session[]) {
				if (newVal) {
					const waitingSession: Session = _find(
						newVal || [],
						({ workshopSessionId }) => workshopSessionId === this.$route.params.workshopSessionId,
					) || null;
					if (waitingSession) {
						this.updateToolbar({
							session: waitingSession,
						});
						this.routerPush(this.$route, this.$router, {
							name: ROUTER.LAUNCH,
							params: {
								sessionId: waitingSession.id,
							},
						});
					}
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'bindLobbyWorkshop',
			'unbindLobbyWorkshop',
			'bindLobbyWorkshopSession',
			'unbindLobbyWorkshopSession',
		]),
		closeCurrWindow () {
			window.close();
		},
		async queueUserForWorkshopSession (lobbyWorkshopSession: WorkshopSession, user: User) {
			if (
				lobbyWorkshopSession &&
				user &&
				!this.isPreviewMode
			) {
				const workshopSessionQueueItemRef = db
					.doc(`workshops/${lobbyWorkshopSession.workshopId}/workshopSessions/${lobbyWorkshopSession.id}/workshopSessionQueue/${user.id}`);
				if ((await workshopSessionQueueItemRef.get()).exists) {
					workshopSessionQueueItemRef.update({
						abandonedAt: null,
						userEmail: user.email,
					});
				} else {
					await workshopSessionQueueItemRef.set({
						createdAt: new Date(),
						abandonedAt: null,
						admission: WORKSHOP_SESSION_QUEUE_ITEM_ADMISSION.INIT,
						userId: user.id,
						userEmail: user.email,
						sessionId: null,
						session: null,
					});
				}
			}
		},
		onLoginRedirectClick () {
			this.routerReplace(this.$route, this.$router, {
				name: ROUTER.LOGIN,
				query: {
					...this.$route.query,
					redirect: this.$route.path,
				},
			});
		},
	},
	components: {
		InfoMessageSmall,
		LoadingSVG,
		RunDiffusionWithRunnitMarkLogo,
	},
});
