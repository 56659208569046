
import Vue from 'vue';
import { db } from '@/firebase';
import { mapState } from 'vuex';
import { RunnitNodeRun, RunnitNodeRunResult } from '@run-diffusion/shared';
import _shuffle from 'lodash/shuffle';

import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import RunnitImageInfoCarouselDialog from '@/views/Runnits/RunnitImageInfoCarouselDialog.vue';
import ImageModeButton from '@/components/ImageGallery/ImageModeButton.vue';
import {
	SELECTED_IMAGE_MODE,
} from '@/views/Runnits/constants';
import SelectedColumnsButton from '@/components/ImageGallery/SelectedColumnsButton.vue';

export default Vue.extend({
	name: 'RunnitLibraryPage',

	components: {
		ImageGallery,
		LoadingState,
		EmptyState,
		RunnitImageInfoCarouselDialog,
		ImageModeButton,
		SelectedColumnsButton,
	},

	data () {
		return {
			SELECTED_IMAGE_MODE,
			loadingFeaturedNodeRuns: false,
			featuredNodeRuns: [] as RunnitNodeRun[],
			imageInfoCarouselConfig: {
				dialogOpen: false,
				nodeRun: null as RunnitNodeRun | null,
				nodeRunResult: null as RunnitNodeRunResult | null,
			},
		};
	},

	computed: {
		...mapState([
			'user',
			'runnitState',
		]),
	},

	async created () {
		await this.initFeaturedNodeRuns();
	},

	methods: {
		async initFeaturedNodeRuns () {
			try {
				this.loadingFeaturedNodeRuns = true;
				this.featuredNodeRuns = [];

				const nodeRunsRef = db.collection('runnitNodeRuns')
					.where('featured', '==', true)
					.limit(100);

				(await nodeRunsRef.get()).forEach(async (doc: any) => {
					let fetchedNodeDef = null;
					if (doc.data().nodeDef && !doc.data().nodeDef.title) {
						try {
							const nodeDefRef = db.doc(`runnitNodeDefs/${doc.data().nodeDefId}`);
							const nodeDef = (await nodeDefRef.get()).data();
							fetchedNodeDef = { ...nodeDef, id: doc.data().nodeDefId };
						} catch (err) {
							console.error('Failed to fetch the generating tool', err);
						}
					}

					this.featuredNodeRuns.push({
						...doc.data(),
						...(fetchedNodeDef ? { nodeDef: fetchedNodeDef } : {}),
						get id () { return doc.id; },
					});
					this.featuredNodeRuns = _shuffle(this.featuredNodeRuns);
				});
			} catch (error) {
				console.error('Failed to fetch featured node runs', error);
			} finally {
				this.loadingFeaturedNodeRuns = false;
			}
		},

		featuredResultFeature (result: RunnitNodeRunResult) {
			return result.featured;
		},
		handleIsPublicUpdated (payload: { nodeRunResult: RunnitNodeRunResult, nodeRun: RunnitNodeRun }) {
			this.imageInfoCarouselConfig.nodeRun = { ...payload.nodeRun };
			this.imageInfoCarouselConfig.nodeRunResult = { ...payload.nodeRunResult };
		},
	},
});
