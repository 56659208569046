
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Aspect Ratio',
		},
		width: {
			type: Number,
			required: true,
		},
		height: {
			type: Number,
			required: true,
		},
	},
	computed: {
		aspectRatio (): number {
			return this.width / this.height;
		},
		scaledDimensions (): { width: number; height: number } {
			const targetSize = 20;
			if (this.width >= this.height) {
				return {
					width: targetSize,
					height: targetSize / this.aspectRatio,
				};
			} else {
				return {
					width: targetSize * this.aspectRatio,
					height: targetSize,
				};
			}
		},
		scaledWidth (): number {
			return this.scaledDimensions.width;
		},
		scaledHeight (): number {
			return this.scaledDimensions.height;
		},
		viewBox (): string {
			return `0 0 ${this.scaledWidth} ${this.scaledHeight}`;
		},
	},
});
