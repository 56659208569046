
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { ROUTER } from '@/router/constants';
import {
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_TYPE,
	RunnitNodeDef,
	RUNNIT_NODE_DEF_TAG_TYPE_SORT,
	RunnitNodeDefToolType,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { db } from '@/firebase';
import RunnitCarousel from '../RunnitCarousel.vue';
import OfferingCard from '@/components/OfferingCard.vue';
import { RunnitsCRUDMixin } from '@/mixins/RunnitsCRUDMixin';
import { fuzzyFilter } from '@/utils/fuzzySearch';
import RunnitTagTypeTabs from '../RunnitTagTypeTabs.vue';
import RunnitTagFilterCarousel from '../RunnitTagFilterCarousel.vue';
import RunnitDuplicateEditNodeDefInternalEditor from '../RunnitSettings/internalAdminOnly/RunnitDuplicateEditNodeDefInternalEditor.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { RUNNITS_PUBLISHED_STATE, RunnitsPublishedState } from '@/constants/constants';

interface App {
	id: string;
	title: string;
	imageUrl: string;
	avatar: string;
	description: string;
	type: string;
	publishedAt: string;
}

export default Vue.extend({
	name: 'RunnitAppLibrary',
	mixins: [
		RunnitsCRUDMixin,
	],
	data () {
		return {
			RUNNITS_PUBLISHED_STATE,
			searchQuery: '',
			featuredTagId: null,
			appTypeTagIds: [] as string[],
			selectedCategory: 'ALL',
			toolSearchValue: '',
			publishedStateFilter: RUNNITS_PUBLISHED_STATE.PUBLISHED,
			selectedTagsFilterMap: {},
			nodeDefsByTagMap: {},
			nodeDefInternalEditorDialog: {
				open: false,
				selectedNodeDef: null,
			},
		};
	},
	async created () {
		await this.fetchAppTypeTagIds();
		await this.fetchFeaturedTagId();
	},
	computed: {
		...mapState([
			'user',
			'runnitState',
			'recentUserRunnitNodeDefInfos',
			'publicRunnitNodeDefs',
			'teamRunnitNodeDefs',
			'publicRunnitNodeDefTagsMap',
			'teamRunnitNodeDefTagsMap',
		]),
		tagsOnSelectedType () {
			return this.sortedTagType.map((tagId) => (this.tagsById[tagId])).filter(t => t);
		},
		noFilterTagsSelected () {
			return !Object.keys(this.selectedTagsFilterMap).length ||
				Object.keys(this.selectedTagsFilterMap).every(tagId => !this.selectedTagsFilterMap[tagId]);
		},
		filteredTagTypes () {
			return this.sortedTagType.filter(tagId => this.noFilterTagsSelected ||
				this.selectedTagsFilterMap[tagId]);
		},
		sortedTagType () {
			return _sortBy(Object.keys(this.nodeDefsByTagMap), [
				(tagId) => { return RUNNIT_NODE_DEF_TAG_TYPE_SORT[this._get(this.tagsById, `[${tagId}].type`)] },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].sortOrder`) },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].label`) }])
				.filter(tagId => this._get(this.tagsById, `[${tagId}].type`) === this.selectedCategory || tagId === 'uncategorized' || this.selectedCategory === RUNNIT_NODE_DEF_TAG_TYPE.TEAM || this.selectedCategory === 'ALL');
		},
		tagsById () {
			return {
				'uncategorized': { label: 'Uncategorized', id: 'uncategorized', type: 'uncategorized' },
				...this.publicRunnitNodeDefTagsMap,
				...this.teamRunnitNodeDefTagsMap,
			}
		},
		recentApps (): RunnitNodeDef[] {
			return (this.recentUserRunnitNodeDefInfos || []).filter((toolInfo) => !toolInfo.nodeDef.isDeleted &&
				(
					this.user &&
					toolInfo.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT &&
					(
						this.user.isAdmin ||
						toolInfo.nodeDef.isPublished
					)) &&
				(
					!toolInfo.nodeDef.teamIds || // No team means it's available to anyone
					(
						this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
						this.team &&
						toolInfo.nodeDef.teamIds[this.team.id]
					)) &&
				this.appTypeTagIds.some(tagId => toolInfo.nodeDef.tags[tagId])
			).slice(0, 5).map(toolInfo => ({
				id: toolInfo.nodeDef.id,
				title: toolInfo.nodeDef.title,
				imageUrl: toolInfo.nodeDef.imageUrl,
				avatar: toolInfo.nodeDef.avatar,
				description: toolInfo.nodeDef.description,
				type: toolInfo.nodeDef.type,
				publishedAt: toolInfo.nodeDef.publishedAt,
			}));
		},
		filteredApps (): Partial<RunnitNodeDef>[] {
			const allNodeDefs = [...this.publicRunnitNodeDefs, ...this.teamRunnitNodeDefs];
			const filteredNodeDefs = allNodeDefs.filter((runnitNodeDef: RunnitNodeDef) => {
				let matchesSearch: boolean, matchesPublishedState: boolean, matchesTeam: boolean, matchesAppType: boolean, matchesSelectedTypes: boolean;

				// Check if it's an app type
				matchesAppType = this.appTypeTagIds.some(tagId => runnitNodeDef.tags?.[tagId]);

				if (!this.toolSearchValue) {
					matchesSearch = true;
				} else {
					matchesSearch = fuzzyFilter(
						[runnitNodeDef],
						this.toolSearchValue,
						[
							{ name: 'title', weight: 0.7 },
							{ name: 'description', weight: 0.3 }
						]
					).length > 0;
				}

				if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.PUBLISHED) {
					matchesPublishedState = !!runnitNodeDef.publishedAt;
				} else if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.NOT_PUBLISHED) {
					matchesPublishedState = !runnitNodeDef.publishedAt;
				} else {
					matchesPublishedState = false;
				}

				const hasTeam = runnitNodeDef.teamIds && Object.keys(runnitNodeDef.teamIds).some((teamId) => runnitNodeDef.teamIds[teamId]);
				if (hasTeam && this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team) {
					matchesTeam = runnitNodeDef.teamIds[this.team.id];
				} else {
					matchesTeam = !hasTeam;
				}

				if (this.selectedCategory && this.selectedCategory !== 'ALL') {
					matchesSelectedTypes = (
						this.selectedCategory !== RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
						runnitNodeDef.type === this.selectedCategory
					) &&
						(
							!runnitNodeDef.tagTypes ||
							_isEmpty(runnitNodeDef.tagTypes) ||
							Object.values(runnitNodeDef.tagTypes).every(applied => !applied) ||
							(
								this.selectedCategory === RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
								runnitNodeDef.tagTypes[this.selectedCategory]
							)
						);
				} else {
					matchesSelectedTypes = true;
				}

				return matchesSearch && matchesPublishedState && matchesTeam && matchesAppType && matchesSelectedTypes;
			});

			return filteredNodeDefs.map(nodeDef => ({
				id: nodeDef.id,
				title: nodeDef.title,
				imageUrl: nodeDef.imageUrl,
				avatar: nodeDef.avatar,
				description: nodeDef.description,
				type: nodeDef.type,
				publishedAt: nodeDef.publishedAt,
			}));
		},
		featuredApps (): Partial<RunnitNodeDef>[] {
			const allNodeDefs = [...this.publicRunnitNodeDefs, ...this.teamRunnitNodeDefs];
			return allNodeDefs
				.filter((nodeDef: RunnitNodeDef) => {
					// Must be an app type
					const isApp = this.appTypeTagIds.some(tagId => nodeDef.tags?.[tagId]);
					// Must have the featured tag
					const isFeatured = this.featuredTagId && nodeDef.tags?.[this.featuredTagId];
					// Must be published for non-admin users
					const isPublished = this.user.isAdmin || !!nodeDef.publishedAt;
					// Check team access
					const hasTeam = nodeDef.teamIds && Object.keys(nodeDef.teamIds).some((teamId) => nodeDef.teamIds[teamId]);
					const hasTeamAccess = hasTeam ? (
						this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
						this.team &&
						nodeDef.teamIds[this.team.id]
					) : true;

					return isApp && isFeatured && isPublished && hasTeamAccess && !nodeDef.isDeleted;
				})
				.map(nodeDef => ({
					id: nodeDef.id,
					title: nodeDef.title,
					imageUrl: nodeDef.imageUrl,
					avatar: nodeDef.avatar,
					description: nodeDef.description,
					type: nodeDef.type,
					publishedAt: nodeDef.publishedAt,
				}));
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		handleCategoryChange (selectedCategory: RunnitNodeDefToolType) {
			this.selectedCategory = selectedCategory;
			this.selectedTagsFilterMap = {};
			this.applyFilters();
		},
		handleSearchInput (searchValue) {
			this.toolSearchValue = searchValue;
			this.applyFilters();
		},
		handlePublishedFilterChange (publishedState: RunnitsPublishedState) {
			this.publishedStateFilter = publishedState;
			this.applyFilters();
		},
		toggleTagFilter (tag) {
			this.selectedTagsFilterMap = {
				...this.selectedTagsFilterMap,
				[tag.id]: !this.selectedTagsFilterMap[tag.id],
			};
		},
		async mapNodeDefsToTag (nodeDefs) {
			this.nodeDefsByTagMap = (nodeDefs || []).reduce((map, nodeDef) => {
				Object.keys(nodeDef.tags || []).map((tagId) => {
					if (!map[tagId]) {
						map[tagId] = [];
					}
					map[tagId].push(nodeDef);
				})
				if (!nodeDef.tags || _isEmpty(nodeDef.tags)) {
					if (!map['uncategorized']) {
						map['uncategorized'] = [];
					}
					map['uncategorized'].push(nodeDef);
				}
				return map;
			}, {});
		},
		applyFilters () {
			const allNodeDefs = [...this.publicRunnitNodeDefs, ...this.teamRunnitNodeDefs];
			this.mapNodeDefsToTag(allNodeDefs);
		},
		async onAppClick (nodeDef: RunnitNodeDef) {
			await this.onAddRunnitClick(nodeDef, RUNNIT_TYPE.SINGLE_TOOL);
		},
		async fetchAppTypeTagIds () {
			const tags = await db.collection('runnitNodeDefTags').where('type', '==', RUNNIT_NODE_DEF_TAG_TYPE.INTERNAL).where('label', '==', 'App').where('isDeleted', '==', false).get();
			this.appTypeTagIds = tags.docs.map(doc => doc.id);
		},
		async fetchFeaturedTagId () {
			try {
				const tags = await db.collection('runnitNodeDefTags')
					.where('type', '==', RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE)
					.where('label', '==', 'Featured')
					.where('isDeleted', '==', false)
					.get();

				if (!tags.empty) {
					this.featuredTagId = tags.docs[0].id;
				}
			} catch (error) {
				console.error('Error fetching featured tag:', error);
				this.updateSnackbar({
					status: 'error',
					message: 'Failed to load featured apps. Please try again.',
					show: true,
				});
			}
		},
		async toggleAdminNodeDefEditor (selectedNodeDef: RunnitNodeDef) {
			try {
				// Fetch the complete nodeDef data
				const nodeDefRef = db.doc(`runnitNodeDefs/${selectedNodeDef.id}`);
				const nodeDefDoc = await nodeDefRef.get();
				if (!nodeDefDoc.exists) {
					throw new Error('Node definition not found');
				}

				const completeNodeDef = {
					...nodeDefDoc.data(),
					id: nodeDefDoc.id,
				};

				this.nodeDefInternalEditorDialog = {
					...this.nodeDefInternalEditorDialog,
					open: true,
					selectedNodeDef: completeNodeDef,
				};
			} catch (error) {
				console.error('Error fetching node definition:', error);
				this.updateSnackbar({
					status: 'error',
					message: 'Failed to load tool data. Please try again.',
					show: true,
				});
			}
		},
		onEditDialogClose (open: boolean) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: !!open,
			};
			this.applyFilters();
		},
	},
	components: {
		RunnitCarousel,
		OfferingCard,
		// RunnitTagTypeTabs,
		// RunnitTagFilterCarousel,
		RunnitDuplicateEditNodeDefInternalEditor,
		GlassButton,
	},
});
