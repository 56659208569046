
import Vue from 'vue';
import { FeatureUpdate } from '../types';

export default Vue.extend({
	name: 'FeatureCard',
	props: {
		feature: {
			type: Object as () => FeatureUpdate,
			required: true
		}
	}
});
