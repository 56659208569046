
import Vue from 'vue';
import { PropType } from 'vue';
import RunnitResult from '../../views/Runnits/RunnitResult.vue';
import RunnitImageInfoCarouselDialog from '../../views/Runnits/RunnitImageInfoCarouselDialog.vue';
import {
	RunnitNodeRun,
	RunnitNodeRunResult,
	RunnitUpload,
	Avatar,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
} from '@run-diffusion/shared';
import _debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { SELECTED_COLUMNS_MAP } from '@/views/Runnits/constants';
import _differenceBy from 'lodash/differenceBy';
import _mapKeys from 'lodash/mapKeys';
import _unionBy from 'lodash/unionBy';
import { RunnitState } from '@/store';
import _range from 'lodash/range';

interface ImageInfoCarouselConfig {
	dialogOpen: boolean;
	nodeRun: RunnitNodeRun | null;
	nodeRunResult: RunnitNodeRunResult | null;
}

interface PlaceholderResult extends RunnitNodeRunResult {
	isPlaceholder?: boolean;
}

interface VirtualRow {
	id: number;
	results: PlaceholderResult[];
	size: number;
	top: number;
}

export default Vue.extend({
	name: 'VirtualScrollResults',
	props: {
		fetchPageFunction: {
			type: Function as PropType<(lastDoc: any, pageSize: number) => Promise<{ results: RunnitNodeRun[], hasMore: boolean, lastDoc: any }>>,
			required: true,
		},
		pageSize: {
			type: [Number, String],
			default: 10,
		},
		height: {
			type: [Number, String],
			default: 800,
		},
		bench: {
			type: Number,
			default: 3,
		},
		disableCarousel: {
			type: Boolean,
			default: false,
		},
		resultFilter: {
			type: Function as PropType<(result: RunnitNodeRunResult) => boolean>,
			default: () => true,
		},
		backgroundColor: {
			type: String,
			default: 'transparent',
		},
		gap: {
			type: Number,
			default: 16,
		},
		draggable: {
			type: Boolean,
			default: false,
		},
		showTrainingResults: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			SELECTED_COLUMNS_MAP,
			initializing: false,
			isLoadingMore: false,
			lastDoc: null as any,
			hasMoreResults: true,
			runnitNodeRunsById: {} as Record<string, RunnitNodeRun>,
			results: [] as PlaceholderResult[],
			containerWidth: 0,
			imageInfoCarouselConfig: {
				dialogOpen: false,
				nodeRun: null,
				nodeRunResult: null,
			} as ImageInfoCarouselConfig,
			scrollTop: 0,
			visibleRange: {
				start: 0,
				end: 0,
			},
			rowHeights: new Map<number, number>(),
			observer: null as ResizeObserver | null,
		};
	},
	computed: {
		...mapState(['runnitState']),
		numColumns (): number {
			return this.$vuetify.breakpoint.smAndUp ? SELECTED_COLUMNS_MAP[this.runnitState.selectedColumns] || 4 : 2;
		},
		rows (): VirtualRow[] {
			const rows: VirtualRow[] = [];
			let currentRow: VirtualRow = { id: 1, results: [], size: 0, top: 0 };
			let currentTop = 0;

			this.results.forEach((result, index) => {
				if (!result.deletedAt) {
					currentRow.results.push(result);
				}

				if (currentRow.results.length >= this.numColumns || index === this.results.length - 1) {
					const rowHeight = currentRow.results.reduce((maxHeight, result) => {
						if (!result.file?.width || !result.file?.height) return maxHeight;
						const aspectRatio = result.file.width / result.file.height;
						const height = this.columnWidth / aspectRatio;
						return Math.max(Math.ceil(maxHeight), Math.ceil(height));
					}, 0);

					currentRow.size = rowHeight || 150;
					currentRow.top = currentTop;
					rows.push(currentRow);

					currentTop += currentRow.size + this.gap;
					currentRow = { id: currentRow.id + 1, results: [], size: 0, top: 0 };
				}
			});

			return rows;
		},
		visibleRows (): VirtualRow[] {
			return this.rows.slice(this.visibleRange.start, this.visibleRange.end);
		},
		totalHeight (): number {
			return this.rows.reduce((sum, row) => sum + row.size + this.gap, 0);
		},
		runnitNodeRuns (): RunnitNodeRun[] {
			return (this.results || []).map(result => this.runnitNodeRunsById[result.nodeRunId]).filter(Boolean);
		},
		columnWidth (): number {
			return (this.containerWidth - (this.gap * (this.numColumns - 1))) / this.numColumns;
		},
	},
	watch: {
		numColumns: {
			handler (newVal: number, oldVal: number) {
				if (newVal !== oldVal) {
					if (this.$refs.virtualScrollContainer) {
						(this.$refs.virtualScrollContainer as HTMLElement).scrollTop = 0;
					}
					this.scrollTop = 0;
					this.updateVisibleRange();
				}
			}
		},
		runnitState: {
			immediate: true,
			async handler (newVal: RunnitState, oldVal: RunnitState) {
				if (newVal.deletedImages.nodeRunResults.length
					&& _differenceBy(newVal?.deletedImages?.nodeRunResults || [], oldVal?.deletedImages?.nodeRunResults || [], 'uuid').length
				) {
					const deletedNodeRunResultsMap: Record<string, RunnitNodeRunResult> = _mapKeys(newVal.deletedImages.nodeRunResults, 'uuid');

					// Filter out deleted results from the results array
					this.results = this.results.filter(result => !deletedNodeRunResultsMap[result.uuid]);

					// Update nodeRunsById to remove empty results
					Object.keys(this.runnitNodeRunsById).forEach(nodeRunId => {
						const nodeRun = this.runnitNodeRunsById[nodeRunId];
						nodeRun.results = (nodeRun.results || []).filter(result => !deletedNodeRunResultsMap[result.uuid]);
						if (!nodeRun.results.length) {
							delete this.runnitNodeRunsById[nodeRunId];
						}
					});
				}
				if (newVal.deletedImages.uploads.length) {
					const deletedUploadsMap: Record<string, RunnitUpload> = _mapKeys(newVal.deletedImages.uploads, 'id');
					this.results = this.results.filter(result => !deletedUploadsMap[result.uploadId]);
				}
				if (newVal.deletedImages.avatars.length) {
					const deletedAvatarsMap: Record<string, Avatar> = _mapKeys(newVal.deletedImages.avatars, 'id');
					this.results = this.results.filter(result => !deletedAvatarsMap[result.avatarId]);
				}
			}
		},
		'runnitState.settingsDrawerOpen': {
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal !== oldVal) {
					// Wait for drawer animation to complete (300ms is typical Vuetify drawer animation duration)
					setTimeout(() => {
						this.$nextTick(() => {
							this.updateContainerWidth();
						});
					}, 300);
				}
			}
		},
	},
	mounted () {
		setTimeout(() => {
			this.updateContainerWidth();
		}, 300);
		window.addEventListener('resize', this.updateContainerWidth);
		this.setupResizeObserver();
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.updateContainerWidth);
		if (this.observer) {
			this.observer.disconnect();
		}
	},
	methods: {
		setupResizeObserver () {
			this.observer = new ResizeObserver(this.handleRowResize);
		},
		handleRowResize (entries: ResizeObserverEntry[]) {
			entries.forEach(entry => {
				const rowId = parseInt(entry.target.getAttribute('data-row-id') || '0');
				const newHeight = entry.contentRect.height;
				if (this.rowHeights.get(rowId) !== newHeight) {
					this.rowHeights.set(rowId, newHeight);
					this.updateVisibleRange();
				}
			});
		},
		updateVisibleRange () {
			const containerHeight = this.$el?.clientHeight || 800;
			const scrollTop = this.scrollTop;
			const scrollBottom = scrollTop + containerHeight;

			// Find the first row that starts after the top of the viewport
			const startIndex = Math.max(0, this.rows.findIndex(row => row.top >= scrollTop));

			// Find the first row that starts after the bottom of the viewport
			let endIndex = this.rows.findIndex(row => row.top >= scrollBottom);

			// If we're at the bottom, show all remaining rows
			if (endIndex === -1) {
				endIndex = this.rows.length;
			} else {
				endIndex = Math.min(this.rows.length, endIndex + 1);
			}

			// Add bench rows above and below
			const rowsAbove = Math.max(0, startIndex - this.bench);
			const rowsBelow = Math.min(this.rows.length, endIndex + this.bench);

			this.visibleRange = {
				start: rowsAbove,
				end: rowsBelow,
			};
		},
		onScroll: _debounce(function (e: Event) {
			const target = e.target as HTMLElement;
			this.scrollTop = target.scrollTop;
			this.updateVisibleRange();

			// Check if we need to load more
			const remainingScroll = target.scrollHeight - target.scrollTop - target.clientHeight;
			if (remainingScroll < 500 && this.hasMoreResults && !this.isLoadingMore) {
				this.loadNextPage();
			}
		}, 16),
		updateContainerWidth () {
			if (this.$el) {
				const rect = this.$el.getBoundingClientRect();
				this.containerWidth = rect.width - 32;
			}
		},
		async loadNextPage () {
			if (this.isLoadingMore || !this.hasMoreResults) return;

			this.isLoadingMore = true;

			try {
				const response = await this.fetchPageFunction(this.lastDoc, this.pageSize);
				const { results: runnitNodeRuns, hasMore, lastDoc } = response;

				this.hasMoreResults = hasMore;
				this.lastDoc = lastDoc;

				this.runnitNodeRunsById = {
					...this.runnitNodeRunsById,
					...runnitNodeRuns.reduce((acc, run) => {
						acc[run.id] = run;
						return acc;
					}, {} as Record<string, RunnitNodeRun>)
				};

				const resultList = runnitNodeRuns.reduce((acc, nodeRun) => {
					if (!this.showTrainingResults && nodeRun?.nodeDef?.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.TRAINER) {
						return acc;
					}
					const filteredResults = (nodeRun.results || []).filter(result => {
						return this.resultFilter(result);
					});
					return _unionBy(acc, filteredResults, 'uuid');
				}, [] as PlaceholderResult[]);

				this.results = [...this.results, ...resultList];
			} catch (error) {
				console.error('Failed to fetch page:', error);
				throw error;
			} finally {
				this.isLoadingMore = false;
			}
		},
		onNodeRunResultClick (nodeRun: RunnitNodeRun, result: RunnitNodeRunResult): void {
			if (!this.disableCarousel) {
				this.imageInfoCarouselConfig = {
					dialogOpen: !!(nodeRun && result),
					nodeRun,
					nodeRunResult: result,
				};
			}
			this.$emit('on-node-run-result-click', { nodeRun, result });
		},
		handleIsPublicUpdated (payload: { nodeRunResult: RunnitNodeRunResult; nodeRun: RunnitNodeRun }): void {
			this.imageInfoCarouselConfig.nodeRun = { ...payload.nodeRun };
			this.imageInfoCarouselConfig.nodeRunResult = { ...payload.nodeRunResult };
		},
		handleCarouselStep ({ nodeRunResult, nodeRun }): void {
			if (!this.imageInfoCarouselConfig.dialogOpen) return;

			this.imageInfoCarouselConfig = {
				dialogOpen: !!(nodeRun && nodeRunResult),
				nodeRun,
				nodeRunResult,
			};
			const INFINITE_LOAD_BUFFER = 4;
			if (this.results.findIndex(result => result.uuid === nodeRunResult.uuid) > this.results.length - INFINITE_LOAD_BUFFER) {
				this.loadNextPage();
			}
		},
		hasPlaceholderResults (nodeRun: RunnitNodeRun): boolean {
			return this.results.some(result => result.nodeRunId === nodeRun.id && result.isPlaceholder);
		},
		addPlaceholderRun (nodeRun: RunnitNodeRun) {
			// Check if we have placeholder results for this nodeRun
			const hasPlaceholders = this.hasPlaceholderResults(nodeRun);

			if (!hasPlaceholders) {
				const length: number = this._get(nodeRun, `staticInputs[${RUNNIT_NODE_STATIC_FIELDS_KEY.numResults}]`) || 1;
				const placeholderResults = _range(length).map(i => ({
					uuid: `placeholder-${nodeRun.id}-${i}`,
					nodeRunId: nodeRun.id,
					isPlaceholder: true,
					// Add a fixed height for placeholders to ensure consistent row sizing
					// This is used to calculate the aspect ratio. 512x512 is the thumbnail size
					file: {
						width: 512,
						height: 512
					}
				})) as PlaceholderResult[];

				// Add placeholders at the beginning
				this.results = [...placeholderResults, ...this.results];

				// Force a visible range update to ensure all placeholders are visible
				this.$nextTick(() => {
					this.updateVisibleRange();
				});
			}
		},
		addNewRun (nodeRun: RunnitNodeRun) {
			// Add nodeRun to the map
			this.runnitNodeRunsById = {
				[nodeRun.id]: nodeRun,
				...this.runnitNodeRunsById
			};

			// Add results to the results array
			if (nodeRun.results && nodeRun.results.length) {
				const filteredResults = nodeRun.results.filter(this.resultFilter);

				// Check if we have placeholder results for this nodeRun
				const hasPlaceholders = this.hasPlaceholderResults(nodeRun);

				if (hasPlaceholders) {
					let filteredResultsIndex = 0;
					// Replace placeholder results with actual results
					this.results = this.results.map((result) => {
						if (result.nodeRunId === nodeRun.id && result.isPlaceholder) {
							return filteredResults[filteredResultsIndex++] || result;
						}
						return result;
					});
				} else {
					// If no placeholders exist, add new results at the beginning
					this.results = [...filteredResults, ...this.results];
				}
			}
		},
		removeNodeRun (nodeRun: RunnitNodeRun) {
			delete this.runnitNodeRunsById[nodeRun.id];
			this.results = this.results.filter(result => result.nodeRunId !== nodeRun.id);
		},
		onSingleSelect (result: { nodeRunResult: RunnitNodeRunResult, upload: RunnitUpload, avatar: Avatar }) {
			this.$emit('on-single-select', result.nodeRunResult);
		},
		onMultiSelect (result: { nodeRunResult: RunnitNodeRunResult, upload: RunnitUpload, avatar: Avatar }) {
			this.$emit('on-multi-select', result.nodeRunResult);
		},
	},
	async created () {
		try {
			this.initializing = true;
			await this.loadNextPage();

			// Force initial visible range update after first page load
			this.$nextTick(() => {
				this.updateVisibleRange();
			});
		} catch (error) {
			console.error('Failed to load initial data:', error);
		} finally {
			this.initializing = false;
		}
	},
	components: {
		RunnitResult,
		RunnitImageInfoCarouselDialog,
	},
});
