
import Vue from 'vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';

export default Vue.extend({
	name: 'RunnitDialog',
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: null },
		icon: { type: String, default: 'mdi-cog-outline' },
		multiLineHeader: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: 650 },
		bodyHeight: { type: String, default: '80vh' },
		bodyPadding: { type: String, default: '35px' },
		persistent: { type: Boolean, default: false },
		noClose: { type: Boolean, default: false },
		colorBackground: { type: Boolean, default: false },
	},
	data () {
		return {
			open: false,
			hasHeaderNav: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	mounted () {
		this.checkHeaderNav();
	},
	updated () {
		this.checkHeaderNav();
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		checkHeaderNav() {
			this.hasHeaderNav = !!this.$slots['header-nav'] && this.$slots['header-nav'].length > 0;
		},
	},
	components: {
		ComplexBackground,
	},
});
