
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import InfoAssetBanner from '@/components/base/InfoAssetBanner.vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { db } from '@/firebase';
import { NSFW_THRESHOLD, NsfwThreshold } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'TeamRunnitSettings',
	data () {
		return {
			nsfwThresholdOptions: [
				{
					text: 'Maximum Safety',
					value: NSFW_THRESHOLD.SFW_2,
					subheader: 'More likely to block generations that aren\'t NSFW',
				},
				{
					text: 'Block More',
					value: NSFW_THRESHOLD.SFW_1,
					subheader: 'More likely to block generations that aren\'t NSFW',
				},
				{
					text: 'Default',
					value: null,
				},
				{
					text: 'Block Less',
					value: NSFW_THRESHOLD.NSFW_1,
					subheader: 'More likely to allow NSFW generations',
				},
			],
			nsfwThresholdSaving: false,
		};
	},
	computed: {
		...mapState([
			'team',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async setNsfwThreshold (nsfwThreshold: NsfwThreshold) {
			if (this.nsfwThresholdSaving) return;
			this.nsfwThresholdSaving = true;

			try {
				await db.doc(`teams/${this.team.id}`).update({
					nsfwThreshold,
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'NSFW threshold updated successfully',
					show: true,
				});
			} catch (e) {
				console.error('Error updating NSFW threshold:', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Failed to update NSFW threshold',
					show: true,
				});
			} finally {
				this.nsfwThresholdSaving = false;
			}
		},
	},
	components: {
		InfoAssetBanner,
	},
});
