import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
		handler: _vm.intersectHandler,
		threshold: 1,
	}),expression:"{\n\t\thandler: intersectHandler,\n\t\tthreshold: 1,\n\t}"}],staticClass:"image-gallery",style:({
		height: _vm.height,
		...(!_vm.isAppDrawerMode && !_vm.$vuetify.breakpoint.mdAndDown && {
			padding: '65px 0 0 0',
		}),
		...(_vm.backgroundColor && {
			background: _vm.backgroundColor,
		}),
		...(!_vm.backgroundColor && _vm.isFlat && {
			background: 'transparent',
		}),
		...(!_vm.backgroundColor && !_vm.isFlat && !_vm.insideNode && {
			background: 'var(--dark-purple-900)',
		}),
		...(_vm.insideNode && {
			background: 'transparent',
		}),
	})},[(_vm.showTopSettings)?_c('div',{staticClass:"image-gallery-top-section",style:({
			...(_vm.insideDrawer && !_vm.$vuetify.breakpoint.mdAndDown && {
				margin: '-2px 0 0 0',
			}),
		})},[_c('complex-background',{attrs:{"include-left-blobs":true}}),(!_vm.insideDrawer && _vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"image-gallery-top-section-above",style:({
				padding: '0 0 10px 0',
			})},[((
					!_vm.insideDrawer &&
					_vm.$vuetify.breakpoint.smAndUp
				))?_c('div',{staticClass:"image-gallery-title"},[_c(VIcon,{staticStyle:{"font-size":"22px","margin":"0 15px 0 0"}},[_vm._v(" mdi-image-multiple-outline ")]),_c('div',[_vm._v(" Library ")]),(!!_vm.currentSelectionState.nodeRunResultSelectionMode)?_c('div',{staticClass:"selection-mode-notification"},[_c('div',{staticClass:"selection-mode-notification-title"},[_c(VIcon,{staticStyle:{"font-size":"15px","color":"white","margin":"0 7px 0 0"}},[_vm._v("mdi-alert-circle")]),_vm._v(" Selection Mode ")],1),_c('div',{staticClass:"selection-mode-notification-icon-container"},[_c('div',{staticClass:"selection-mode-notification-icon"},[_c(VIcon,{staticStyle:{"font-size":"14px"}},[_vm._v(" mdi-check ")])],1)])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"image-gallery-actions",style:({
					...(_vm.insideDrawer && {
						position: 'relative',
						top: '3px',
					}),
				})},[_c(VBtn,{staticClass:"ml-3",staticStyle:{"margin-right":"-5px"},attrs:{"icon":""},on:{"click":_vm.onClose}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v("mdi-close")])],1)],1)]):_vm._e(),_c('div',{staticClass:"image-gallery-top-section-below"},[_c('div',{staticClass:"image-gallery-top-section-below-left"},[_c('runnit-nav-tabs',{attrs:{"tabs":_vm.imageGalleryNavTabs,"curr-tab-id":_vm.currNavTab,"initial-active-tab-id":_vm.runnitState.imageGalleryDialogInitialNav,"tabs-height":_vm.insideDrawer || _vm.$vuetify.breakpoint.xsOnly ? '53px' : undefined,"icon-size":_vm.insideDrawer || _vm.$vuetify.breakpoint.xsOnly ? '18px' : '15px',"no-label":_vm.insideDrawer || _vm.$vuetify.breakpoint.xsOnly,"active-background-color":_vm.insideDrawer ? 'var(--grey-900)' : 'var(--grey-950)'},on:{"on-curr-tab-id-input":_vm.changeNavTab}})],1),_c('div',{staticClass:"image-gallery-top-section-below-right",style:({
					padding: _vm.insideDrawer ? '0 0 15px 0' : '0 0 10px 0',
				})},[_c('image-mode-button'),(_vm.runnitState.selectedImageMode === _vm.SELECTED_IMAGE_MODE.TILED)?_c('selected-columns-button'):_vm._e(),(_vm.insideDrawer || _vm.$vuetify.breakpoint.xsOnly)?_c(VBtn,{staticClass:"ml-3",staticStyle:{"margin-right":"-5px"},attrs:{"icon":""},on:{"click":_vm.onClose}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v("mdi-close")])],1):_vm._e()],1)])],1):_vm._e(),(!_vm.currNavTab || _vm.currNavTab === _vm.IMAGE_GALLERY_DIALOG_NAV.GENERATIONS)?_c('div',{staticClass:"image-gallery-bottom-section",class:{
			'image-gallery-bottom-section-inside-node': _vm.insideNode,
			'image-gallery-bottom-section-inside-drawer': _vm.insideDrawer,
			'image-gallery-bottom-section-inside-dialog': _vm.insideDialog,
			'image-gallery-bottom-section-is-flat': _vm.isFlat,
		},attrs:{"id":`image-gallery-node-runs-container-${_vm.elIdSuffix}`}},[(!_vm.realtimeNodeRuns.length && !_vm.realtimeNodeRunsLoading && !_vm.moreNodeRuns.length && !_vm.loadingMoreNodeRuns)?_c('div',{staticClass:"full-width"},[_vm._t("emptyState",null,{"showIsQueuingDraftRunnitNodeRunPlaceholderLoaders":_vm.showIsQueuingDraftRunnitNodeRunPlaceholderLoaders})],2):(_vm.runnitState.selectedImageMode === _vm.SELECTED_IMAGE_MODE.TILED)?_c('div',{staticClass:"rd-image-gallery"},[_c('div',{attrs:{"id":`image-gallery-node-runs-anchor-${_vm.elIdSuffix}`}}),_c('transition-group',{staticClass:"transition-group-flex-container",style:({
					'background-color': _vm.insideNode && _vm.isFlat ? 'var(--grey-1000)' : 'transparent',
				}),attrs:{"name":"slide-fade","tag":"div"}},[_c('virtual-scroll-results',{key:"virtual-scroll-node-runs",ref:"virtualScrollResults",attrs:{"fetch-page-function":_vm.fetchNodeRunsPage,"page-size":_vm.moreNodeRunsLimit,"height":_vm.height,"background-color":_vm.insideNode && _vm.isFlat ? 'var(--grey-1000)' : 'transparent',"draggable":_vm.insideNode},on:{"on-single-select":function($event){return _vm.handleSingleSelect($event, 'NodeRunResult')},"on-multi-select":function($event){return _vm.onNodeRunResultMultiSelection($event, 'NodeRunResult')}}})],1)],1):(_vm.runnitState.selectedImageMode === _vm.SELECTED_IMAGE_MODE.INFO)?_c('div',{staticClass:"rd-image-gallery"},[_c('div',{attrs:{"id":`image-gallery-node-runs-anchor-${_vm.elIdSuffix}`}}),(_vm.showIsQueuingDraftRunnitNodeRunPlaceholderLoaders)?_vm._l((_vm.getEnqueueResultsPlaceholder(_vm.draftRunnitNodeRun)),function(iter){return _c(VCol,{key:`placeholder-${_vm.draftRunnitNodeRun.id}-${iter}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-result',{attrs:{"is-loading-placeholder":"","timer-start-at-millis":_vm.draftLoadingTimerStartAtMillisMap[_vm.draftRunnitNodeRun.id] || null}})],1)}):_vm._e(),_vm._l((_vm.reversedRealtimeNodeRuns),function(nodeRun,index){return _c('div',{key:`realtime-${nodeRun.id}`,staticClass:"image-batch"},[_c('image-info',{attrs:{"node-run":nodeRun,"created-at":nodeRun.createdAt,"inside-drawer":_vm.insideDrawer,"inside-node":_vm.insideNode,"index":index}}),_c('div',{staticClass:"rd-image-gallery"},[_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade","tag":"div"}},[((
							(
								!_vm.showIsQueuingDraftRunnitNodeRunPlaceholderLoaders ||
								_vm.draftRunnitNodeRun.id !== nodeRun.id
							) &&
							!_vm._get(nodeRun, 'results.length')
						))?_vm._l((_vm.getEnqueueResultsPlaceholder(nodeRun)),function(iter){return _c(VCol,{key:`placeholder-${nodeRun.id}-${iter}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-result',{attrs:{"is-loading-placeholder":"","timer-start-at-millis":_vm.draftLoadingTimerStartAtMillisMap[nodeRun.id] || null}})],1)}):_vm._e()],2)],1)],1)}),_vm._l((_vm.moreNodeRuns),function(nodeRun,index){return _c('div',{key:`more-${nodeRun.id}`,staticClass:"image-batch"},[_c('image-info',{attrs:{"node-run":nodeRun,"created-at":nodeRun.createdAt,"inside-drawer":_vm.insideDrawer,"inside-node":_vm.insideNode,"index":index}}),_c('div',{staticClass:"rd-image-gallery"},[_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade","tag":"div"}},[(nodeRun.state === _vm.RUNNIT_NODE_RUN_STATE.ERROR)?void 0:(nodeRun.state === _vm.RUNNIT_NODE_RUN_STATE.NSFW && !nodeRun.results.length)?[_c(VCol,{key:`nsfw-${nodeRun.id}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-result',{attrs:{"is-nsfw-placeholder":""}})],1)]:[_vm._l((nodeRun.results),function(result){return [_c(VCol,{key:`result-${result.uuid}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-result',{attrs:{"timer-start-at-millis":_vm.draftLoadingTimerStartAtMillisMap[nodeRun.id] || null,"node-run-result":result,"node-run-id":nodeRun.id,"node-run":nodeRun,"node-def":nodeRun.nodeDef,"is-thumb":_vm.$vuetify.breakpoint.smAndDown,"is-nsfw-placeholder":result.isNsfw},on:{"on-single-select":function($event){return _vm.handleSingleSelect(result, 'NodeRunResult')},"on-multi-select":function($event){return _vm.onNodeRunResultMultiSelection(result)},"on-click":function($event){return _vm.onNodeRunResultClick(nodeRun, result)}}})],1)]})]],2)],1)],1)}),(_vm.canLoadMoreNodeRuns)?_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
					handler: _vm.onNodeRunsInfiniteScroll,
					threshold: 0.5
				}),expression:"{\n\t\t\t\t\thandler: onNodeRunsInfiniteScroll,\n\t\t\t\t\tthreshold: 0.5\n\t\t\t\t}"}],staticClass:"infinite-scroll-trigger"},[(_vm.loadingMoreNodeRuns)?_c('loading-state'):_vm._e()],1):_vm._e()],2):_vm._e()]):(_vm.currNavTab === _vm.IMAGE_GALLERY_DIALOG_NAV.UPLOADS)?_c('div',{staticClass:"image-gallery-bottom-section",class:{
			'image-gallery-bottom-section-inside-node': _vm.insideNode,
			'image-gallery-bottom-section-inside-drawer': _vm.insideDrawer,
			'image-gallery-bottom-section-inside-dialog': _vm.insideDialog,
			'image-gallery-bottom-section-is-flat': _vm.isFlat,
		},attrs:{"id":`image-gallery-uploads-container-${_vm.elIdSuffix}`}},[((!_vm.realtimeUploads.length && _vm.realtimeUploadsLoading) || (!_vm.moreUploads.length && _vm.loadingMoreUploads))?_c('div',{staticClass:"full-width"},[_c('loading-state')],1):(!_vm.realtimeUploads.length && !_vm.realtimeUploadsLoading && !_vm.moreUploads.length && !_vm.loadingMoreUploads)?_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"mb-7"},[_c('image-upload',{attrs:{"mode":_vm.IMAGE_UPLOAD_MODE.RUNNIT_UPLOAD}})],1),_vm._t("emptyState")],2):_c('div',{staticClass:"rd-image-gallery"},[_c('div',{attrs:{"id":`image-gallery-uploads-anchor-${_vm.elIdSuffix}`}}),_c('div',{staticClass:"mb-7"},[_c('image-upload',{attrs:{"mode":_vm.IMAGE_UPLOAD_MODE.RUNNIT_UPLOAD}})],1),_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade","tag":"div"}},[_vm._l((_vm.realtimeUploads),function(upload){return _c(VCol,{key:`realtime-${upload.id}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-image',{attrs:{"upload":upload,"is-thumb":_vm.$vuetify.breakpoint.smAndDown},on:{"on-single-select":function($event){return _vm.handleSingleSelect(upload, 'Upload')},"on-multi-select":function($event){return _vm.onUploadMultiSelection(upload)}}})],1)}),_vm._l((_vm.moreUploads),function(upload){return _c(VCol,{key:`more-${upload.id}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-image',{attrs:{"upload":upload,"is-thumb":_vm.$vuetify.breakpoint.smAndDown},on:{"on-single-select":function($event){return _vm.handleSingleSelect(upload, 'Upload')},"on-multi-select":function($event){return _vm.onUploadMultiSelection(upload)}}})],1)})],2),(_vm.canLoadMoreUploads)?_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
					handler: _vm.onUploadsInfiniteScroll,
					threshold: 0.5
				}),expression:"{\n\t\t\t\t\thandler: onUploadsInfiniteScroll,\n\t\t\t\t\tthreshold: 0.5\n\t\t\t\t}"}],staticClass:"infinite-scroll-trigger"},[(_vm.loadingMoreUploads)?_c('loading-state'):_vm._e()],1):_vm._e()],1)]):(_vm.currNavTab === _vm.IMAGE_GALLERY_DIALOG_NAV.AVATARS)?_c('div',{staticClass:"image-gallery-bottom-section",class:{
			'image-gallery-bottom-section-inside-node': _vm.insideNode,
			'image-gallery-bottom-section-inside-drawer': _vm.insideDrawer,
			'image-gallery-bottom-section-inside-dialog': _vm.insideDialog,
			'image-gallery-bottom-section-is-flat': _vm.isFlat,
		},attrs:{"id":`image-gallery-avatars-container-${_vm.elIdSuffix}`}},[((!_vm.realtimeAvatars.length && _vm.realtimeAvatarsLoading) || (!_vm.moreAvatars.length && _vm.loadingMoreAvatars))?_c('div',{staticClass:"full-width"},[_c('loading-state')],1):(!_vm.realtimeAvatars.length && !_vm.realtimeAvatarsLoading && !_vm.moreAvatars.length && !_vm.loadingMoreAvatars)?_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"mb-7"},[_c('image-upload',{attrs:{"mode":_vm.IMAGE_UPLOAD_MODE.AVATAR,"avatar-use-case":_vm.AVATAR_USE_CASE.RUNNIT}})],1),_vm._t("emptyState")],2):_c('div',{staticClass:"rd-image-gallery"},[_c('div',{attrs:{"id":`image-gallery-avatars-anchor-${_vm.elIdSuffix}`}}),_c('div',{staticClass:"mb-7"},[_c('image-upload',{attrs:{"mode":_vm.IMAGE_UPLOAD_MODE.AVATAR,"avatar-use-case":_vm.AVATAR_USE_CASE.RUNNIT}})],1),_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade","tag":"div"}},[_vm._l((_vm.realtimeAvatars),function(avatar){return _c(VCol,{key:`realtime-${avatar.id}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-image',{attrs:{"avatar":avatar,"is-avatar":""},on:{"on-single-select":function($event){return _vm.handleSingleSelect(avatar, 'Avatar')},"on-multi-select":function($event){return _vm.onAvatarMultiSelection(avatar)}}})],1)}),_vm._l((_vm.moreAvatars),function(avatar){return _c(VCol,{key:`more-${avatar.id}`,staticStyle:{"padding":"7px !important"},attrs:{"cols":_vm.runnitState.selectedColumns}},[_c('runnit-image',{attrs:{"avatar":avatar,"is-avatar":""},on:{"on-single-select":function($event){return _vm.handleSingleSelect(avatar, 'Avatar')},"on-multi-select":function($event){return _vm.onAvatarMultiSelection(avatar)}}})],1)})],2),(_vm.canLoadMoreAvatars)?_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
					handler: _vm.onAvatarsInfiniteScroll,
					threshold: 0.5
				}),expression:"{\n\t\t\t\t\thandler: onAvatarsInfiniteScroll,\n\t\t\t\t\tthreshold: 0.5\n\t\t\t\t}"}],staticClass:"infinite-scroll-trigger"},[(_vm.loadingMoreAvatars)?_c('loading-state'):_vm._e()],1):_vm._e()],1)]):_vm._e(),_c('runnit-image-info-carousel-dialog',{attrs:{"value":_vm.imageInfoCarouselConfig.dialogOpen,"node-run":_vm.imageInfoCarouselConfig.nodeRun,"node-runs":_vm.moreNodeRuns,"node-run-result":_vm.imageInfoCarouselConfig.nodeRunResult},on:{"on-prev-or-next-result":function($event){return _vm.handleCarouselStep($event)},"input":function($event){_vm.imageInfoCarouselConfig.dialogOpen = $event},"is-public-updated":_vm.handleIsPublicUpdated}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }