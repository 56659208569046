
import Vue from 'vue';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import ActionsIslandFixedBottom from '@/components/ActionsIslandFixedBottom.vue';

export default Vue.extend({
	name: 'BaseDrawerOrBottomSheet',
	components: {
		ActionsIslandFixedBottom,
		BaseDrawer,
	},
	props: {
		// shared props
		value: { type: Boolean, default: false },
		overrideHidden: { type: Boolean, default: false },
		eager: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		makeTriggerContentCentered: { type: Boolean, default: false },
		scrollable: { type: Boolean, default: false },
		// drawer props
		drawerAlwaysFullHeight: { type: Boolean, default: false },
		permanent: { type: Boolean, default: false },
		fixed: { type: Boolean, default: true },
		right: { type: Boolean, default: true },
		drawerWidth: { type: Number, default: 700 },
		title: { type: String, default: '' },
		showActions: { type: Boolean, default: false },
		closeOnRight: { type: Boolean, default: false },
		closeIcon: { type: String, default: 'mdi-arrow-right' },
		containerStyles: { type: [String, Object], default: null },
		actionsIslandStyles: { type: Object, default: () => ({ height: '60px' }) },
		actionsIslandIsFixedBottom: { type: Boolean, default: false },
		clipped: { type: Boolean, default: false },
		app: { type: Boolean, default: false },
		hideOverlay: { type: Boolean, default: false },
		// bottom sheet props
		bottomSheetPersistent: { type: Boolean, default: false },
		bottomSheetOnSmall: { type: Boolean, default: false },
		bottomSheetOnMedium: { type: Boolean, default: false },
		isBottomSheet: { type: Boolean, default: false },
	},
	data () {
		return {
			isOpen: this.value,
		};
	},
	watch: {
		value(val) {
			this.setValue(val);
		},
	},
	computed: {
		drawerBreakPoint() {
			let breakPoint = this.$vuetify.breakpoint.smAndUp;

			if (this.bottomSheetOnSmall && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.mdAndUp;
			}

			if (this.bottomSheetOnMedium && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.lgAndUp;
			}

			if (this.isBottomSheet) {
				breakPoint = null;
			}

			return breakPoint;
		},
		bottomSheetBreakPoint() {
			let breakPoint = this.$vuetify.breakpoint.xsOnly;

			if (this.bottomSheetOnSmall && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.smAndDown;
			}

			if (this.bottomSheetOnMedium && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.mdAndDown;
			}

			if (this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.smAndUp;
			}

			return breakPoint;
		},
		computedIsOpen() {
			return !!(
				this.isOpen &&
				!this.overrideHidden
			);
		},
		isOpenButOverrideHidden() {
			return !!(
				this.isOpen &&
				this.overrideHidden
			);
		},
	},
	methods: {
		close() {
			this.setValue(false);
		},
		open() {
			this.setValue(true);
		},
		setValue(val) {
			if (val !== this.isOpen) {
				this.$emit('input', val);
			}
			this.isOpen = val;
		},
	},
});
