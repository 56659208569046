import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.open,"max-width":_vm.maxWidth,"persistent":_vm.persistent || _vm.confirmLoading,"overlay-opacity":"0.8"},on:{"input":_vm.setOpen},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_vm._t("activator",null,null,props)]}}],null,true)},[_c(VCard,[(_vm.title || _vm.showTopRightCloseBtn)?_c(VCardTitle,{staticClass:"px-12 pt-12"},[_c('span',{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),(_vm.showTopRightCloseBtn)?_c('base-button',{attrs:{"disabled":_vm.disabled || _vm.confirmLoading,"secondary":"","icon":"","small":""},on:{"click":_vm.onCancel}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e(),_c(VCardText,{staticClass:"px-12 d-flex flex-column justify-center align-center",class:{
				'pt-6': !_vm.title,
			}},[(_vm.bodyText)?_c('div',{staticClass:"py-6"},[_vm._v(_vm._s(_vm.bodyText))]):_vm._e(),_vm._t("body")],2),_c(VCardActions,{staticClass:"d-flex justify-center px-12 pb-12"},[(!_vm.hideCancel)?_c('base-button',{staticClass:"mr-4 px-4",attrs:{"disabled":_vm.disabled || _vm.confirmLoading,"text":""},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]):_vm._e(),(!_vm.hideConfirm)?_c('base-button',_vm._b({staticClass:"px-8",attrs:{"disabled":_vm.disabled || _vm.confirmDisabled,"loading":_vm.confirmLoading},on:{"click":_vm.onConfirm}},'base-button',_vm.confirmBtnProps,false),[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }