
import RunnitsVerticalLogoWithRDSVG from '@/assets/RunnitsWithRDVerticalLogoSVG.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import LoadingState from "@/components/states/LoadingState.vue";
import { RunnitsCRUDMixin, TeamUserMixin } from '@/mixins';
import { ROUTER } from '@/router/constants';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { RUNNIT_TYPE } from '@run-diffusion/shared';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';
import ComplexBackgroundSideNav from '@/views/Runnits/RunnitSideNav/ComplexBackgroundSideNav.vue';
import RunnitTokens from '@/views/Runnits/RunnitTokens.vue';
import RunDiffusionWithRunnitMarkLogo from '@/assets/RunDiffusionWithRunnitMarkLogo.vue';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { setPlatformHomePreference } from '@/utils';

export default Vue.extend({
	name: 'RunnitSideNav',
	mixins: [
		RunnitsCRUDMixin,
		TeamUserMixin,
	],
	props: {},
	data () {
		return {
			ROUTER,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		...mapGetters([
			'showStripeUserMembershipPortalBtn',
		]),
		...mapGetters('modelUpload', [
			'getUploading',
		]),
		isSubscriptionsRoute () {
			return this.$route.name === ROUTER.RUNNITS_SUBSCRIPTIONS;
		},
		isHomeRoute () {
			return this.$route.name === ROUTER.RUNNITS_HOME;
		},
		isModelsRoute () {
			return this.$route.name === ROUTER.RUNNITS_MODELS_LIST;
		},
		isAllToolsRoute () {
			return this.$route.name === ROUTER.RUNNITS_ALL_TOOLS;
		},
		isBoardsRoute () {
			return this.$route.name === ROUTER.RUNNITS_BOARDS;
		},
		isLibraryRoute () {
			return this.$route.name === ROUTER.RUNNITS_LIBRARY;
		},
		isAppsRoute () {
			return this.$route.name === ROUTER.RUNNITS_APP_LIBRARY;
		},
		isHelpRoute () {
			return this.$route.name === ROUTER.HELP_CENTER;
		},
		isWhatsNewRoute () {
			return this.$route.name === ROUTER.RUNNITS_WHATS_NEW;
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateToolbar',
		]),
		goTo (routeName: string) {
			this.routerPush(this.$route, this.$router, { name: routeName });
		},
		onManagePlanClick () {
			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER && this.user.clubOffer.isFreemium) {
				this.goTo(ROUTER.RUNNITS_SUBSCRIPTIONS);
			} else {
				this.setUpsellDialog({
					runnitsPlanDetailsData: {
						open: true,
						scrollToPrivateBalance: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER,
						scrollToTeamBalance: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM,
					},
				});
			}
		},
		openRunDiffusionInNewTab () {
			// They are a new user, automatically getting redirected to Runnit, and they want to go back to RD, so don't auto redirect again endlessly.
			setPlatformHomePreference('RD');
			const routeData = this.$router.resolve({
				name: ROUTER.SESSIONS,
			});
			window.open(routeData.href, '_blank');
		},
		openWhatsNew() {
			this.goTo(ROUTER.RUNNITS_WHATS_NEW);
		},
		closeSideNav () {
			this.updateToolbar({
				runnitsSideNavOpen: false,
			});
		},
		async handleAddRunnitClick () {
			const runnitDraft = await this.createRunnitDraft(null, null, RUNNIT_TYPE.WORKFLOW);
			this.updateRunnitState({
				runnitDraft,
				runnitDraftNodeRef: null,
				runnit: null,
				configureRunnitOpen: true,
			});
		},
	},
	components: {
		LoadingState,
		ComplexBackgroundSideNav,
		RunnitTokens,
		RunnitAccountSelectionDialog,
		GlassButton,
		RunnitsVerticalLogoWithRDSVG,
		RunDiffusionWithRunnitMarkLogo,
	},
});
