
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import _unionBy from 'lodash/unionBy';
import _shuffle from 'lodash/shuffle';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import {
	RunnitsAccessLevel,
	RUNNITS_ACCESS_LEVEL,
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_TYPE,
	RunnitNodeDef,
	RunnitNodeRunResult,
	RunnitNodeRun,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
	Avatar,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION, SELECTED_COLUMNS_MAP, FEATURED_RUNNIT_NODE_DEF_IDS } from '@/views/Runnits/constants';
import { getRunnitsHomeLibrarySelection, setRunnitsHomeLibrarySelection, setRunnitsTeamAccessLevel } from '@/utils';
import { RunnitRemixMixin, RunnitsCRUDMixin, TeamUserMixin } from '@/mixins';
import TeamPicker from '@/components/TeamPicker.vue';
import OfferingCard from '@/components/OfferingCard.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import RunnitCarousel from '../RunnitCarousel.vue';
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';
import RunnitResult from '../RunnitResult.vue';
import { db } from '@/firebase';
import RunnitImageInfoCarouselDialog from '../RunnitImageInfoCarouselDialog.vue';
import SelectedColumnsButton from '@/components/ImageGallery/SelectedColumnsButton.vue';
import ImageModeButton from '@/components/ImageGallery/ImageModeButton.vue';
import RunnitIntentionsBar from '../RunnitIntentionsBar.vue';
import RunnitToolPromoCard from '../RunnitToolPromoCard.vue';
export type LibrarySelection = 'FEATURED' | 'PERSONAL';

export default Vue.extend({
	name: 'RunnitsHome',
	mixins: [
		RunnitsCRUDMixin,
		TeamUserMixin,
	],
	data () {
		return {
			RUNNIT_TYPE,
			RUNNITS_OWNER_SELECTION,
			RUNNITS_ACCESS_LEVEL,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			SELECTED_COLUMNS_MAP,
			FEATURED_RUNNIT_NODE_DEF_IDS,
			addingNewRunnit: false,

			slideGroupState: null,
			hasPrev: false,

			librarySelection: 'FEATURED' as LibrarySelection,
			featuredNodeRunsPagesLoading: false as boolean,
			featuredNodeRunsLoading: false as boolean,
			isLoadingMore: false as boolean,
			featuredNodeRuns: [] as RunnitNodeRun[],
			pageSize: 50,
			usedPages: new Set<number>(),
			totalPages: 0,
			allNodeRunIds: [] as string[],
			hasMorePages: true,
			imageInfoCarouselConfig: {
				dialogOpen: false,
				nodeRun: null,
				nodeRuns: null,
				nodeRunResult: null,
			},
		};
	},
	async created () {
		await this.initFeaturedNodeRuns();
	},
	mounted () {
		this.updateArrowVisibility();
		this.librarySelection = getRunnitsHomeLibrarySelection() || 'FEATURED';

		// Add resize listener to update arrow visibility on window resize
		window.addEventListener('resize', this.updateArrowVisibility);
		// Add scroll listener for infinite scroll
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	},
	beforeDestroy () {
		// Remove resize listener
		window.removeEventListener('resize', this.updateArrowVisibility);
		// Remove scroll listener
		window.removeEventListener('scroll', this.handleScroll);

		// Reset pagination state
		this.featuredNodeRuns = [];
		this.usedPages.clear();
		this.hasMorePages = true;
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitsMap',
			'runnitState',
			'loadingRunnits',
			'recentUserRunnitNodeDefInfos',
		]),
		...mapGetters([
			'runnitsLimitMap',
		]),
		computedVisibleRunnits () {
			return 100;
		},
		featuredNodeRunResults (): RunnitNodeRunResult[] {
			if (!this.featuredNodeRuns || !this.featuredNodeRuns.length) {
				return [];
			}
			return this.featuredNodeRuns.flatMap(nodeRun => nodeRun.results.filter(result => result.featured));
		},
		nodeRunByIdMap (): Record<string, RunnitNodeRun> {
			if (!this.featuredNodeRuns || !this.featuredNodeRuns.length) {
				return {};
			}
			return this.featuredNodeRuns.reduce((map: Record<string, RunnitNodeRun>, nodeRun: RunnitNodeRun) => {
				map[nodeRun.id] = nodeRun;
				return map;
			}, {})
		},
		visibleRunnitNodeDefInfos () {
			return (this.recentUserRunnitNodeDefInfos || []).filter((toolInfo) => !toolInfo.nodeDef.isDeleted &&
				(
					this.user &&
					toolInfo.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT &&
					(
						this.user.isAdmin ||
						toolInfo.nodeDef.isPublished
					)) &&
				(
					!toolInfo.nodeDef.teamIds || // No team means it's available to anyone
					(
						this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
						this.team &&
						toolInfo.nodeDef.teamIds[this.team.id]
					))
			).slice(0, 5);
		},
		featuredColumns () {
			const numColumns = this.$vuetify.breakpoint.xsOnly ? 2 :
				(SELECTED_COLUMNS_MAP[this.runnitState.selectedColumns] || 4);

			const columns = Array.from({ length: numColumns }, () => []);
			const columnHeights = Array.from({ length: numColumns }, () => 0);

			if (!this.featuredNodeRunResults) return columns;

			this.featuredNodeRunResults.forEach((result, index) => {
				// Get image dimensions from result.file
				const height = result.file?.height || 1;
				const width = result.file?.width || 1;
				const aspectRatio = width / height;

				// Calculate normalized height (relative to a standard width of 1)
				const normalizedHeight = 1 / aspectRatio;

				const { shortestColumnIndex } = columnHeights.reduce((acc, height, index) => {
					return height < acc.minHeight ? { minHeight: height, shortestColumnIndex: index } : acc;
				}, { minHeight: columnHeights[0], shortestColumnIndex: 0 });

				// Add the image to the shortest column
				columns[shortestColumnIndex].push(result);

				// Update the column's total height
				columnHeights[shortestColumnIndex] += normalizedHeight;
			});

			return columns;
		},
	},
	methods: {
		...mapActions([
			'updateLoader',
			'updateSnackbar',
			'updateRunnitState',
			'setUpsellDialog',
		]),
		_isEmpty,
		onRunnitsAccessLevelChange (runnitsAccessLevel: RunnitsAccessLevel) {
			setRunnitsTeamAccessLevel(runnitsAccessLevel);
			this.updateRunnitState({
				runnitsAccessLevel,
			});
		},
		goHome () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.SESSIONS });
		},
		goToAllTools () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_ALL_TOOLS });
		},
		goToRunnitBoards () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_BOARDS });
		},
		updateArrowVisibility () {
			const slideGroup = this.$refs.slideGroup as any;

			if (slideGroup && slideGroup.$el) {
				const container = slideGroup.$el.querySelector('.v-slide-group__content');
				if (container) {
					this.hasPrev = container.scrollLeft > 0;
				}
			}
		},
		async initFeaturedNodeRuns () {
			try {
				this.featuredNodeRunsPagesLoading = true;

				// First, get total count to calculate pages
				const nodeRunsRef = db.collection('runnitNodeRuns').where('featured', '==', true);
				const snapshot = await nodeRunsRef.get();
				this.allNodeRunIds = snapshot.docs.map(doc => doc.id);
				this.totalPages = Math.ceil(this.allNodeRunIds.length / this.pageSize);

				// Load first random page
				await this.loadNextRandomPage();
			} catch (error) {
				console.error('Error fetching featured images', error);
			} finally {
				this.featuredNodeRunsPagesLoading = false;
			}
		},
		handleScroll () {
			if (!this.totalPages || this.isLoadingMore || !this.hasMorePages || this.featuredNodeRunsLoading || this.librarySelection !== 'FEATURED') {
				return;
			}

			const scrollPosition = window.scrollY + window.innerHeight;
			const threshold = document.documentElement.scrollHeight - 800; // Load more when within 800px of bottom

			if (scrollPosition > threshold) {
				this.loadNextRandomPage();
			}
		},
		async loadNextRandomPage () {
			if (!this.hasMorePages || this.featuredNodeRunsLoading || this.isLoadingMore) return;

			try {
				this.isLoadingMore = true;
				this.featuredNodeRunsLoading = true;

				// Get available pages that haven't been used yet
				const availablePages = Array.from(
					{ length: this.totalPages },
					(_, i) => i
				).filter(page => !this.usedPages.has(page));

				if (availablePages.length === 0) {
					this.hasMorePages = false;
					return;
				}

				// Pick a random available page
				const randomIndex = Math.floor(Math.random() * availablePages.length);
				const selectedPage = availablePages[randomIndex];
				this.usedPages.add(selectedPage);

				// Get IDs for this page
				const startIndex = selectedPage * this.pageSize;
				const pageIds = this.allNodeRunIds.slice(startIndex, startIndex + this.pageSize);

				// Fetch the actual documents
				const newNodeRuns = await Promise.all(
					pageIds.map(async (id) => {
						const doc = await db.collection('runnitNodeRuns').doc(id).get();
						if (!doc.exists) return null;

						let fetchedNodeDef = null;
						const data = doc.data();

						if (data.nodeDef && !data.nodeDef.title) {
							try {
								const nodeDefRef = db.doc(`runnitNodeDefs/${data.nodeDefId}`);
								const nodeDef = (await nodeDefRef.get()).data();
								fetchedNodeDef = { ...nodeDef, id: data.nodeDefId };
							} catch (err) {
								console.error('Failed to fetch the generating tool', err);
							}
						}

						return {
							...data,
							...(fetchedNodeDef ? { nodeDef: fetchedNodeDef } : {}),
							id: doc.id,
						};
					})
				);

				// Filter out null values and add to existing runs
				const validNodeRuns = newNodeRuns.filter(run => run !== null);
				this.featuredNodeRuns = [...this.featuredNodeRuns, ..._shuffle(validNodeRuns)];

				// Update hasMorePages based on available unused pages
				this.hasMorePages = this.usedPages.size < this.totalPages;

			} catch (error) {
				console.error('Error loading next page', error);
			} finally {
				this.featuredNodeRunsLoading = false;
				this.isLoadingMore = false;
			}
		},
		onNodeRunResultClick (nodeRun: RunnitNodeRun, nodeRunResult: RunnitNodeRunResult) {
			this.imageInfoCarouselConfig = {
				dialogOpen: !!(nodeRun && nodeRunResult),
				nodeRun,
				nodeRunResult,
			};
		},
		featuredResultFilter (result: RunnitNodeRunResult) {
			return result.featured;
		},
		setLibrarySelection (val) {
			this.librarySelection = val;
			setRunnitsHomeLibrarySelection(val);
		},
		async onToolCardClick (nodeDef: RunnitNodeDef) {
			await this.onAddRunnitClick(nodeDef, RUNNIT_TYPE.SINGLE_TOOL);
		},
		handleIsPublicUpdated (payload: { nodeRunResult: RunnitNodeRunResult, nodeRun: RunnitNodeRun }) {
			this.imageInfoCarouselConfig.nodeRun = { ...payload.nodeRun };
			this.imageInfoCarouselConfig.nodeRunResult = { ...payload.nodeRunResult };
		},
	},
	components: {
		EmptyState,
		TeamPicker,
		OfferingCard,
		RunnitCarousel,
		ImageGallery,
		RunnitResult,
		RunnitImageInfoCarouselDialog,
		SelectedColumnsButton,
		ImageModeButton,
		RunnitIntentionsBar,
		RunnitToolPromoCard,
	},
});
