<template>
	<div>
		<div style="margin: 20px 0 45px 0; font-size: 20px;">
			Now that your server is <b>launched</b>, you can connect to it by following the steps below:
		</div>
		<instructions-step
			color="#6f86ff"
			step-number="1"
			class="mt-6"
		>
			<div>
				Now go to the settings after you have opened the <b>PCoIPClient</b>
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/post-launch-step-1.png">
			</div>
		</instructions-step>
		<instructions-step
			color="#ff7586"
			step-number="2"
			class="mt-10"
		>
			<div>
				Set the security mode to low so you don't have to go through enterprise level steps to connect
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/post-launch-step-2.png">
			</div>
		</instructions-step>
		<instructions-step
			color="#b372ce"
			step-number="3"
			class="mt-10"
		>
			<div>
				Now using the <b>IP Address</b> of the server we launched for you, below you will put it in the <b>Host Address or Registration Code</b> field.
				<br />
				<br />
				Then just put in something like 'Run Diffusion' for the Connection Name
				<br />
				<br />
				Then click 'Add connection'
			</div>
			<div class="mt-8 mb-1 ml-8 d-flex flex-column" style="font-size: 16px;">
				<div class="mb-2 d-flex">
					<div>
						IP Address: <span style="font-size: 20px; margin: 0 0 0 17px;"><b>{{ session.serverWorkstationIp }}</b></span>
					</div>
					<clipboard-copy
						:value="session.serverWorkstationIp"
					>
						<template v-slot:activator="{ on, attrs }">
							<base-button
								v-on="on"
								v-bind="attrs"
								small
								call-to-action
								class="ml-4"
							>
								<b>Copy</b>
							</base-button>
						</template>
					</clipboard-copy>
				</div>
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/post-launch-step-3.png">
			</div>
		</instructions-step>
		<instructions-step
			color="#6f86ff"
			step-number="4"
			class="mt-10"
		>
			<div>
				Now put in the following <b>Username</b> and <b>Password</b>, and click <b>'Connect'</b>
			</div>
			<div class="mt-8 mb-4 ml-8 d-flex flex-column" style="font-size: 16px;">
				<div class="mb-2">
					Username: <span style="font-size: 20px; margin: 0 0 0 10px;"><b>workspace</b></span>
				</div>
				<div>
					Password: <span style="font-size: 20px; margin: 0 0 0 15px;"><b>rdconnect123</b></span>
				</div>
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/post-launch-step-4.png">
			</div>
		</instructions-step>
	</div>
</template>

<script>
import Vue from 'vue';
import InstructionsStep from '../../InstructionsStep';
import ClipboardCopy from '@/components/ClipboardCopy';

export default Vue.extend({
	name: 'DreamBoothPostLaunch',
	props: {
		session: { type: Object, required: true },
	},
	components: {
		ClipboardCopy,
		InstructionsStep,
	},
});
</script>
