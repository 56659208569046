import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-upload-container"},[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field,"adminOnly":_vm.adminOnly}}),_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade-absolute","tag":"div"}},[_c('image-input-selected-image-container',{key:"option-select",attrs:{"library-text":"Library","value":_vm.value,"hasInpainting":_vm.hasInpainting,"hasStrength":_vm.hasStrength},on:{"on-clear":_vm.clearValueAndStartSelectSelectionMode,"on-library":function($event){return _vm.startLibrarySelectionMode(_vm.IMAGE_GALLERY_DIALOG_NAV.GENERATIONS)},"on-strength-change":_vm.onStrengthChange,"on-upload":_vm.handleImageUpload,"on-toggle-image-selection-mode":_vm.onToggleImageSelectionMode}})],1),_c(VTextField,{staticClass:"pl-2 pt-2 pr-0 pb-0 my-0 rules-only-validation-text-field",class:_vm.field.required ? 'required' : '',attrs:{"rules":[
			...(_vm.field.required ? _vm.rules.requiredFileInput : _vm.rules.optionalFileInput),
			_vm.validateImageSize,
			_vm.validateImageAspectRatio,
		],"value":_vm.imageValue,"readonly":"","required":"","hide-details":"auto","single-line":""}}),(_vm.inpaintingRequired)?_c(VTextField,{staticClass:"pl-2 pt-2 pr-0 pb-0 my-0 rules-only-validation-text-field",class:_vm.field.required ? 'required' : '',attrs:{"rules":_vm.rules.requiredInpaintingInput,"value":_vm.inpaintingValue.mask,"readonly":"","required":"","hide-details":"auto","single-line":""}}):_vm._e(),(_vm.runnitState.inpaintingEditorOpen)?_c('inpainting-editor',{staticClass:"absolutely-positioned-anchor",attrs:{"maskURL":_vm.runnitState.inpaintingMaskUrl,"nodeId":_vm.nodeId},on:{"input":_vm.onInpaintingMaskChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }