
import Vue from 'vue';
import RunnitDialog from './RunnitDialog.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import {
	THUMB_SIZE,
	RUNNITS_ACCESS_LEVEL,
	RUNNIT_NODE_DEF_TAG_TYPE,
	Model,
	RunnitNodeDefTag,
} from '@run-diffusion/shared';
import { mapActions, mapState } from "vuex";
import RunnitNavTabs from "@/views/Runnits/base/RunnitNavTabs.vue";
import {
	TRAINING_TYPE_CARDS,
	RUNNITS_OWNER_SELECTION,
} from "@/views/Runnits/constants";
import EmptyState from "@/components/states/EmptyState.vue";
import { db } from "@/firebase";
import { get$bindFirestoreOptions } from "@/mixins";
import { SNACKBAR_STATUS } from "@/constants/constants";
import OfferingCard from "@/components/OfferingCard.vue";
import { ROUTER } from "@/router/constants";
import ModelTagTypeSelect from "@/views/Runnits/pages/models/ModelTagTypeSelect.vue";
import { fuzzyFilter } from '@/utils/fuzzySearch';
export default Vue.extend({
	name: 'RunnitModelsDialog',
	props: {
		value: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '1100px' },
		persistent: { type: Boolean, default: false },
		modelArchitecture: { type: String, required: true },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
			RUNNITS_ACCESS_LEVEL,
			THUMB_SIZE,
			TRAINING_TYPE_CARDS,
			open: false,
			currNavTab: RUNNITS_ACCESS_LEVEL.PRIVATE,

			loadingModels: false,
			models: [],

			modelSearchValue: '',
			filtersCollapsed: false,

			isAllTypesSelected: null,
			selectedTypes: [],
		};
	},
	created () {
		this.open = !!this.value;
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnitState',
			'modelsState',
			'publicRunnitNodeDefTagsMap',
		]),
		modelsNavTabs () {
			const hasTeam = this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team?.id;

			const tabs = [{
				id: RUNNITS_ACCESS_LEVEL.PRIVATE,
				label: 'Your Models',
				icon: 'mdi-vector-circle',
			},];

			if (hasTeam) {
				tabs.push({
					id: RUNNITS_ACCESS_LEVEL.SHARED,
					label: 'Team Models',
					icon: 'mdi-account-group-outline',
				},)
			}

			return tabs;
		},
		filteredModels () {
			return this.models.filter((model: Model) => {
				// Filter by model architecture if specified
				if (this.modelArchitecture && model.architecture !== this.modelArchitecture) {
					return false;
				}

				// Filter out non done models
				if (!model.file) {
					return false;
				}

				// Type filter
				const matchesType = !this.selectedTypes?.length ||
					this.selectedTypes.some(type => model.tags?.[type]);
				if (!matchesType) return false;

				// Filter by search text
				if (!this.modelSearchValue) {
					return true;
				}
				return fuzzyFilter(
					[model],
					this.modelSearchValue,
					[
						{ name: 'name', weight: 0.7 },
						{ name: 'description', weight: 0.3 }
					]
				).length > 0;
			});
		},
		qualityTags () {
			return this.filteredModels
				.map((model: Model) => model.tags)
				.map((tags: Record<string, RunnitNodeDefTag>) => Object.keys(tags))
				.map((tagIds: string[]) => tagIds.map((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]))
				.flat()
				.filter((tag: RunnitNodeDefTag) => tag && tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY);
		},
	},
	watch: {
		value (newVal: boolean) {
			this.setOpen(newVal);
		},
		currNavTab: {
			immediate: true,
			async handler (newTabId) {
				try {
					this.loadingModels = true;
					if (this.team && this.user) {
						// index created: models - teamId Ascending accessLevel Ascending isDeleted Ascending createdAt Descending __name__ Descending
						let modelsRef: any = db.collection(`models`)
							.where('teamId', '==', this.team.id)
							.where('accessLevel', '==', newTabId)
							.where('isDeleted', '==', false)
							.orderBy('createdAt', 'desc')
							.limit(500);
						if (newTabId !== RUNNITS_ACCESS_LEVEL.SHARED) {
							modelsRef = modelsRef.where('userId', '==', this.user.id);
						}
						await this.$bind(
							'models',
							modelsRef,
							get$bindFirestoreOptions(),
						);
					} else if (this.user) {
						// index created: models - userId Ascending teamId Ascending accessLevel Ascending isDeleted Ascending createdAt Descending __name__ Descending
						const modelsRef: any = db.collection(`models`)
							.where('userId', '==', this.user.id)
							.where('teamId', '==', null)
							.where('accessLevel', '==', newTabId)
							.where('isDeleted', '==', false)
							.orderBy('createdAt', 'desc')
							.limit(100);
						await this.$bind(
							'models',
							modelsRef,
							get$bindFirestoreOptions(),
						);
					}
				} catch (e) {
					console.error(e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error loading models',
						show: true,
					});
				} finally {
					this.loadingModels = false;
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateModelState',
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onModelSelect (model) {
			this.$emit('on-model-select', model);
			this.setOpen(false);
		},
		changeNavTab (tabId) {
			this.currNavTab = tabId;
			this.$emit('on-nav-tab-change', tabId);
		},
		onEditModelSettings (model) {
			if (model) {
				this.updateModelState({
					modelDraft: model,
					modelDialog: {
						...this.modelsState.modelDialog,
						open: true,
						mode: 'edit',
					},
				});
			}
		},
		goToModelsPage () {
			this.setOpen(false);
			this.routerPush(this.$route, this.$router, {
				name: ROUTER.RUNNITS_MODELS_LIST,
			});
		},
		handleSearchInput (searchValue) {
			this.modelSearchValue = searchValue;
		},
		toggleFilterCollapse () {
			this.filtersCollapsed = !this.filtersCollapsed;
		},
		handleMultiSelectFilter (tagIds: string[]) {
			this.selectedTypes = tagIds;
		},
	},
	components: {
		ModelTagTypeSelect,
		OfferingCard,
		EmptyState,
		RunnitNavTabs,
		RunnitDialog,
		LoadingState,
	},
});
