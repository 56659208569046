
import Vue from 'vue';
import TokensSVG from '@/assets/TokensSVG.vue';
import { mapState } from 'vuex';
import _sortBy from 'lodash/sortBy';
import { ClubOffer, DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT, RunnitNodeDef } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'TokenCalculationsTable',
	props: {
		clubOffers: { type: Array, default: () => ([]) },
		tokenClubAddOnsMap: { type: Object, default: () => ({}) },
	},
	data () {
		return {
			sortedNodeDefs: [],
		};
	},
	computed: {
		...mapState([
			'publicRunnitNodeDefs',
			'trainerNodeDefs',
		]),
	},
	watch: {
		publicRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				if (newVal !== oldVal) {
					const combined = [...(newVal || []), ...(this.trainerNodeDefs || [])];
					this.sortedNodeDefs = _sortBy(combined, ['costPerResult', 'title']);
				}
			},
		},
		trainerNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				if (newVal !== oldVal) {
					const combined = [...(newVal || []), ...(this.publicRunnitNodeDefs || [])];
					this.sortedNodeDefs = _sortBy(combined, ['costPerResult', 'title']);
				}
			},
		},
	},
	methods: {
		getClubOfferTokens (clubOffer: ClubOffer) {
			if (!clubOffer) return 0;
			let tokens: number = 0;
			if (clubOffer.isFreemium) {
				tokens = DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT;
			} else if (this.tokenClubAddOnsMap[clubOffer.club]) {
				tokens = this.tokenClubAddOnsMap[clubOffer.club].tokens || 0;
			}
			return tokens;
		},
		getNumNodeDefResultsForTokens (clubOffer: ClubOffer, nodeDef: RunnitNodeDef) {
			if (!nodeDef || !nodeDef.costPerResult) return 0;
			return Math.floor(this.getClubOfferTokens(clubOffer) / nodeDef.costPerResult);
		},
	},
	components: {
		TokensSVG,
	},
});
