import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"offering-card",class:{
			'offering-card-disabled-hover': hover && _vm.disabled,
			'offering-card-hover': hover && !_vm.displayOnly && !_vm.disabled,
			'vertical-orientation': _vm.vertical && !_vm.imageOnly,
			'horizontal-orientation': _vm.horizontal && !_vm.imageOnly,
			'full-image': _vm.fullImage && !_vm.imageOnly,
			'image-only': _vm.imageOnly,
		},style:({
			..._vm.offeringCardStyles,
			...(_vm.$vuetify.breakpoint.xsOnly && {
				width: '100%',
			}),
			...(_vm.$vuetify.breakpoint.smAndUp && {
				width: `${_vm.computedContainerWidth}px`,
			}),
			height: `${_vm.computedContainerHeight}px`,
		}),on:{"click":function($event){_vm.displayOnly || _vm.disabled ? () => { } : _vm.$emit('click')}}},[_c('div',{staticClass:"offering-top-section",style:({
				...(_vm.imageOnly && {
					padding: '0',
					width: '100%',
					height: '100%',
				}),
				...(_vm.vertical && !_vm.imageOnly && {
					alignItems: 'flex-start',
					padding: '20px 5px 0 5px',
					...(_vm.small && {
						padding: '5px 0 0 0',
					}),
				}),
				...(_vm.horizontal && !_vm.imageOnly && {
					alignItems: 'flex-start',
					padding: '15px 0 15px 25px',
					...(_vm.small && {
						padding: '15px 0 5px 15px',
					}),
				}),
			})},[_c('div',{staticClass:"offering-image-container",class:{ 'image-only-container': _vm.imageOnly }},[_c('div',{staticClass:"offering-image-img-wrapper",style:({
						width: `${_vm.computedImageHeightWidth}px`,
						height: `${_vm.computedImageHeightWidth}px`,
					})},[(!(_vm.avatar || _vm.imageUrl))?_c('div',{staticClass:"darken-on-hover empty-image full-height full-width"},[_vm._v(" No Image ")]):(_vm.avatar)?[(typeof _vm.avatar !== 'string')?_c('runnit-image',{staticClass:"darken-on-hover",attrs:{"avatar":_vm.avatar,"is-avatar":"","no-click":_vm.displayOnly || _vm.disabled || _vm.noClick,"width":_vm.computedImageHeightWidth,"height":_vm.computedImageHeightWidth}}):_c('div',{staticStyle:{"width":"120px","height":"120px","display":"flex","justify-content":"center","align-items":"center","background-color":"var(--grey-950)"}},[_c(VIcon,{staticStyle:{"font-size":"64px"}},[_vm._v("mdi-image-outline")])],1)]:_c('img',{staticClass:"darken-on-hover offering-image",style:({
							width: `${_vm.computedImageHeightWidth}px`,
							height: `${_vm.computedImageHeightWidth}px`,
						}),attrs:{"src":_vm.imageUrl,"alt":"Offering Image"}}),(!_vm.displayOnly && !_vm.imageOnly)?_c('div',{staticClass:"offering-image-hover-blur",style:({
							width: `${_vm.computedImageHeightWidth}px`,
							height: `${_vm.computedImageHeightWidth}px`,
						})},[_c('div',{staticClass:"offering-image-hover-blur-inner",style:({
								opacity: hover ? 1 : 0,
							})},[(_vm.disabled)?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c(VIcon,{staticStyle:{"color":"var(--red-400)"},style:({
										...(_vm.disabledCtaText ? {
											fontSize: _vm.small ? '40px' : '55px',
										} : {
											fontSize: _vm.small ? '50px' : '75px',
										}),
									})},[_vm._v("mdi-lock-outline")]),(_vm.disabledText)?_c('b',{staticStyle:{"color":"var(--red-400)"}},[_vm._v(_vm._s(_vm.disabledText))]):_vm._e(),(_vm.disabledCtaText)?_c('base-button',_vm._b({staticClass:"mt-4",on:{"click":function($event){return _vm.$emit('disabled-cta-click')}}},'base-button',{
										..._vm.disabledCtaProps,
									},false),[_vm._v(" "+_vm._s(_vm.disabledCtaText)+" ")]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c(VIcon,{staticClass:"gradient-text-90",style:({
										fontSize: _vm.small ? '50px' : '75px',
									})},[_vm._v(_vm._s(_vm.selectIcon))]),(_vm.selectText)?_c('b',{staticClass:"gradient-text-90"},[_vm._v(_vm._s(_vm.selectText))]):_vm._e()],1)])]):_vm._e()],2)])]),(_vm.fullImage && !_vm.imageOnly)?_c('div',[(hover || _vm.persistentFooter)?_c('div',{staticClass:"offering-hover-content"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"offering-title offering-title-hover",class:{
							...(!(hover && _vm.disabled) && {
								'offering-title-team': _vm.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.TEAM && !_vm.isFeatured,
								'gradient-text': _vm.isFeatured,
							}),
						}},[_c(VTooltip,{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({},tooltipOn),[_c('div',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.title,{ length: 23 }))+" ")])])]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.title))])]),(_vm.isFeatured)?_c(VIcon,{staticClass:"gradient-text-90"},[_vm._v(" mdi-star-four-points-circle ")]):_vm._e()],1),(_vm.description)?_c('div',{staticClass:"offering-description",style:({
							..._vm.descriptionStyles,
							...(!_vm.small && {
								fontSize: '14px',
							}),
							...(_vm.small && {
								fontSize: '12px',
							}),
						})},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.description,{ length: _vm.computedDescriptionCharLimit }))+" ")]):_vm._e(),(_vm.tags.length)?_c('div',{staticClass:"offering-tags"},_vm._l((_vm.tags),function(tag){return _c(VChip,{key:tag,attrs:{"x-small":"","color":"var(--grey-600)"}},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()])]):_vm._e()]):(!_vm.imageOnly)?_c('div',{staticClass:"darken-on-hover offering-bottom-section",style:({
				...(_vm.vertical && {
					padding: '35px 25px 0 25px',
					...(_vm.small && {
						padding: '0 25px',
					}),
				}),
				...(_vm.horizontal && {
					padding: '0 35px',
					...(_vm.small && {
						padding: '10px 20px 0 20px',
					}),
				}),
			})},[_c('div',{staticClass:"offering-title offering-title-hover",class:{
					...(!(hover && _vm.disabled) && {
						'offering-title-team': _vm.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.TEAM && !_vm.isFeatured,
						'gradient-text': _vm.isFeatured,
					}),
				},style:({
					...(_vm.vertical && {
						fontSize: '24px',
						margin: '0 0 15px 0',
						...(_vm.small && {
							fontSize: '16px',
							margin: '15px 0 15px 0',
						}),
					}),
					...(_vm.horizontal && {
						fontSize: '20px',
						margin: '15px 0 15px 0',
						...(_vm.small && {
							fontSize: '15px',
							margin: '5px 0 5px 0',
						}),
					}),
				})},[_c(VTooltip,{attrs:{"top":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({},tooltipOn),[_c('div',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.title,{ length: 25 }))+" ")])])]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.title))])]),(_vm.isFeatured)?_c(VIcon,{staticClass:"gradient-text-90"},[_vm._v(" mdi-star-four-points-circle ")]):_vm._e()],1),(_vm.description)?_c('div',{staticClass:"offering-description",style:({
					..._vm.descriptionStyles,
					...(!_vm.small && {
						fontSize: '14px',
					}),
					...(_vm.small && {
						fontSize: '12px',
					}),
				})},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.description,{ length: _vm.computedDescriptionCharLimit }))+" ")]):_vm._e()]):_vm._e(),_c(VSlideYReverseTransition,[(_vm.showEditBtn && hover)?_c('div',{staticStyle:{"z-index":"12","position":"absolute","top":"10px","right":"10px"}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({attrs:{"fab":"","small":"","white":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit-click')}}},'base-button',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog-outline")])],1)]}}],null,true)},[(_vm.editText)?_c('div',{staticClass:"tooltip-max-width"},[_vm._v(_vm._s(_vm.editText))]):_vm._e()])],1):_vm._e()]),(_vm.loading)?_c('div',{staticStyle:{"position":"absolute","top":"0","width":"100%","height":"100%","background":"rgba(0, 0, 0, .7)","border-radius":"20px","z-index":"100"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","width":"100%"}},[_c('runnit-image-timer',{attrs:{"progress":_vm.loadingProgress,"parent-width":_vm.computedContainerWidth,"parent-height":_vm.computedContainerHeight,"timer-start-at-millis":_vm.loadAt}}),(_vm.loadingMessage)?_c('list-item',{staticClass:"px-4 mt-4",attrs:{"icon":"mdi-information-outline","icon-color":"white","container-styles":{ marginBottom: '0 !important' }}},[_c('div',{staticStyle:{"font-size":"13px","line-height":"20px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.loadingMessage)+" ")])]):_vm._e()],1)]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }