
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'RunDiffusion With Runnit Mark Logo',
		},
		width: {
			type: [Number, String],
			default: 1072,
		},
		height: {
			type: [Number, String],
			default: 170,
		},
		viewBox: {
			type: String,
			default: '0 0 1072 170',
		},
		fill: {
			type: String,
			default: 'white',
		},
	},
});
