"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT = exports.getRunnitNodeRunPrice = exports.getHardwareHourlyPrice = exports.currencyTokens = exports.tokensToHundredths = exports.hundredthsToTokens = exports.commaSeparated = exports.currency = exports.dollarsToCents = exports.centsToDollars = void 0;
const lodash_1 = require("lodash");
const numeral = require('numeral');
const bignumber_js_1 = require("bignumber.js");
const centsToDollars = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return (0, bignumber_js_1.default)(Math.floor(num)).div(100).toNumber(); // Cents always integer
};
exports.centsToDollars = centsToDollars;
const dollarsToCents = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return Math.round((0, bignumber_js_1.default)(num).times(100).toNumber()); // Cents always integer
};
exports.dollarsToCents = dollarsToCents;
const currency = (val, useNegSign = false) => {
    const ZERO_VAL = `$0.00`;
    if (!val)
        return ZERO_VAL;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return ZERO_VAL;
    const localeString = numeral(Math.abs(num)).format('0,0.00');
    if (num < 0 && !useNegSign)
        return `($${localeString})`;
    if (num < 0 && useNegSign)
        return `-$${localeString}`;
    return `$${localeString}`;
};
exports.currency = currency;
const commaSeparated = (val) => {
    if (!val)
        return '0';
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return '0';
    // Convert to string and split at decimal point to count decimal places
    const MAX_DECIMALS = 10;
    const decimalPart = num.toString().split('.')[1];
    const decimalPlaces = Math.min((decimalPart || '').length || 0, MAX_DECIMALS);
    // Create dynamic format based on decimal places
    const format = decimalPlaces > 0 ? `0,0.${'0'.repeat(decimalPlaces)}` : '0,0';
    return numeral(num).format(format);
};
exports.commaSeparated = commaSeparated;
const hundredthsToTokens = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return (0, bignumber_js_1.default)(Math.floor(num)).div(100).toNumber(); // Hundredths always integer
};
exports.hundredthsToTokens = hundredthsToTokens;
const tokensToHundredths = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return Math.round((0, bignumber_js_1.default)(num).times(100).toNumber()); // Hundredths always integer
};
exports.tokensToHundredths = tokensToHundredths;
const currencyTokens = (val) => {
    const ZERO_VAL = '0';
    if (!val)
        return ZERO_VAL;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return ZERO_VAL;
    return numeral(num).format('0,0');
};
exports.currencyTokens = currencyTokens;
const getHardwareHourlyPrice = (hardwareOffer, teamId, club) => {
    if (!hardwareOffer)
        return 0;
    const price = hardwareOffer.price || 0;
    if (teamId &&
        hardwareOffer.teamDiscount &&
        hardwareOffer.teamDiscount.percent > 0 &&
        hardwareOffer.teamDiscount.percent <= 100) {
        const priceRatio = (100 - hardwareOffer.teamDiscount.percent) / 100;
        return Math.max(Math.round(price * priceRatio), 0);
    }
    if (club &&
        hardwareOffer.clubDiscounts &&
        hardwareOffer.clubDiscounts[club] &&
        hardwareOffer.clubDiscounts[club].percent > 0 &&
        hardwareOffer.clubDiscounts[club].percent <= 100) {
        const priceRatio = (100 - hardwareOffer.clubDiscounts[club].percent) / 100;
        return Math.max(Math.round(price * priceRatio), 0);
    }
    return price;
};
exports.getHardwareHourlyPrice = getHardwareHourlyPrice;
const getRunnitNodeRunPrice = (costPerResult, numResults, costPerResultLogics, fieldValues) => {
    let finalCostPerResult = costPerResult || 0;
    if (costPerResultLogics && fieldValues) {
        for (const logic of costPerResultLogics) {
            for (const [fieldDefUuid, rule] of Object.entries(logic.field)) {
                const fieldValue = fieldValues[fieldDefUuid];
                if ((0, lodash_1.isArray)(rule.onValue) ? rule.onValue.includes(fieldValue) : rule.onValue) {
                    switch (rule.operator) {
                        case 'ADD':
                            finalCostPerResult += rule.operand;
                            break;
                        case 'MULTIPLY':
                            finalCostPerResult *= rule.operand;
                            break;
                        case 'SET':
                            finalCostPerResult = rule.operand;
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }
    return (0, bignumber_js_1.default)(finalCostPerResult).times(numResults || 1).integerValue(bignumber_js_1.default.ROUND_FLOOR).toNumber();
};
exports.getRunnitNodeRunPrice = getRunnitNodeRunPrice;
exports.LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT = 500000; // The amount of tokens you can buy if you're low, but don't want to upgrade yet
