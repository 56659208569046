
import Vue from 'vue';
import { RunnitNodeDef, RUNNIT_TYPE, RunnitNodeRun } from '@run-diffusion/shared';
import { RunnitRemixMixin } from '@/mixins/RunnitRemixMixin';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import _get from 'lodash/get';
import { mapState } from 'vuex';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
  name: 'ToolInfoActionBar',
  components: {
    RunnitImage,
  },
  mixins: [RunnitRemixMixin],
  props: {
    nodeDef: {
      type: Object as () => RunnitNodeDef,
      required: true
    },
    nodeRunId: {
      type: String,
      required: true
    },
    nodeRun: {
      type: Object as () => RunnitNodeRun,
      default: null
    },
    resultUuid: {
      type: String,
      default: null
    },
  },
  methods: {
    _get,
    handleToolClick () {
      if (this.user) {
        this.routeToTool(this.nodeDef);
      }
    },
    handleRemixClick () {
      if (!this.isRemixable) return;

      if (this.user) {
        this.openToolWithSameInputs(
          this.nodeDef,
          this.nodeRun,
          this.nodeRunId,
          this.nodeRun?.runnitId || null,
          this.nodeRun?.nodeId || null
        );
      } else if (this.resultUuid) {
        const currentPath: string = this.$router.resolve({
          name: ROUTER.RUNNIT_REMIX,
          query: {
            rnrid: this.nodeRun.id,
            ruuid: this.resultUuid,
            autoRemix: true,
          },
        }).href;
        this.$router.push({
          name: ROUTER.LOGIN,
          query: { redirect: currentPath }
        });
      }
    },
    routeToTool (runnitNodeDef: RunnitNodeDef) {
      this.onAddRunnitClick(runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL);
      this.$emit('close-carousel', true);
    }
  },
  computed: {
    ...mapState(['user']),
    isRemixable () {
      return this.canRemix(this.nodeRun);
    }
  },
});
