import { render, staticRenderFns } from "./Runnits.vue?vue&type=template&id=421a8f50&scoped=true"
import script from "./Runnits.vue?vue&type=script&lang=ts"
export * from "./Runnits.vue?vue&type=script&lang=ts"
import style0 from "./Runnits.vue?vue&type=style&index=0&id=421a8f50&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421a8f50",
  null
  
)

export default component.exports