<template>
  <runnit-dialog
    :value="open"
    @input="setOpen"
    max-width="800"
    title="What is a Runnit Board?"
    icon="mdi-help-circle-outline"
    body-padding="0"
    body-height="auto"
  >
    <v-card-text
        :style="{
            padding: $vuetify.breakpoint.xsOnly ? '25px' : '50px'
        }"
    >
      <p class="text-subtitle-1 grey--text text--lighten-1 pb-5">
        Let's get started with Runnit Boards, our AI-powered creative platform exclusively on Runnit.ai. 
        Whether you're a business professional, a hobbyist, or a seasoned creative, you'll find resources 
        and inspiration to help you unlock the full potential of AI for your image creation needs from 
        individual generative tools to entire workflows a Runnit Board is your solution.
      </p>

      <h3 class="text-h5 font-weight-medium mt-6 mb-4">Why Use Runnit Boards?</h3>
      
      <div class="d-flex flex-column">
        <list-item
          icon="mdi-flask"
          icon-color="white"
          class="mb-4"
        >
          <div class="list-title">Experiment and iterate</div>
          <div class="list-subtitle">
            Easily add multiple instances of the same tool with different settings to A/B test prompts 
            and parameters. This helps you quickly discover what works best.
          </div>
        </list-item>

        <list-item
          icon="mdi-folder"
          icon-color="white"
          class="mb-4"
        >
          <div class="list-title">Stay organized</div>
          <div class="list-subtitle">
            Create separate Boards for different projects or tasks. This keeps your workspace tidy and focused.
          </div>
        </list-item>

        <list-item
          icon="mdi-source-branch"
          icon-color="white"
        >
          <div class="list-title">Streamline your workflow</div>
          <div class="list-subtitle">
            Arrange tools in a top-to-bottom flow to create a seamless creative process. For example, 
            start with an idea, generate an image, inpaint unwanted details, upscale for higher resolution, 
            and finally, turn it into a video.
          </div>
        </list-item>
      </div>
    </v-card-text>

    <template #actions>
      <div class="d-flex justify-center pb-10 px-10" :style="{
        'flex-direction': $vuetify.breakpoint.xsOnly ? 'column' : 'row',
        'align-items': $vuetify.breakpoint.xsOnly ? 'center' : 'flex-start'
      }">
        <base-button
          href="https://learn.rundiffusion.com/runnit-board/"
          target="_blank"
          rel="noopener noreferrer"
          super-primary
          style="padding: 10px 30px;"
        >
          <template v-if="$vuetify.breakpoint.xsOnly">Learn More</template>
          <template v-else>Learn More about Runnit Boards</template>
        </base-button>
      </div>
    </template>
  </runnit-dialog>
</template>

<script>
export default {
  name: 'RunnitBoardOnboardingDialog',
  components: {
    ListItem: () => import('@/components/base/ListItem.vue'),
    RunnitDialog: () => import('../base/RunnitDialog.vue')
  },
  props: {
    value: { type: Boolean, default: false }
  },
  data () {
    return {
      open: false
    };
  },
  created () {
    this.open = !!this.value;
  },
  watch: {
    value(newVal) {
      this.open = !!newVal;
    }
  },
  methods: {
    setOpen(val) {
      this.open = !!val;
      if (this.open !== this.value) {
        this.$emit('input', this.open);
      }
    }
  }
}
</script>

<style scoped>
  .gradient-btn {
      background: linear-gradient(to right, var(--v-success-base), var(--v-success-darken2)) !important;
      transition: all 0.3s ease;
  }
  
  .gradient-btn:hover {
      transform: translateY(-2px) scale(1.05);
      box-shadow: 0 0 20px rgba(132,204,22,0.5);
  }

  .list-title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: white;
  }

  .list-subtitle {
    color: #BDBDBD;
    font-size: 0.875rem;
    line-height: 1.4;
  }
</style>