
import Vue from 'vue';
import _truncate from 'lodash/truncate';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import { SETTING_ACTIONS } from '@/views/Runnits/constants';
import { RunnitNodeField, RunnitMask, RUNNIT_NODE_FIELD_TYPE, RUNNIT_NODE_STATIC_FIELDS_KEY, RunnitNodeRunResult } from '@run-diffusion/shared';
import ImageInfoFields from '@/components/ImageGallery/ImageInfoFields.vue';
import { mapActions, mapState } from 'vuex';
import { db } from '@/firebase';
/* global firestore */
import firebase from 'firebase/app';
import FieldValue = firebase.firestore.FieldValue;
import { SNACKBAR_STATUS } from '@/constants/constants';

export default Vue.extend({
	name: 'ImageInfo',
	props: {
		nodeRun: { type: Object, required: true },
		insideDrawer: { type: Boolean, default: false },
		insideNode: { type: Boolean, default: false },
		insideCarousel: { type: Boolean, default: false },
		ignoreTruncation: { type: Boolean, default: false },
		index: { type: [String, Number], default: null },
		imageAttributionStyles: { type: Object, default: null },
		canBeFeatured: { type: Boolean, default: false },
	},
	data () {
		return {
			SETTING_ACTIONS,
			RUNNIT_NODE_FIELD_TYPE,

			showMask: true as boolean,
			settingFeaturedState: false as boolean,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
		numImgs () {
			return this._get(this.nodeRun, 'results.length') || this._get(this.nodeRun, `staticInputs[${RUNNIT_NODE_STATIC_FIELDS_KEY.numResults}]`) || 1;
		},
		isFirstIndex () {
			return +this.index === 0;
		},
		fieldValueTruncateCharCount () {
			let charCount: number = 400;
			if (this.$vuetify.breakpoint.xsOnly || this.insideDrawer) {
				charCount = 100;
			}
			if (this.ignoreTruncation) {
				charCount = 3000;
			}
			return charCount;
		},
		firstLetterOfEmail () {
			return this.nodeRun.email ? this.nodeRun.email[0].toUpperCase() : '';
		},
		hasFeaturedAccess () {
			const hasFeatureFlag = !!(this.user &&
				this.user.features &&
				this.user.features.featureRunnitResult &&
				this.nodeRun.userId === this.user.id
			);
			const hasAdminAccess = !!(this.user && this.user.isAdmin);
			return hasAdminAccess || hasFeatureFlag;
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar'
		]),
		_truncate,
		_isNil,
		determineWidth () {
			let width = '100%';
			if (
				this.$vuetify.breakpoint.smAndUp &&
				!this.insideDrawer
			) {
				width = '50%';
			}
			return width;
		},
		getInpaintingMask (field: RunnitNodeField): RunnitMask {
			return (
				field &&
				field.type === RUNNIT_NODE_FIELD_TYPE.IMG &&
				this.nodeRun.inputs[field.fieldDefUuid] &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting.mask
			) || null;
		},
		async onFeaturedStarClick () {
			if (!this.isSharable) return;

			try {
				this.settingFeaturedState = true;
				await db.runTransaction(async (transaction) => {
					if (this._get(this.nodeRun, `results[${+this.index}].featured`)) { // is currently featured
						const allResultsNotFeatured = !this.nodeRun.results.find((result: RunnitNodeRunResult, index: number) => index !== +this.index && result.featured);
						const updatedResults = this.nodeRun.results.map((result: RunnitNodeRunResult, index: number) => {
							if (index === +this.index) {
								return _omit(result, ['featured']);
							}
							return result;
						})
						transaction.update(
							db.doc(`runnitNodeRuns/${this.nodeRun.id}`),
							{
								...(allResultsNotFeatured && { featured: FieldValue.delete() }),
								results: updatedResults
							},
						);
					} else { // not currently featured
						const updatedResults = this.nodeRun.results.map((result: RunnitNodeRunResult, index: number) => {
							if (index === +this.index) {
								return { ...result, featured: true };
							}
							return result;
						});
						transaction.update(
							db.doc(`runnitNodeRuns/${this.nodeRun.id}`),
							{
								featured: true,
								results: updatedResults
							}
						);
					}
				});

				// Update local nodeRun state after successful transaction
				if (this._get(this.nodeRun, `results[${+this.index}].featured`)) { // was featured
					const allResultsNotFeatured = !this.nodeRun.results.find((result: RunnitNodeRunResult, index: number) => index !== +this.index && result.featured);
					const updatedResults = this.nodeRun.results.map((result: RunnitNodeRunResult, index: number) => {
						if (index === +this.index) {
							return _omit(result, ['featured']);
						}
						return result;
					});

					if (allResultsNotFeatured) {
						delete this.nodeRun.featured;
					}
					this.nodeRun.results = updatedResults;
				} else { // was not featured
					const updatedResults = this.nodeRun.results.map((result: RunnitNodeRunResult, index: number) => {
						if (index === +this.index) {
							return { ...result, featured: true };
						}
						return result;
					});

					this.nodeRun.featured = true;
					this.nodeRun.results = updatedResults;
				}

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Successfully updated featured status!`,
					show: true,
				});
			} catch (error) {
				console.error('Error changing featured state', error);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Failed to update featured status`,
					show: true,
				});
			} finally {
				this.settingFeaturedState = false;
			}
		},
	},
	components: {
		ImageInfoFields,
	},
});
