
import Vue from 'vue';
import { mapState } from 'vuex';
import { BOOTING_SESSION_STATES, READY_SESSION_STATES, STOPPING_SESSION_STATES } from '@/constants/constants';
import { SESSION_STATE } from '@run-diffusion/shared';
import EditCurrSessionDialog from '@/views/Sessions/CurrSessions/EditCurrSessionDialog.vue';
import ListItem from '@/components/base/ListItem.vue';
import CircleIcon from '@/components/base/CircleIcon.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import MultipleActiveSessionTimerWrapper from '@/components/MultipleActiveSessionTimerWrapper.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'MultipleSessions',
	data () {
		return {
			ROUTER,
			SESSION_COUNT_LIMIT: 70,
			BOOTING_SESSION_STATES,
			READY_SESSION_STATES,
			STOPPING_SESSION_STATES,
			SESSION_STATE,
		};
	},
	computed: {
		...mapState([
			'appOffersMap',
			'hardwareOffersMap',
			'currSessions',
		]),
		sessionsLimitReached () {
			return this.currSessions.length >= this.SESSION_COUNT_LIMIT;
		},
	},
	components: {
		MultipleActiveSessionTimerWrapper,
		InfoMessage,
		EditCurrSessionDialog,
		ListItem,
		CircleIcon,
	},
});
