export const resizeImage = async (file: File, maxSizeBytes: number): Promise<File> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        
        img.onload = () => {
            URL.revokeObjectURL(img.src);
            
            // Calculate scale factor to get under max size
            // Assuming a roughly linear relationship between area and file size
            const scaleFactor = Math.sqrt(maxSizeBytes / file.size);
            
            const canvas = document.createElement('canvas');
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            
            // Convert to blob with reasonable quality
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error('Failed to create blob'));
                        return;
                    }
                    
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                    
                    resolve(resizedFile);
                },
                file.type,
                0.8  // Fixed quality - good balance between size and quality
            );
        };
        
        img.onerror = () => {
            URL.revokeObjectURL(img.src);
            reject(new Error('Error loading image'));
        };
    });
}; 