
import Vue from 'vue';
import BaseStyledMenu from '@/components/base/BaseStyledMenu.vue';
import { extractPromptStringValue, PromptMagicMode, PROMPT_MAGIC_MODE } from '@run-diffusion/shared';
import PromptGenerator from '@/views/Runnits/RunnitSettings/PromptGenerator/PromptGenerator.vue';
import { mapActions, mapState } from 'vuex';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';

export default Vue.extend({
	name: 'PromptMagicButton',
	props: {
		iconColor: { type: String, default: 'rgba(255, 255, 255, 0.7)' },
		promptValue: { type: [String, Object], default: null },
		promptField: { type: Object, required: true },
	},
	data () {
		return {
			PROMPT_MAGIC_MODE,
			isMenuOpen: false,
			promptMagicMenuItems: [
				{
					id: 1,
					icon: 'mdi-creation-outline',
					label: 'Auto',
					value: PROMPT_MAGIC_MODE.AUTO,
					description: 'Prompt Magic will automatically expand your short prompts to include more detail.',
				},
				{
					id: 2,
					icon: 'mdi-toggle-switch',
					label: 'Turn On',
					value: PROMPT_MAGIC_MODE.ON,
					description: 'Regardless of length, Prompt Magic will always enhance your prompt.',
				},
				{
					id: 3,
					icon: 'mdi-toggle-switch-off-outline',
					label: 'Turn Off',
					value: PROMPT_MAGIC_MODE.OFF,
					description: 'Your prompt will not be modified.',
				},
			],
		}
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		promptStringValue(): string {
			return extractPromptStringValue(this.promptValue);
		},
		promptMagicMode(): PromptMagicMode {
			return (this.promptValue && this.promptValue.magicMode && PROMPT_MAGIC_MODE[this.promptValue.magicMode]) || PROMPT_MAGIC_MODE.AUTO;
		},
		promptMagicModeDisplay(): string {
			return this.promptMagicMode === PROMPT_MAGIC_MODE.AUTO ? 'Auto' :
				this.promptMagicMode === PROMPT_MAGIC_MODE.ON ? 'On' : 'Off';
		},
		currentIcon(): string {
			return this.promptMagicMenuItems.find(item => item.value === this.promptMagicMode)?.icon || 'mdi-creation-outline';
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'incrementFireInitialPromptGenerationTrigger',
		]),
		onSelectPromptMagicMode (magicMode: PromptMagicMode) {
			if (PROMPT_MAGIC_MODE[magicMode]) {
				this.onPromptInput(this.promptStringValue, magicMode);
			}
		},
		openPromptGenerator() {
			this.isMenuOpen = false;
			this.updateRunnitState({
				promptGeneratorOpen: true,
				// Close all other potentially open runnit menus
				imageSelectOpen: false,
				inpaintingEditorOpen: false,
			});
			this.incrementFireInitialPromptGenerationTrigger();
		},
		onPromptInput (value: string, magicMode: PromptMagicMode) {
			this.$emit('on-prompt-input', {
				value,
				magicMode,
			});
		},
		onUseEnhancedPrompt(positive: string, negative: string) {
			if (negative) {
				if (negative !== this.runnitState.generatedNegativePrompt) {
					this.updateRunnitState({ generatedNegativePrompt: negative });
				} else {
					// If it hasn't changed, tell the negative prompt field that it should re-init the mode (default, custom, none)
					this.updateRunnitState({ generatedNegativePrompt: null });
					this.$nextTick(() => {
						this.updateRunnitState({ generatedNegativePrompt: negative });
					});
				}
			}
			if (positive) this.onPromptInput(positive, this.promptMagicMode);
		},
	},
	components: {
		RunnitSettingHelperBtn,
		BaseStyledMenu,
		PromptGenerator,
	},
});
