
import Vue from 'vue';
import OfferingCard from '@/components/OfferingCard.vue';
import { ROUTER } from '@/router/constants';
import { RunnitNodeDef, RunnitNodeDefTag, RUNNIT_NODE_DEF_TAG_TYPE, RUNNIT_TYPE } from '@run-diffusion/shared';
import { mapState } from 'vuex';
import _shuffle from 'lodash/shuffle';
import { RunnitsCRUDMixin } from '@/mixins';

export default Vue.extend({
	name: 'RunnitsPromoCard',
	mixins: [
		RunnitsCRUDMixin,
	],
	props: {
		showButton: { type: Boolean, default: false },
		showAnimation: { type: Boolean, default: false },
	},
	data () {
		return {
			numGridItems: 16,
			featuredTool: null,
		};
	},
	watch: {
		loadingPublicRunnitNodeDefs: {
			immediate: true,
			handler (newVal) {
				if (newVal === false && !this.featuredTool) {
					this.setFeaturedTool();
				}
			}
		}
	},
	computed: {
		...mapState([
			'publicRunnitNodeDefs',
			'loadingPublicRunnitNodeDefs',
			'publicRunnitNodeDefTags',
		]),
	},
	methods: {
		goToRunnitsHome () {
			if (this.featuredTool) {
				this.onAddRunnitClick(this.featuredTool, RUNNIT_TYPE.SINGLE_TOOL);
			}
		},
		setFeaturedTool (): void {
			const featuredTag = this.publicRunnitNodeDefTags.find((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE && tag.label === 'Featured Banner')
			if (!featuredTag) {
				this.featuredTool = null;
				return;
			}

			const featuredTools = (this.publicRunnitNodeDefs || []).filter((tool: RunnitNodeDef) => this._get(tool, `tags[${featuredTag.id}]`));
			this.featuredTool = featuredTools.length > 0 ? featuredTools[0] : null;
		}
	},
	components: {
		OfferingCard,
	},
});
