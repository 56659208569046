
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db, storage } from '@/firebase';
import {
	Avatar,
	RunnitUpload,
	UploadFile,
	AVATAR_USE_CASE,
	IMAGE_UPLOAD_MODE,
	UPLOAD_FILE_TYPE,
} from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { ImageUploadMixin } from '@/mixins/ImageUploadMixin';
import { v4 as uuidv4 } from 'uuid';
import { resizeImage } from '@/utils/imageUtils';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';

export default Vue.extend({
	name: 'ImageInput',
	props: {
		mode: {
			type: String,
			required: true,
			validator: (val: string) => Object.keys(IMAGE_UPLOAD_MODE).includes(val),
		},
		avatarUseCase: {
			type: String,
			default: AVATAR_USE_CASE.RUNNIT,
			validator: (val: string) => Object.keys(AVATAR_USE_CASE).includes(val),
		},
		multiple: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		allowTextFiles: { type: Boolean, default: false },
		dropZone: { type: Boolean, default: true },
	},
	mixins: [
		ImageUploadMixin,
		RunnitsImageSelectMixin,
	],
	data () {
		return {
			FILE_SIZE_LIMIT: 4194304, // 4 MB in bytes
			isUploading: false,
			isBeingDraggedOver: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
			'dragAndDrop',
		]),
		acceptedFileTypes() {
			return this.allowTextFiles ? 'image/*, text/plain' : 'image/*';
		},
		isDraggingActive() {
			return this.dragAndDrop.isDragging && !this.disabled;
		},
		hasActiveDropZones() {
			return this.dragAndDrop.activeDropZones > 0;
		}
	},
	watch: {
		'dragAndDrop.isDragging': {
			immediate: true,
			handler(isDragging) {
				// Only set allowDrop if this component is a drop zone
				if (this.dropZone) {
					this.setAllowDrop(!this.disabled && isDragging);
				}
			}
		},
		'dragAndDrop.activeDropZones': {
			immediate: true,
			handler(activeDropZones) {
				// If we're in a dragging state but there are no active drop zones, restore this one
				// Only do this if this component is a drop zone
				if (this.dropZone && this.dragAndDrop.isDragging && activeDropZones === 0) {
					this.registerAsDropZone();
				}
			}
		},
		// Watch for changes to the dropZone prop
		dropZone: {
			immediate: false, // Don't run on component creation (handled in mounted)
			handler(newValue, oldValue) {
				if (newValue && !oldValue) {
					// If changing from not a drop zone to a drop zone, register
					this.registerAsDropZone();
				} else if (!newValue && oldValue) {
					// If changing from a drop zone to not a drop zone, unregister
					this.unregisterAsDropZone();
				}
			}
		}
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
			'setAllowDrop',
			'setIsDragging',
			'setDraggedItem',
			'addDropZone',
			'removeDropZone',
		]),
		clearAllSelectionStates() {
			const activeSelectionStates = this.orderedSelectionStates;

			// Exit selection mode for all active selection states
			activeSelectionStates.forEach(state => {
				if (state.triggeringId) {
					this.exitSelectionMode(state.triggeringId);
				}
			});
		},
		onUploadComplete (val: RunnitUpload[]) {
			const filesToEmit = this.multiple ? val : val[0];
			this.$emit('on-upload-complete', filesToEmit);
		},
		handleDragOver (ev) {
			if (this.disabled || !this.dropZone) return;

			// Check if it's a RunnitImage being dragged
			if (this.dragAndDrop.isDragging && this.dragAndDrop.draggedItem && this.dragAndDrop.draggedItem.type === 'RUNNIT_IMAGE') {
				this.isBeingDraggedOver = true;
				ev.dataTransfer.dropEffect = 'copy';
			} else if (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) {
				// Check if it's a file being dragged
				let hasValidFile = false;
				for (let i = 0; i < ev.dataTransfer.items.length; i++) {
					const item = ev.dataTransfer.items[i];
					if (item.kind === 'file' && (item.type.startsWith('image/') || (this.allowTextFiles && item.type === 'text/plain'))) {
						hasValidFile = true;
						break;
					}
				}

				if (hasValidFile) {
					this.isBeingDraggedOver = true;
					ev.dataTransfer.dropEffect = 'copy';
				}
			}

			ev.preventDefault();
		},
		handleDragLeave (ev) {
			if (this.isUploading || this.disabled || !this.dropZone) return;
			this.isBeingDraggedOver = false;
			ev.preventDefault();
		},
		async handleDrop (ev) {
			if (this.disabled || !this.dropZone) return;
			this.isBeingDraggedOver = false;

			// Check if it's a RunnitImage being dropped
			if (this.dragAndDrop.isDragging && this.dragAndDrop.draggedItem && this.dragAndDrop.draggedItem.type === 'RUNNIT_IMAGE') {
				// If we're in avatar mode, don't allow dropping already uploaded files
				if (this.mode === 'AVATAR') {
					console.log('Dropping images not supported in avatar mode');
					this.updateSnackbar({
						status: SNACKBAR_STATUS.WARN,
						message: `Dropping images is not supported in avatar mode`,
						show: true,
					});

					// Reset drag state
					this.setIsDragging(false);
					this.setDraggedItem(null);
					return;
				}

				const draggedItem = this.dragAndDrop.draggedItem;

				try {
					this.$emit('on-upload-start');
					this.isUploading = true;

					if (draggedItem.nodeRunResult) {
						this.onUploadComplete([{ nodeRunResult: draggedItem.nodeRunResult }]);
					} else if (draggedItem.upload) {
						this.onUploadComplete([{ upload: draggedItem.upload }]);
					} else if (draggedItem.src) {
						// Handle case where we only have the image source URL
						try {
							const response = await fetch(draggedItem.src);
							const blob = await response.blob();
							const file = new File([blob], 'dragged-image.png', { type: 'image/png' });
							const upload = await this.uploadFile(file);
							this.onUploadComplete([{ upload }]);
						} catch (e) {
							console.error('Error processing image from src:', e);
							throw e;
						}
					} else {
						console.error('Dropped item has no recognizable content:', draggedItem);
					}

					// Explicitly reset the drag state after processing
					this.setIsDragging(false);
					this.setDraggedItem(null);

					// Clear all active selection states
					this.clearAllSelectionStates();
				} catch (e) {
					console.error('Error processing dragged image:', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: `Error processing dragged image`,
						show: true,
					});

					// Reset drag state even if there's an error
					this.setIsDragging(false);
					this.setDraggedItem(null);
				} finally {
					this.isUploading = false;
					this.$emit('on-upload-end');
				}

				// Ensure drag state is reset
				this.setIsDragging(false);
				this.setDraggedItem(null);

				// Clear all active selection states
				this.clearAllSelectionStates();
				return;
			}

			// Handle regular file drop
			let dt = ev.dataTransfer;
			let files = dt.files;
			await this.handleFiles(files);
		},
		async onHiddenFileInputChange (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = false;
			await this.handleFiles(ev.target.files);

			// Clear the selected file from the input
			this.$refs.fileInput.value = ''; // Reset the value of the input element
		},
		openFileDialog () {
			if (this.isUploading || this.disabled) return;
			this.$refs.fileInput.click();
		},
		async handleFiles(fileList: FileList) {
			this.isBeingDraggedOver = false;
			const files = Array.from(fileList);
			if (!files || !files.length) return;

			const processedFiles = await Promise.all(
				files.map(async file => {
					if (file.type.startsWith('image/') && file.size >= this.FILE_SIZE_LIMIT) {
						return resizeImage(file, this.FILE_SIZE_LIMIT * 0.9); // Target 90% of limit to be safe
					}
					return file;
				})
			);

			try {
				this.$emit('on-upload-start');
				this.isUploading = true;
				const uploads = await Promise.all(processedFiles.map(file => this.uploadFile(file)));

				this.onUploadComplete(uploads);

				// Clear all active selection states
				this.clearAllSelectionStates();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error uploading image`,
					show: true,
				});

				// Clear all active selection states
				this.clearAllSelectionStates();
			} finally {
				this.isUploading = false;
				this.$emit('on-upload-end');
			}
		},
		async uploadFile (file) {
			if (this.mode === 'RUNNIT_UPLOAD') {
				return this.handleRunnitUpload(file);
			} else if (this.mode === 'AVATAR') {
				return this.handleAvatar(file);
			}
		},
		async handleRunnitUpload (file) {

			let upload: RunnitUpload = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const uploadsRef = db.collection('runnitUploads')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await uploadsRef.get()).forEach(async (doc: any) => {
				upload = {
					...doc.data(),
					get id () { return doc.id },
				} as RunnitUpload;
			});

			if (!upload) {
				// Upload file
				const { name, contentType, size, width, height } = await this.getImageMetaData(file);
				const nameSplit: string[] = name.split('.');
				const uniqName: string = `${uuidv4()}.${nameSplit[nameSplit.length - 1]}`;
				const fileRef = storage.ref(`runnitUploads/users/${this.user.id}/uploads/${uniqName}`);
				await fileRef.put(file);

				// Record upload file in database
				const nowDate: Date = new Date();
				const addedUploadRef = await db.collection('runnitUploads')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: uniqName,
						contentType,
						size,
						width,
						height,
						hashHex,
					});
				upload = {
					...(await addedUploadRef.get()).data(),
					get id () { return addedUploadRef.id },
				} as RunnitUpload;
			}

			return upload;
		},
		async handleAvatar (file) {
			// NOTE: the firebase size extension will be making this 256x256 and then deleting the originals
			let avatar: Avatar = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const avatarsRef = db.collection('avatars')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await avatarsRef.get()).forEach(async (doc: any) => {
				avatar = {
					...doc.data(),
					get id () { return doc.id },
				} as Avatar;
			});

			if (!avatar) {
				// avatar file
				const { name, contentType, size, width, height } = await this.getImageMetaData(file);
				const nameSplit: string[] = name.split('.');
				const uniqName: string = `${uuidv4()}.${nameSplit[nameSplit.length - 1]}`;
				const fileRef = storage.ref(`avatars/users/${this.user.id}/uploads/${uniqName}`);
				await fileRef.put(file);

				// Record avatar file in database
				const nowDate: Date = new Date();
				const addedAvatarRef = await db.collection('avatars')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: uniqName,
						contentType,
						size,
						width,
						height,
						hashHex,
						useCase: this.avatarUseCase || AVATAR_USE_CASE.RUNNIT,
					});
				avatar = {
					...(await addedAvatarRef.get()).data(),
					get id () { return addedAvatarRef.id },
				} as Avatar;
			}

			return avatar;
		},
		handleDocumentDrop(ev) {
			// Only handle the document drop if we're in a dragging state, this component is still active,
			// and this component is a drop zone
			if (this.dropZone && this.dragAndDrop.isDragging && this.hasActiveDropZones) {

				// Don't process drops in avatar mode
				if (this.mode === 'AVATAR' && this.dragAndDrop.draggedItem && this.dragAndDrop.draggedItem.type === 'RUNNIT_IMAGE') {

					// Reset drag state
					this.setIsDragging(false);
					this.setDraggedItem(null);
					return;
				}

				// Prevent default browser behavior
				ev.preventDefault();

				// Process the drop using our component's handler
				this.handleDrop(ev);
			}
		},
		handleGlobalDragEnd() {
			// This ensures the drag state is reset even if the component unmounts during drag
			// Only affect the global state if this component is a drop zone
			if (this.dropZone) {
				this.cleanupDragState();
			}
		},
		cleanupDragState() {
			// Only cleanup if this component is a drop zone
			if (this.dropZone) {
				// Reset the drag state
				this.setIsDragging(false);
				this.setDraggedItem(null);

				// Clear all active selection states
				this.clearAllSelectionStates();
			}
		},
		// Method to register this component as a drop zone
		registerAsDropZone() {
			if (this.dropZone) {
				this.addDropZone();
			}
		},
		// Method to unregister this component as a drop zone
		unregisterAsDropZone() {
			if (this.dropZone) {
				this.removeDropZone();
			}
		},
	},
	components: {
	},
	created() {
		// If we're in the middle of a drag operation and this is a drop zone, register immediately
		if (this.dropZone && this.dragAndDrop && this.dragAndDrop.isDragging) {
			this.registerAsDropZone();
		}
	},
	mounted () {
		// Only register as a drop zone if the dropZone prop is true
		if (this.dropZone) {
			this.registerAsDropZone();
		}

		// Add event listener for dragend to ensure we clean up if the component unmounts during drag
		window.addEventListener('dragend', this.handleGlobalDragEnd);

		// Add document-level drop handler as a fallback
		document.addEventListener('drop', this.handleDocumentDrop);

		// Check if we're in the middle of a drag operation and restore state if needed
		if (this.dropZone && this.dragAndDrop.isDragging && !this.hasActiveDropZones) {
			this.registerAsDropZone();
		}
	},
	beforeDestroy () {
		// Only unregister as a drop zone if the dropZone prop is true
		if (this.dropZone) {
			// Only unregister as a drop zone if we're not in the middle of a drag operation
			if (!this.dragAndDrop.isDragging) {
				this.unregisterAsDropZone();
			}
		}

		// Remove the global event listeners
		window.removeEventListener('dragend', this.handleGlobalDragEnd);
		document.removeEventListener('drop', this.handleDocumentDrop);
	},
});
