
import Vue from 'vue';
import { WhatsNewUpdate, WhatsNewFeature } from './types';
import { v4 as uuidv4 } from 'uuid';
import BaseButton from '@/components/base/BaseButton.vue';
import { ROUTER } from '@/router/constants';
import moment from 'moment-timezone';
import { mapActions } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import DatePicker from '@/components/base/DatePicker.vue';
import { get$bindFirestoreOptions } from '@/mixins';
import { db } from '@/firebase';
// Define a type for the update form data
interface UpdateFormData extends Omit<WhatsNewUpdate, 'id'> {
	id?: string;
	features: WhatsNewFeature[];
}

export default Vue.extend({
	name: 'ManageUpdatesPage',
	components: {
		BaseButton,
		DatePicker
	},
	data() {
		return {
			updates: [] as WhatsNewUpdate[],
			loading: true,
			error: null as Error | null,
			dialog: false,
			editingUpdate: null as UpdateFormData | null,
			defaultFeature: {
				title: '',
				description: '',
				icon: ''
			} as WhatsNewFeature,
			saving: false, // Flag for save operation in progress
			deleting: false, // Flag for delete operation in progress
		};
	},
	computed: {
		// sortedUpdates property is removed as we're now sorting at the database level
	},
	async mounted() {
		await this.bindUpdates();
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		
		async bindUpdates() {
			this.loading = true;
			this.error = null;
			try {
				// index created: whatsNewUpdates	isPublished Ascending date Descending __name__ Descending
				const updatesRef = db
					.collection('whatsNewUpdates')
					// Sort by date in descending order (newest first)
					.orderBy('date', 'desc');

				await this.$bind(
					'updates',
					updatesRef, 
					get$bindFirestoreOptions(),
				);
			} catch (err: any) {
				console.error('Error binding updates:', err);
				this.error = err;
			} finally {
				this.loading = false;
			}
		},

		openAddDialog() {
			this.editingUpdate = {
				version: '',
				date: moment().format('YYYY-MM-DD'), // Default to today
				sortOrder: 0, // This field will be ignored but kept for backward compatibility
				isPublished: false,
				features: [{ ...this.defaultFeature }],
				title: '',
				subTitle: '',
				callToActionText: ''
			};
			this.dialog = true;
		},

		openEditDialog(update: WhatsNewUpdate) {
			this.editingUpdate = {
				...update,
				// Ensure features is a deep copy
				features: JSON.parse(JSON.stringify(update.features)),
				callToActionText: update.callToActionText || ''
			};
			this.dialog = true;
		},

		closeDialog() {
			this.dialog = false;
			this.editingUpdate = null;
			this.saving = false; // Reset saving flag
		},

		addFeature() {
			if (this.editingUpdate) {
				this.editingUpdate.features.push({ ...this.defaultFeature });
			}
		},

		removeFeature(index: number) {
			if (this.editingUpdate && this.editingUpdate.features.length > 1) {
				this.editingUpdate.features.splice(index, 1);
			}
		},

		async saveUpdate() {
			if (!this.editingUpdate) return;
			this.saving = true; // Set saving flag

			const updateData: Omit<WhatsNewUpdate, 'id'> = {
				version: this.editingUpdate.version,
				date: this.editingUpdate.date,
				sortOrder: 0, // This field is kept for backward compatibility
				isPublished: this.editingUpdate.isPublished,
				features: this.editingUpdate.features.filter(
					(f) => f.title && f.description // Filter out empty features
				),
				title: this.editingUpdate.title || undefined,
				subTitle: this.editingUpdate.subTitle || undefined,
				callToActionText: this.editingUpdate.callToActionText || undefined
			};

			try {
				const id = this.editingUpdate.id || uuidv4();
				const docRef = db.collection('whatsNewUpdates').doc(id);
				await docRef.set(updateData, { merge: true });
				this.closeDialog();
				// No need to rebind - changes will be reflected automatically
			} catch (err: any) {
				console.error('Error saving update:', err);
				this.error = err;
				this.saving = false; // Reset saving flag on error
			}
			// Saving flag is reset in closeDialog on success
		},

		async togglePublish(update: WhatsNewUpdate) {
			const docRef = db.collection('whatsNewUpdates').doc(update.id);
			try {
				await docRef.update({
					isPublished: !update.isPublished
				});
				// No need to rebind - changes will be reflected automatically
			} catch (err: any) {
				console.error('Error toggling publish status:', err);
				this.error = err;
			}
		},

		copyUpdateId() {
			if (this.editingUpdate && this.editingUpdate.id) {
				navigator.clipboard.writeText(this.editingUpdate.id)
					.then(() => {
						// Show global snackbar notification
						this.updateSnackbar({
							status: SNACKBAR_STATUS.SUCCESS,
							message: 'Update ID copied to clipboard!',
							show: true,
						});
					})
					.catch(err => {
						console.error('Error copying to clipboard:', err);
						this.updateSnackbar({
							status: SNACKBAR_STATUS.ERROR,
							message: 'Failed to copy update ID',
							show: true,
						});
					});
			}
		},

		async deleteUpdate(id: string) {
			if (!confirm('Are you sure you want to delete this update?')) return;
			this.deleting = true; // Set deleting flag
			const docRef = db.collection('whatsNewUpdates').doc(id);
			try {
				await docRef.delete();
				// No need to rebind - changes will be reflected automatically
			} catch (err: any) {
				console.error('Error deleting update:', err);
				this.error = err;
			} finally {
				this.deleting = false; // Reset deleting flag
			}
		},

		formatDate(date: string): string {
			if (!date) return '';
			return moment(date).format('MMMM D, YYYY');
		},

		goBack() {
			this.$router.push({ name: ROUTER.RUNNITS_WHATS_NEW });
		},
	}
});
