
import Vue from 'vue';
import { mapState } from 'vuex';
import { Moment } from 'moment-timezone';
import { DbTimestamp } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'SimultaneousLoginsProtector',
	data() {
		return {
			isTakingControl: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'isAdminProxyingUser',
			'deviceReportLocal',
		]),
		shouldShowProtection () {
			const currentVisitorId: string = this._get(this.deviceReportLocal, 'fingerprint.visitorId') || null;
			const lastVisitorId: string = this._get(this.user, 'loginInfo.lastFingerprint.visitorId') || null;
			const loginLastAtMoment: Moment = this._get(this.deviceReportLocal, 'loginLastAtMoment') || null;
			const userLastAt: DbTimestamp = this._get(this.user, 'loginInfo.lastAt') || null;

			return !!(
				this.user &&
				!this.user.isAdmin &&
				!this.isAdminProxyingUser &&
				currentVisitorId &&
				lastVisitorId &&
				currentVisitorId !== lastVisitorId &&
				loginLastAtMoment &&
				userLastAt &&
				loginLastAtMoment.isSameOrBefore(userLastAt.toMillis())
			);
		},
	},
	methods: {
		async onTakeControl () {
			this.isTakingControl = true;
			await new Promise(resolve => setTimeout(resolve, 5000));
			window.location.reload();
		},
		onLogout () {
			this.$emit('on-logout');
		},
	},
});
