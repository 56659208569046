var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"explanation-container",class:{
  'explanation-container--xs': _vm.$vuetify.breakpoint.xsOnly,
  'explanation-container--sm': _vm.$vuetify.breakpoint.sm,
  'explanation-container--md': _vm.$vuetify.breakpoint.md,
  'explanation-container--lg': _vm.$vuetify.breakpoint.lg,
  'explanation-container--xl': _vm.$vuetify.breakpoint.xl
}},[_c('div',[_c('div',{staticClass:"explanation-container-headline"},[_vm._v("Unlock Your Creative Flow with Runnit Boards")]),_c('div',{staticClass:"explanation-container-body"},[_vm._v(" AI-powered creative workflows, all in one place "),_c('div',{staticClass:"mt-5 full-width d-flex justify-start"},[_c('base-button',{attrs:{"secondary-primary":""},on:{"click":function($event){return _vm.$emit('learn-more')}}},[_vm._v(" Learn More ")])],1)])]),_c('runnit-page-graphic-s-v-g',{staticClass:"explanation-container-image",style:({
      transform: _vm.$vuetify.breakpoint.smAndDown ? 'rotateX(53deg) rotateY(356deg) rotateZ(33deg) scale(1.6) translateY(180px) translateX(80px)' : 'rotateX(53deg) rotateY(356deg) rotateZ(27deg) scale(1.76) translateY(30px) translateX(-20px)',
    }),attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? 170 : 120,"bevel":10}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }