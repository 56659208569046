
import Vue from 'vue';
import RunnitDialog from "@/views/Runnits/base/RunnitDialog.vue";
import { ModelCRUDMixin } from "@/mixins/ModelCRUDMixin";
import OptionCard from "@/views/Runnits/base/OptionCard.vue";
import {
	BASE_NODE_DEF_TO_CREATE_MODEL_FROM,
	BASE_NODE_DEF_TO_USE_MODEL_WITH,
	MODEL_BASE_NODE_DEF_TYPE_CARD,
	MODEL_ARCHITECTURE_NODE_DEF_ID_MAP,
} from "@/views/Runnits/constants";
import {
	MODEL_BASE_NODE_DEF_MAP,
	RUNNIT_NODE_FIELD_TYPE,
	RUNNIT_TYPE,
	RunnitNodeDef
} from "@run-diffusion/shared";
import BackButton from "@/components/base/BackButton.vue";
import { db, functions } from "@/firebase";
import { SNACKBAR_STATUS } from "@/constants/constants";
import { mapActions, mapState } from "vuex";
import { RunnitsCRUDMixin } from "@/mixins";

const TOOL_CREATION_TYPE = {
	EXISTING: 'EXISTING',
	NEW: 'NEW',
}

export default Vue.extend({
	name: 'ModelToolUseDialog',
	mixins: [
		RunnitsCRUDMixin,
		ModelCRUDMixin,
	],
	props: {
		value: { type: Boolean, default: false },
		model: { type: Object, default: null },
	},
	computed: {
		...mapState([
			'user',
		]),
		baseNodeDefs() {
			if (this.selectedToolCreationType === TOOL_CREATION_TYPE.EXISTING) {
				return BASE_NODE_DEF_TO_USE_MODEL_WITH[this.model.architecture]
			} else if (this.selectedToolCreationType === TOOL_CREATION_TYPE.NEW) {
				return BASE_NODE_DEF_TO_CREATE_MODEL_FROM[this.model.architecture]
			} else {
				return [];
			}
		},
		allowToolCreation() {
			return this.user?.isAdmin || this._get(this.user, 'features.createTool') || false;
		},
	},
	data() {
		return {
			BASE_NODE_DEF_TO_USE_MODEL_WITH,
			MODEL_BASE_NODE_DEF_MAP,
			MODEL_ARCHITECTURE_NODE_DEF_ID_MAP,
			MODEL_BASE_NODE_DEF_TYPE_CARD,
			TOOL_CREATION_TYPE,
			open: false,
			selectedToolCreationType: null,
			selectedModelBaseTool: null,
			transitionDirection: 'slide-x',
			mergingModelWithTool: false,
		};
	},
	watch: {
		value(newVal: boolean) {
			this.open = newVal;
		},
		open(newVal: boolean) {
			if (this.open !== this.value) {
				if (!this.open) {
					if (this.allowToolCreation) {
						this.selectedToolCreationType = null;
					}
					this.selectedModelBaseTool = null;
					this.transitionDirection = 'slide-x';
				}
				this.$emit('input', newVal);
			}
		},
		model: {
			immediate: true,
			handler(newVal) {
				if (newVal && !this.allowToolCreation) {
					// If user doesn't have createTool feature, automatically select "Use this model" option
					this.selectedToolCreationType = TOOL_CREATION_TYPE.EXISTING;
				}
			}
		}
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async runAction(modelBaseTool: string) {
			if (this.selectedToolCreationType === TOOL_CREATION_TYPE.EXISTING) {
				this.useModelInExistingTool(MODEL_ARCHITECTURE_NODE_DEF_ID_MAP[modelBaseTool]);
			} else if (this.selectedToolCreationType === TOOL_CREATION_TYPE.NEW) {
				this.createTool(MODEL_ARCHITECTURE_NODE_DEF_ID_MAP[modelBaseTool]);
			}
		},
		async createTool(nodeDefIdMapValue: string) {
			this.mergingModelWithTool = true;
			const functionRef = functions.httpsCallable('createNodeDefFromModel');

			try {
				const response = await functionRef({ modelId: this.model.id, modelBaseNodeDef: nodeDefIdMapValue });
				const { success, newNodeDefId } = response.data;

				if (!success || !newNodeDefId) {
					throw new Error('Error creating tool');
				}

				const runnitNodeDefWithId = {
					id: newNodeDefId,
					title: `${this.model.architecture} tool`,
				};
				await this.onAddRunnitClick(runnitNodeDefWithId, RUNNIT_TYPE.SINGLE_TOOL);
				this.open = false;
			} catch (e) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error creating tool',
					show: true,
				});
				this.mergingModelWithTool = false;
			}
		},
		async useModelInExistingTool(nodeDefId: string) {
			this.mergingModelWithTool = true;
			const runnitNodeDefWithId = {
				id: nodeDefId,
				title: `${this.model.architecture} tool`,
			};

			try {
				const runnitNodeDefSnapshot: any = await db.doc(`runnitNodeDefs/${nodeDefId}`).get();
				const runnitNodeDef: RunnitNodeDef = runnitNodeDefSnapshot.data() as RunnitNodeDef;
				const modelSingleSelectFieldDefUuid = this.getFieldDefUuidByFieldType(runnitNodeDef.fields, RUNNIT_NODE_FIELD_TYPE.MODEL_SINGLE_SELECT);
				if (modelSingleSelectFieldDefUuid) {
					const defaultModelSingleSelectInputValue = {
						[modelSingleSelectFieldDefUuid]: {
							model: this.model.id,
						}
					}
					const inputValuesQueryParams = {
						inputValues: encodeURIComponent(JSON.stringify(defaultModelSingleSelectInputValue)),
					};
					await this.onAddRunnitClick(runnitNodeDefWithId, RUNNIT_TYPE.SINGLE_TOOL, inputValuesQueryParams);
					this.open = false
				} else {
					throw new Error('Error using tool. If issue persists, contact support.');
				}
			} catch (e) {
				this.mergingModelWithTool = false;
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error using tool. If issue persists, contact support.',
					show: true,
				});
			}
		},
		onToolCreationTypeSelected(type: string) {
			this.transitionDirection = type ? 'slide-x' : 'slide-x-reverse';
			this.selectedToolCreationType = type;
		},
	},
	components: {
		BackButton,
		OptionCard,
		RunnitDialog,
	},
});
