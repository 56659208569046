<template v-once>
	<div class="px-4 pb-12 d-flex flex-column align-center justify-center" style="max-height: 600px">
		<h1 class="mt-12 text-center error--text" style="font-weight: 900;">404</h1>
		<h4 class="text-center error--text">
			This page was not found, please check the URL
		</h4>
		<base-button
			@click="routerReplace($route, $router, { name: ROUTER.SESSIONS })"
			class="my-3"
			call-to-action
			large
		>
			Go to Home Page
		</base-button>
	</div>
</template>

<script>
import Vue from 'vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'Page404',
	data () {
		return {
			ROUTER,
		};
	},
});
</script>
