
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import ListItem from '@/components/base/ListItem.vue';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { functions } from '@/firebase';

export default Vue.extend({
	name: 'UnlockRunnitsFreemium',
	data () {
		return {
			isActivatingMyAccount: false,
			isFreemiumInfoIssue: false,
			invalidatedOtherUsers: false,
		};
	},
	computed: {
		...mapState([
			'authUser',
			'user',
		]),
		isValidateAccountPassChecked () {
			return !!(
				this.authUser &&
				this.authUser.emailVerified &&
				!this._get(this.user, 'freemiumInfo.invalidEmail') &&
				!(
					this.isFreemiumInfoIssue &&
					this._get(this.user, 'freemiumInfo.noFingerprint')
				)
			);
		},
		isHasDiscordUserPassChecked () {
			return !!(
				this.isValidateAccountPassChecked &&
				this._get(this.user, 'discordUserId') &&
				!this._get(this.user, 'freemiumInfo.noDiscord')
			);
		},
		isDiscordUserMemberRdPassChecked () {
			return !!(
				this.isHasDiscordUserPassChecked &&
				this._get(this.user, 'discordUserId') &&
				!this._get(this.user, 'freemiumInfo.noDiscordMemberRd')
			);
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'setUpsellDialog',
		]),
		onSubscriptionsPageRoute () {
			this.setUpsellDialog({});
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_SUBSCRIPTIONS });
		},
		async onActiveMyAccount () {
			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error activating your account. If this issue persists, please reach out to report issues by clicking the support button in our top toolbar.',
					show: true,
					timeout: 10000,
				});
			};
			try {
				this.isFreemiumInfoIssue = false;
				this.invalidatedOtherUsers = false;
				this.isActivatingMyAccount = true;
				const functionRef = functions
					.httpsCallable('unlockRunnitsFreemium');
				const {
					success,
					isFreemiumInfoIssue,
					invalidatedOtherUsers,
				} = (await functionRef()).data;
				this.isFreemiumInfoIssue = !!isFreemiumInfoIssue;
				this.invalidatedOtherUsers = !!invalidatedOtherUsers;

				if (!success && isFreemiumInfoIssue) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Activation not successful! You still have tasks to complete.',
						show: true,
						timeout: 10000,
					});
				} else if (!success) {
					const message: string = `unlockRunnitsFreemium returned a status of: ${success}`;
					onError(new Error(message));
				} else {
					this.onSuccess();
				}
			} catch (e) {
				onError(e);
			} finally {
				this.isActivatingMyAccount = false;
			}
		},
		onSuccess () {
			this.updateSnackbar({
				status: SNACKBAR_STATUS.SUCCESS,
				message: 'Success! Activation complete',
				show: true,
			});
			this.$emit('on-success', this.invalidatedOtherUsers);
		},
	},
	components: {
		JoinOurDiscord,
		ListItem,
	},
});
