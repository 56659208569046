
import Vue from 'vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import _slice from 'lodash/slice';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import BackButton from '@/components/base/BackButton.vue';
import { db } from '@/firebase';
import SurveyFieldsDisplay from '@/views/Admin/AdminSurveys/SurveyFieldsDisplay.vue';
import AdminUserStats from '@/components/AdminStats/AdminUserStats/AdminUserStats.vue';
import AdminTeamStats from '@/components/AdminStats/AdminTeamStats.vue';
import _has from 'lodash/has';
import { Survey, SURVEY_KEY } from '@run-diffusion/shared';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'AdminSurveys',
	data () {
		return {
			surveyKeyValue: SURVEY_KEY.NEW_TEAM_1,
			SURVEY_KEY,

			loadingCurrAuthors: false,
			currAuthors: {},

			currPage: 0,
			pageSize: 50,
			currSurveys: [],
			surveys: [],
			lastSurveyDoc: null,
			hasMoreSurveys: false,
			loadingInitialSurveys: false,
			loadingMoreSurveys: false,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
		hasRouteHistory () {
			return window.history.length > 2;
		},
		canGoPrevPage () {
			return this.currPage > 0;
		},
		canGoNextPage () {
			return (
				(this.lastSurveyDoc && this.hasMoreSurveys) ||
				this.currPage < Math.floor(this.surveys.length / this.pageSize)
			);
		},
	},
	watch: {
		surveyKeyValue: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.loadInitialSurveys(newVal);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		_has,
		goBack () {
			if (this.hasRouteHistory) {
				this.$router.back();
			} else {
				const hasCurrentSessionId: boolean = !!(this._get(this.toolbar, 'session.id'));

				if (hasCurrentSessionId) {
					this.routerPush(this.$route, this.$router, { name: ROUTER.LAUNCH, params: { sessionId: this._get(this.toolbar, 'session.id') } });
				} else {
					this.routerPush(this.$route, this.$router, { name: ROUTER.SESSIONS });
				}
			}
		},
		sliceCurrSurveys () {
			const startIndex: number = (this.currPage * this.pageSize);
			const endIndex: number = ((this.currPage + 1) * this.pageSize);
			this.currSurveys = _slice(
				this.surveys,
				startIndex,
				endIndex,
			);
		},
		onPrevPage () {
			this.currPage = this.currPage - 1;
			if (this.currPage < 0) this.currPage = 0;

			this.sliceCurrSurveys();
		},
		async onNextPage () {
			this.currPage = this.currPage + 1;

			if (this.lastSurveyDoc && this.hasMoreSurveys) {
				const endIndex: number = (this.currPage * this.pageSize);
				if (endIndex >= this.surveys.length) {
					await this.loadMoreSurveys(this.surveyKeyValue);
				} else {
					this.sliceCurrSurveys();
				}
			} else {
				this.sliceCurrSurveys();
			}
		},
		async loadSurveys (surveyKeyValue: number) {
			try {
				// index created = surveys: key Ascending createdAt Descending __name__ Descending
				let surveysRef: any = db.collection('surveys')
					.where('key', '==', surveyKeyValue)
					.orderBy('createdAt', 'desc')
					.limit(this.pageSize);
				if (this.lastSurveyDoc) {
					surveysRef = surveysRef
						.startAfter(this.lastSurveyDoc);
				}

				const surveysSnapshot = await surveysRef.get();
				const result: any[] = surveysSnapshot.docs.map((doc: any) => {
					return {
						...doc.data(),
						get id () { return doc.id },
					};
				});
				await this.loadCurrAuthors(surveysSnapshot);
				const lastDoc: any = surveysSnapshot.empty ? null : surveysSnapshot.docs[surveysSnapshot.docs.length - 1];

				return {
					success: true,
					result,
					lastDoc,
				};
			} catch (e) {
				console.error('Error loading surveys: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading surveys',
					show: true,
				});

				return {
					success: false,
					result: [],
					lastDoc: null,
				};
			}
		},
		async loadInitialSurveys (surveyKeyValue: number) {
			if (!this.loadingInitialSurveys && !this.loadingMoreSurveys) {
				this.loadingInitialSurveys = true;
				this.lastSurveyDoc = null;
				this.hasMoreSurveys = false;
				const { success, result, lastDoc } = await this.loadSurveys(surveyKeyValue);
				this.loadingInitialSurveys = false;
				if (success) {
					this.currPage = 0;
					this.lastSurveyDoc = lastDoc;
					this.hasMoreSurveys = !!lastDoc;
					this.currSurveys = result;
					this.surveys = this.currSurveys;
				}
			}
		},
		async loadMoreSurveys () {
			if (!this.loadingInitialSurveys && !this.loadingMoreSurveys) {
				this.loadingMoreSurveys = true;
				const { success, result, lastDoc } = await this.loadSurveys(this.surveyKeyValue);
				this.loadingMoreSurveys = false;
				if (success) {
					this.lastSurveyDoc = lastDoc;
					this.hasMoreSurveys = !!lastDoc;
					this.currSurveys = result;
					this.surveys = this.surveys.concat(this.currSurveys);
					this.$scrollTo('#surveys-list-scroll-anchor', 200);
				}
			}
		},
		async loadCurrAuthors (surveysSnapshot: any) {
			if (!surveysSnapshot || !surveysSnapshot.docs || !surveysSnapshot.docs.length) return;

			this.loadingCurrAuthors = true;
			this.currAuthors = {
				...this.currAuthors
			};

			try {
				const promises = [];
				surveysSnapshot.docs.forEach((surveySnapshot: any) => {
					const survey: Survey = surveySnapshot.data();
					if (survey.teamId) {
						promises.push(new Promise(async (resolve: Function) => {
							try {
								const teamRef: any = db.doc(`teams/${survey.teamId}`);
								resolve({
									id: survey.teamId,
									snapshot: await teamRef.get(),
								});
							} catch (e) {
								console.error(e);
								resolve({
									id: survey.teamId,
									snapshot: null,
								});
							}
						}));
					}
					if (survey.userId) {
						promises.push(new Promise(async (resolve: Function) => {
							try {
								const userRef: any = db.doc(`users/${survey.userId}`);
								resolve({
									id: survey.userId,
									snapshot: await userRef.get(),
								});
							} catch (e) {
								console.error(e);
								resolve({
									id: survey.userId,
									snapshot: null,
								});
							}
						}));
					}
				});
				const authors: { id: string, snapshot: any }[] = await Promise.all(promises);
				authors.forEach(({ id, snapshot }) => {
					if (snapshot) {
						this.currAuthors[id] = snapshot.data();
					} else {
						this.currAuthors[id] = null;
					}
				});
			} catch (e) {
				console.error('Error loading authors', e);
			} finally {
				this.loadingCurrAuthors = false;
			}
		},
	},
	components: {
		AdminTeamStats,
		AdminUserStats,
		SurveyFieldsDisplay,
		BackButton,
		LoadingSVG,
		EmptyState,
	},
});
