
import {
	RUNNIT_NODE_FIELD_TYPE,
	RunnitNodeField,
	RunnitNodeRun,
	RunnitNodeRunInputValue,
	RunnitNodeRunResult,
	RunnitNodeDef,
	Avatar,
} from '@run-diffusion/shared';
import Vue from 'vue';
import { RunnitRemixMixin } from '@/mixins/RunnitRemixMixin';
import { db, functions } from '@/firebase';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import RunnitResult from '@/views/Runnits/RunnitResult.vue';
import RunnitImageInfoCarouselDialog from '@/views/Runnits/RunnitImageInfoCarouselDialog.vue';
import { mapState } from 'vuex';
import _shuffle from 'lodash/shuffle';
import { ROUTER } from '@/router/constants';
import { SNACKBAR_STATUS } from '@/constants/constants';
import RunnitsWithRDHorizontalLogoSVG from '@/assets/RunnitsWithRDHorizontalLogoSVG.vue';
import { RunnitsCRUDMixin } from '@/mixins/RunnitsCRUDMixin';

export default Vue.extend({
	name: 'RemixLandingPage',
	mixins: [RunnitRemixMixin, RunnitsCRUDMixin],
	data () {
		return {
			remixRunnitNodeRunId: null as string,
			remixResultUuid: null as string,
			remixRunnitNodeRun: null as RunnitNodeRun,
			remixResult: null as RunnitNodeRunResult,
			loadingRemixRunnitNodeRun: false as boolean,

			// Featured images data
			featuredNodeRunsPagesLoading: false as boolean,
			featuredNodeRunsLoading: false as boolean,
			isLoadingMore: false as boolean,
			featuredNodeRuns: [] as RunnitNodeRun[],
			pageSize: 50,
			usedPages: new Set<number>(),
			totalPages: 0,
			allNodeRunIds: [] as string[],
			hasMorePages: true,
			imageInfoCarouselConfig: {
				dialogOpen: false,
				nodeRun: null as RunnitNodeRun,
				nodeRunResult: null as RunnitNodeRunResult,
			},
			selectedColumns: 4,
		};
	},
	watch: {
		'$route.query': {
			immediate: true,
			async handler (newVal, oldVal) {
				if (newVal && (newVal?.rnrid !== oldVal?.rnrid || newVal?.ruuid !== oldVal?.ruuid)) {
					this.remixRunnitNodeRunId = newVal?.rnrid as string;
					this.remixResultUuid = newVal?.ruuid as string;
					if (this.remixRunnitNodeRunId && this.remixResultUuid) {
						await this.initRemixRunnitNodeRun();
					}
					if (newVal?.autoRemix) {
						this.routerReplace(this.$route, this.$router, {
							name: ROUTER.RUNNIT_REMIX,
							query: {
								rnrid: newVal?.rnrid,
								ruuid: newVal?.ruuid,
							}
						})
						this.handleRemixClick();
					}
				}
			},
		},
	},
	computed: {
		...mapState(['user']),
		featuredNodeRunResults (): RunnitNodeRunResult[] {
			if (!this.featuredNodeRuns || !this.featuredNodeRuns.length) {
				return [];
			}
			return this.featuredNodeRuns.flatMap(nodeRun => nodeRun.results.filter(result => result.featured));
		},
		nodeRunByIdMap (): Record<string, RunnitNodeRun> {
			if (!this.featuredNodeRuns || !this.featuredNodeRuns.length) {
				return {};
			}
			return this.featuredNodeRuns.reduce((map: Record<string, RunnitNodeRun>, nodeRun: RunnitNodeRun) => {
				map[nodeRun.id] = nodeRun;
				return map;
			}, {});
		},
		featuredColumns () {
			const numColumns = this.selectedColumns;
			const columns = Array.from({ length: numColumns }, () => []);
			const columnHeights = Array.from({ length: numColumns }, () => 0);

			if (!this.featuredNodeRunResults) return columns;

			this.featuredNodeRunResults.forEach((result) => {
				const height = result.file?.height || 1;
				const width = result.file?.width || 1;
				const aspectRatio = width / height;
				const normalizedHeight = 1 / aspectRatio;

				const { shortestColumnIndex } = columnHeights.reduce((acc, height, index) => {
					return height < acc.minHeight ? { minHeight: height, shortestColumnIndex: index } : acc;
				}, { minHeight: columnHeights[0], shortestColumnIndex: 0 });

				columns[shortestColumnIndex].push(result);
				columnHeights[shortestColumnIndex] += normalizedHeight;
			});

			return columns;
		},
		isRemixable (): boolean {
			return this.canRemix(this.remixRunnitNodeRun);
		},
	},
	async mounted () {
		if (!this.featuredNodeRuns.length && !this.featuredNodeRunsLoading) {
			await this.initFeaturedNodeRuns();
		}
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.handleScroll);
		this.featuredNodeRuns = [];
		this.usedPages.clear();
		this.hasMorePages = true;
	},
	methods: {
		goToHome () {
			this.$router.push({ name: ROUTER.RUNNITS_HOME });
		},
		async initRemixRunnitNodeRun () {
			if (!this.remixRunnitNodeRunId) return;

			this.loadingRemixRunnitNodeRun = true;
			try {
				const functionRef = functions
					.httpsCallable('getPublicRemixGeneration');
				const { nodeRun, nodeDef, nodeRunResult, error } = (await functionRef({
					nodeRunId: this.remixRunnitNodeRunId,
					resultUuid: this.remixResultUuid,
				})).data;

				if (error) {
					console.error('getPublicRemixGeneration returned an error', error);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error fetching remix generation',
						show: true,
					});
				} else {
					this.remixRunnitNodeRun = nodeRun;
					this.remixRunnitNodeRun.nodeDef = nodeDef;
					this.remixResult = nodeRunResult;
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loadingRemixRunnitNodeRun = false;
			}
		},
		goToSignup () {
			const currentPath: string = this.$router.resolve({
				name: ROUTER.RUNNIT_REMIX,
				query: {
					rnrid: this.remixRunnitNodeRunId,
					ruuid: this.remixResultUuid,
					autoRemix: true,
				},
			}).href;
			this.$router.push({
				name: ROUTER.SIGNUP,
				query: { redirect: currentPath }
			});
		},
		goToLogin () {
			const currentPath: string = this.$router.resolve({
				name: ROUTER.RUNNIT_REMIX,
				query: {
					rnrid: this.remixRunnitNodeRunId,
					ruuid: this.remixResultUuid,
					autoRemix: true,
				},
			}).href;
			this.$router.push({
				name: ROUTER.LOGIN,
				query: { redirect: currentPath }
			});
		},
		async initFeaturedNodeRuns () {
			try {
				this.featuredNodeRunsPagesLoading = true;
				const nodeRunsRef = db.collection('runnitNodeRuns').where('featured', '==', true);
				const snapshot = await nodeRunsRef.get();
				this.allNodeRunIds = snapshot.docs.map(doc => doc.id);
				this.totalPages = Math.ceil(this.allNodeRunIds.length / this.pageSize);
				await this.loadNextRandomPage();
			} catch (error) {
				console.error('Error fetching featured images', error);
			} finally {
				this.featuredNodeRunsPagesLoading = false;
			}
		},
		handleScroll () {
			if (this.isLoadingMore || !this.hasMorePages || this.featuredNodeRunsLoading) {
				return;
			}

			const scrollPosition = window.scrollY + window.innerHeight;
			const threshold = document.documentElement.scrollHeight - 800;

			if (scrollPosition > threshold) {
				this.loadNextRandomPage();
			}
		},
		async loadNextRandomPage () {
			if (!this.hasMorePages || this.featuredNodeRunsLoading || this.isLoadingMore) return;

			try {
				this.isLoadingMore = true;
				this.featuredNodeRunsLoading = true;

				const availablePages = Array.from(
					{ length: this.totalPages },
					(_, i) => i
				).filter(page => !this.usedPages.has(page));

				if (availablePages.length === 0) {
					this.hasMorePages = false;
					return;
				}

				const randomIndex = Math.floor(Math.random() * availablePages.length);
				const selectedPage = availablePages[randomIndex];
				this.usedPages.add(selectedPage);

				const startIndex = selectedPage * this.pageSize;
				const pageIds = this.allNodeRunIds.slice(startIndex, startIndex + this.pageSize);

				const newNodeRuns = await Promise.all(
					pageIds.map(async (id) => {
						const doc = await db.collection('runnitNodeRuns').doc(id).get();
						if (!doc.exists) return null;

						let fetchedNodeDef = null;
						const data = doc.data();

						if (data.nodeDef && !data.nodeDef.title) {
							try {
								const nodeDefRef = db.doc(`runnitNodeDefs/${data.nodeDefId}`);
								const nodeDef = (await nodeDefRef.get()).data();
								fetchedNodeDef = { ...nodeDef, id: data.nodeDefId };
							} catch (err) {
								console.error('Failed to fetch the generating tool', err);
							}
						}

						return {
							...data,
							...(fetchedNodeDef ? { nodeDef: fetchedNodeDef } : {}),
							get id () { return doc.id; },
						};
					})
				);

				const validNodeRuns = newNodeRuns.filter(run => run !== null);
				this.featuredNodeRuns = [...this.featuredNodeRuns, ..._shuffle(validNodeRuns)];
				this.hasMorePages = this.usedPages.size < this.totalPages;

			} catch (error) {
				console.error('Error loading next page', error);
			} finally {
				this.featuredNodeRunsLoading = false;
				this.isLoadingMore = false;
			}
		},
		onNodeRunResultClick (nodeRun: RunnitNodeRun, nodeRunResult: RunnitNodeRunResult) {
			this.imageInfoCarouselConfig = {
				dialogOpen: !!(nodeRun && nodeRunResult),
				nodeRun,
				nodeRunResult,
			};
		},
		featuredResultFilter (result: RunnitNodeRunResult) {
			return result.featured;
		},
		handleIsPublicUpdated (payload: { nodeRunResult: RunnitNodeRunResult, nodeRun: RunnitNodeRun }) {
			this.imageInfoCarouselConfig.nodeRun = { ...payload.nodeRun };
			this.imageInfoCarouselConfig.nodeRunResult = { ...payload.nodeRunResult };
		},
		handleRemixClick () {
			if (!this.user) {
				const currentPath: string = this.$router.resolve({
					name: ROUTER.RUNNIT_REMIX,
					query: {
						rnrid: this.remixRunnitNodeRunId,
						ruuid: this.remixResult.uuid,
						autoRemix: true,
					},
				}).href;
				this.$router.push({
					name: ROUTER.LOGIN,
					query: { redirect: currentPath }
				});
			} else if (this.isRemixable) {
				this.openToolWithSameInputs(this.remixRunnitNodeRun.nodeDef, this.remixRunnitNodeRun, this.remixRunnitNodeRunId, null, null);
			} else {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Unable to Remix this Creation.',
					show: true,
				});
			}
		},
	},
	components: {
		LoadingSVG,
		RunnitResult,
		RunnitImageInfoCarouselDialog,
		RunnitsWithRDHorizontalLogoSVG,
	},
});
