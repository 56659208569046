import { db } from '@/firebase';
import {
	RUNNIT_NODE_FIELD_TYPE,
	RUNNIT_TYPE,
	RunnitNodeField,
	RunnitNodeFieldGroup,
	RunnitNodeRun,
	RunnitNodeDef,
	RUNNIT_NODE_DEF_TOOL_TYPE,
	FieldDefUuid,
} from '@run-diffusion/shared';
import Vue from 'vue';
import { ROUTER } from '@/router/constants';
import { RunnitsCRUDMixin } from './RunnitsCRUDMixin';
import { mapActions } from 'vuex';
import _isEmpty from 'lodash/isEmpty';

export const RunnitRemixMixin = Vue.extend({
	mixins: [
		RunnitsCRUDMixin,
	],
	methods: {
		...mapActions(['updateRunnitState']),
		async openToolWithSameInputs (nodeDef: RunnitNodeDef, nodeRun: RunnitNodeRun, nodeRunId: string, runnitId?: string, nodeId?: string) {
			const inputValues: Record<FieldDefUuid, any> = await this.fetchNodeRunValues(nodeRun, nodeRunId);
			const encodedInputValues: string = encodeURIComponent(JSON.stringify(inputValues));

			// If we're on a RunnitBoard, just update the query params
			if (runnitId && nodeId && this.$route.params.runnitId === runnitId) {
				// Set the selectedNodeId to match the nodeId from the generation
				// This needs to happen before the routerPush so that the selected node is set correctly
				this.updateRunnitState({
					selectedNodeId: nodeId
				});

				// Update route and selectedNodeId
				await this.routerPush(this.$route, this.$router, {
					name: ROUTER.RUNNITS_BOARD,
					params: {
						runnitId: runnitId || this.$route.params.runnitId,
					},
					query: {
						inputValues: encodedInputValues
					}
				});

				this.$emit('close-carousel', true);
				return;
			}

			// Otherwise create a new single tool runnit
			this.onAddRunnitClick(nodeDef, RUNNIT_TYPE.SINGLE_TOOL, {
				inputValues: encodedInputValues
			});
			this.$emit('close-carousel', true);
		},
		async fetchNodeRunValues (nodeRun: RunnitNodeRun, nodeRunId: string) {
			try {
				const fieldDefUuidToTypeMap = this.mapFieldDefUuidToFieldType(nodeRun.fields);
				const inputs = { ...nodeRun.inputs }
				Object.keys(fieldDefUuidToTypeMap).forEach(inputUuid => {
					if (
						fieldDefUuidToTypeMap[inputUuid] === RUNNIT_NODE_FIELD_TYPE.PROMPT &&
						nodeRun.promptMagic?.results?.[inputUuid]
					) {	
						inputs[inputUuid] = nodeRun.promptMagic.results[inputUuid];
					} else if (fieldDefUuidToTypeMap[inputUuid] === RUNNIT_NODE_FIELD_TYPE.SEED) {
						delete inputs[inputUuid];
					}
				});
				return inputs;
			} catch (err) {
				console.error('Failed to preload settings', err);
			}
		},
		mapFieldDefUuidToFieldType (fields: (RunnitNodeFieldGroup | RunnitNodeField)[]) {
			let map = {}
			for (const field of (fields || [])) {
				if ((field as RunnitNodeFieldGroup).__rgroup) {
					map = {
						...this.mapFieldDefUuidToFieldType((field as RunnitNodeFieldGroup).fields),
						...map
					};
				} else if ((field as RunnitNodeField).__rfield) {
					map[(field as RunnitNodeField).fieldDefUuid] = (field as RunnitNodeField).type;
				}
			}
			return map;
		},
		canRemix (nodeRun: RunnitNodeRun): boolean {
			if (!nodeRun || !nodeRun.nodeDef) return false;

			// Is the tool published?
			if (!nodeRun.nodeDef.isPublished) return false;

			// Does the user have access to the team tool?
			if (
				nodeRun.nodeDef?.type === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM
			) {
				if (!this.user || !this.user.teamIds || _isEmpty(this.user.teamIds)) return false;
				if (!Object.keys(this.user.teamIds).some((teamId: string) => nodeRun.nodeDef.teamIds?.[teamId])) return false;
			}

			// Does the user have access to the model used?
			const modelFieldDefUuid: FieldDefUuid = this.getFieldDefUuidByFieldType(nodeRun.nodeDef.fields, RUNNIT_NODE_FIELD_TYPE.MODEL_SINGLE_SELECT);
			if (!modelFieldDefUuid) return true;
			const model = nodeRun.inputs[modelFieldDefUuid];

			if (model) {
				if (!this.user) return false;
				if (model.teamId && !this.user.teamIds[model.teamId]) return false;
				if (model.userId && model.userId !== this.user.id) return false;
			}

			return true;
		},
	},
});
