/**
 * For when you need to reference the names outside this file
 */
export const ROUTER: {
	LOGIN: string
	SIGNUP: string
	TEAM_SIGNUP: string
	EMAIL_VERIFICATION: string
	SESSIONS: string
	SETUP_SESSION: string
	ANOTHER_SESSION: string
	SETUP_ANOTHER_SESSION: string
	LAUNCH: string
	APP_METRICS_REPORT: string
	IMAGE_GENERATIONS_REPORT: string
	IMAGE_AUDIT_REPORT: string
	USER_USAGE_REPORT: string
	TEAM_MEMBERS: string
	SPACES: string
	TEAM_RUNNIT_SETTINGS: string
	WORKSHOPS: string
	RUNNITS: string
	RUNNITS_APP: string
	RUNNITS_HOME: string
	RUNNITS_LIBRARY: string
	RUNNITS_ALL_TOOLS: string
	RUNNITS_BOARD: string
	RUNNITS_SUBSCRIPTIONS: string
	RUNNITS_MODELS: string
	RUNNITS_MODELS_LIST: string
	RUNNITS_TRAIN_MODEL_OPTIONS: string
	RUNNITS_BOARDS: string
	RUNNIT_REMIX: string
	RUNNITS_APP_LIBRARY: string
	HELP_CENTER: string
	RUNNITS_WHATS_NEW: string
	MANAGE_UPDATES: string
} = {
	LOGIN: 'Login',
	SIGNUP: 'Signup',
	TEAM_SIGNUP: 'TeamSignup',
	EMAIL_VERIFICATION: 'EmailVerification',
	SESSIONS: 'Sessions',
	SETUP_SESSION: 'SetupSession',
	ANOTHER_SESSION: 'AnotherSession',
	SETUP_ANOTHER_SESSION: 'SetupAnotherSession',
	LAUNCH: 'Launch',
	APP_METRICS_REPORT: 'AppMetricsReport',
	IMAGE_GENERATIONS_REPORT: 'ImageGenerationsReport',
	IMAGE_AUDIT_REPORT: 'ImageAuditReport',
	USER_USAGE_REPORT: 'UserUsageReport',
	TEAM_MEMBERS: 'TeamMembers',
	SPACES: 'Spaces',
	TEAM_RUNNIT_SETTINGS: 'TeamRunnitSettings',
	WORKSHOPS: 'Workshops',
	RUNNITS: 'Runnits',
	RUNNITS_APP: 'RunnitsApp',
	RUNNITS_HOME: 'RunnitsHome',
	RUNNITS_LIBRARY: 'RunnitsLibrary',
	RUNNITS_ALL_TOOLS: 'RunnitsAllTools',
	RUNNITS_BOARD: 'RunnitsBoard',
	RUNNITS_SUBSCRIPTIONS: 'RunnitsSubscriptions',
	RUNNITS_MODELS: 'RunnitsModels',
	RUNNITS_MODELS_LIST: 'RunnitsModelsList',
	RUNNITS_TRAIN_MODEL_OPTIONS: 'RunnitsTrainModelOptions',
	RUNNITS_BOARDS: 'RunnitBoards',
	RUNNIT_REMIX: 'RunnitRemix',
	RUNNITS_APP_LIBRARY: 'RunnitAppLibrary',
	HELP_CENTER: 'HelpCenter',
	RUNNITS_WHATS_NEW: 'RunnitsWhatsNew',
	MANAGE_UPDATES: 'ManageUpdates',
};
