
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import {
	DEFAULT_SHOW_TEXT_COUNTER_AT,
	DEFAULT_TEXT_MAX_LENGTH,
	getTextMaxLengthRule,
} from '@/utils';
import BackButton from '@/components/base/BackButton.vue';
import { db } from '@/firebase';
import { AppOffer, AppOfferResource, APP_OFFER_TYPE } from '@run-diffusion/shared';
import _find from 'lodash/find';
import _trim from 'lodash/trim';
import _values from 'lodash/values';
import StringArrayField from '@/components/StringArrayField.vue';
import HtmlField from '@/components/HtmlField.vue';
import AppOfferResourcesField from '@/views/Admin/AdminEditAppOffers/AppOfferResourcesField.vue';
import ActionsIsland from '@/components/ActionsIsland.vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import AppOfferCard from '@/views/Sessions/AppOffersList/AppOfferCard/AppOfferCard.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'AdminEditAppOffers',
	data () {
		return {
			DEFAULT_SHOW_TEXT_COUNTER_AT,
			DEFAULT_TEXT_MAX_LENGTH,
			APP_OFFER_TYPE,

			createNewAppOfferKey: null,
			localAppOffers: [],
			hasMovedAppOfferItem: false,
			editAppOffer: null,
			expandedAppOffer: null,
			orderedAppKeys: [],

			isCreatingNew: false,
			duplicateAppOffer: null,

			saving: false,
			formValid: false,
			rules: {
				requiredTextField: [
					getTextMaxLengthRule(),
					v => !!_trim(v) || 'Required',
				],
				required: [
					v => !!_trim(v) || 'Required',
				],
				requiredArray: [
					v => !!v.length || 'Required',
				],
			},
		};
	},
	computed: {
		...mapState([
			'user',
			'appOffers',
		]),
	},
	watch: {
		appOffers: {
			immediate: true,
			handler (newVal: AppOffer[], oldVal: AppOffer[]) {
				if (newVal !== oldVal) {
					this.localAppOffers = (newVal || []).map(this.copyLocalAppOffer);
					this.orderedAppKeys = (newVal || []).map(({ app }) => app).sort();
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		_values,
		_trim,
		goBack () {
			if (this.hasRouteHistory) {
				this.$router.back();
			} else {
				const hasCurrentSessionId: boolean = !!(this._get(this.toolbar, 'session.id'));

				if (hasCurrentSessionId) {
					this.routerPush(this.$route, this.$router, { name: ROUTER.LAUNCH, params: { sessionId: this._get(this.toolbar, 'session.id') } });
				} else {
					this.routerPush(this.$route, this.$router, { name: ROUTER.SESSIONS });
				}
			}
		},
		onCreateNewClick (appOffer: AppOffer) {
			this.isCreatingNew = true;
			this.duplicateAppOffer = appOffer || null;
			this.createNewAppOfferKey = appOffer ? appOffer.app : null;
		},
		onEditAppOfferCancel () {
			if (this.editAppOffer) {
				const valueAppOffer: AppOffer = _find(
					this.value || [],
					({ id }) => id === this.editAppOffer.id,
				) || null;
				if (valueAppOffer) {
					this.localAppOffers = this.localAppOffers.map((localAppOffer: AppOffer) => {
						if (localAppOffer.id !== this.editAppOffer.id) {
							return localAppOffer;
						}
						return this.copyLocalAppOffer(valueAppOffer);
					});
				}

				this.editAppOffer = null;
			}
		},
		copyLocalAppOffer (appOffer: AppOffer) {
			return {
				id: appOffer.id,
				publishedAt: appOffer.publishedAt ? new Date(appOffer.publishedAt.toMillis()) : null,
				isTeam: !!appOffer.isTeam,
				clubs: (appOffer.clubs || []).sort(),
				app: appOffer.app,
				type: appOffer.type,
				label: appOffer.label,
				shortLabel: appOffer.shortLabel,
				backendApps: (appOffer.backendApps || []).sort(),
				sortOrder: appOffer.sortOrder,
				descriptionHtml: appOffer.descriptionHtml,
				carouselUrls: appOffer.carouselUrls || [],
				resources: appOffer.resources || [],
				popularity: appOffer.popularity,
				tags: (appOffer.tags || []).sort(),
				singleOnly: !!appOffer.singleOnly,
				isBeta: !!appOffer.isBeta,
				isDev: !!appOffer.isDev,
				showApiEnabled: !!appOffer.showApiEnabled,
				showPersistExtensions: !!appOffer.showPersistExtensions,
				showStabilityAILicenseDisclaimer: !!appOffer.showStabilityAILicenseDisclaimer,
			};
		},
		moveAppOfferItem (fromIndex: number, toIndex: number) {
			if (toIndex <= (this.localAppOffers || []).length - 1 && toIndex >= 0) {
				const item: AppOffer = this.localAppOffers[fromIndex];
				let newArray: AppOffer[] = this.localAppOffers.filter((val: AppOffer) => val !== item);
				newArray.splice(toIndex, 0, item);
				this.localAppOffers = newArray;
				this.hasMovedAppOfferItem = true;
			}
		},
		async onCreateNew (closeCallback: Function) {
			const newAppKey: string = _trim(this.createNewAppOfferKey).toUpperCase();
			if (this.saving || !newAppKey) return;

			try {
				this.saving = true;
				const appOfferRef = db
					.doc(`appOffers/${newAppKey}`);

				// Make sure key is free
				if ((await appOfferRef.get()).exists) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: `The app key "${newAppKey}" is already being used`,
						show: true,
					});
				} else {
					await appOfferRef.set({
						app: newAppKey,
						publishedAt: null,
						sortOrder: this.localAppOffers.length,
						...(this.duplicateAppOffer ? {
							isTeam: !!this.duplicateAppOffer.isTeam,
							clubs: this.duplicateAppOffer.clubs || [],
							type: this.duplicateAppOffer.type,
							label: `${this.duplicateAppOffer.label} (duplicated)`,
							shortLabel: this.duplicateAppOffer.shortLabel,
							backendApps: this.duplicateAppOffer.backendApps || [],
							extensions: this.duplicateAppOffer.extensions || [],
							descriptionHtml: this.duplicateAppOffer.descriptionHtml,
							carouselUrls: this.duplicateAppOffer.carouselUrls || [],
							resources: this.duplicateAppOffer.resources || [],
							popularity: this.duplicateAppOffer.popularity,
							tags: this.duplicateAppOffer.tags || [],
							singleOnly: !!this.duplicateAppOffer.singleOnly,
							isBeta: !!this.duplicateAppOffer.isBeta,
							isDev: !!this.duplicateAppOffer.isDev,
							showApiEnabled: !!this.duplicateAppOffer.showApiEnabled,
							showPersistExtensions: !!this.duplicateAppOffer.showPersistExtensions,
						} : {
							label: '',
							shortLabel: '',
							descriptionHtml: '',
						}),
					});

					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! App offer created.`,
						show: true,
					});
					this.createNewAppOfferKey = null;
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error creating app offer`,
					show: true,
				});
			} finally {
				if (closeCallback) closeCallback();
				this.saving = false;
			}
		},
		async onSaveAppOffersSortOrder () {
			if (this.saving) return;
			try {
				this.saving = true;
				await Promise.all(this.localAppOffers.map(async (appOffer: AppOffer, index: number) => {
					const appOfferRef = db
						.doc(`appOffers/${appOffer.id}`);
					await appOfferRef.update({
						sortOrder: index,
					});
				}));
				this.hasMovedAppOfferItem = false;
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Sort order saved.`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error saving sort order',
					show: true,
				});
			} finally {
				this.saving = false;
			}
		},
		async onSave () {
			if (!this.editAppOffer || this.saving) return;
			if (!this.$refs.form.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			try {
				this.saving = true;
				const appOfferRef = db
					.doc(`appOffers/${this.editAppOffer.id}`);
				await appOfferRef.update({
					publishedAt: this.editAppOffer.publishedAt,
					isTeam: !!this.editAppOffer.isTeam,
					clubs: this.editAppOffer.clubs.map((v: string) => _trim(v).toUpperCase()),
					type: _trim(this.editAppOffer.type),
					label: _trim(this.editAppOffer.label),
					shortLabel: _trim(this.editAppOffer.shortLabel),
					backendApps: this.editAppOffer.backendApps.map((v: string) => _trim(v).toUpperCase()),
					descriptionHtml: _trim(this.editAppOffer.descriptionHtml),
					carouselUrls: this.editAppOffer.carouselUrls.map((v: string) => _trim(v)),
					resources: this.editAppOffer.resources.map((v: AppOfferResource) => ({
						type: v.type,
						name: _trim(v.name),
						description: _trim(v.description),
						url: _trim(v.url),
						imgUrl: _trim(v.imgUrl),
					})),
					popularity: this.editAppOffer.popularity || null,
					tags: this.editAppOffer.tags.map((v: string) => _trim(v)),
					singleOnly: !!this.editAppOffer.singleOnly,
					isBeta: !!this.editAppOffer.isBeta,
					isDev: !!this.editAppOffer.isDev,
					showApiEnabled: !!this.editAppOffer.showApiEnabled,
					showPersistExtensions: !!this.editAppOffer.showPersistExtensions,
					showStabilityAILicenseDisclaimer: !!this.editAppOffer.showStabilityAILicenseDisclaimer,
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! ${this.editAppOffer.label} saved.`,
					show: true,
				});
				this.editAppOffer = null;
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error saving app offer ${this.editAppOffer.label}`,
					show: true,
				});
			} finally {
				this.saving = false;
			}
		},
	},
	components: {
		AppOfferCard,
		ConfirmDialog,
		ActionsIsland,
		AppOfferResourcesField,
		HtmlField,
		StringArrayField,
		BackButton,
	},
});
