
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Runnits Logo with RD',
		},
		width: {
			type: [Number, String],
			default: 834,
		},
		height: {
			type: [Number, String],
			default: 172,
		},
		viewBox: {
			type: String,
			default: '0 0 834 172',
		},
		fill: {
			type: String,
			default: 'white',
		},
	},
});
