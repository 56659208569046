import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.clearable)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"clear-btn",attrs:{"fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClearClick.apply(null, arguments)}}},on),[_c(VIcon,{staticStyle:{"color":"var(--grey-300)","font-size":"16px"}},[_vm._v("mdi-close-thick")])],1)]}}],null,false,1264564566)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(_vm._s(_vm.clearText))])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('div',{ref:"runnitImageContainer",staticClass:"runnit-image-container",class:{
				...(!_vm.noClick && {
					'runnit-image-container-no-selection-mode': !_vm.isSelectionMode,
					'runnit-image-container-selection-mode': _vm.isSelectionMode,
				}),
				'gradient-border': _vm.isSelectionMode && _vm.isSelected,
			},style:({
				...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
					borderRadius: '12px',
				}),
				...(_vm.dimensions.width <= _vm.WIDTH_XS_BREAKPOINT && {
					borderRadius: '8px',
				}),
				width: _vm.computedWidth || '100%',
				height: _vm.computedHeight || '100%',
				...(_vm.maxHeight && {
					maxHeight: _vm.maxHeight,
				}),
			})},[_c(VSlideYTransition,[(_vm.isSelectionMode)?_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"runnit-image-container-selected",class:{ selected: _vm.isSelected },style:({
						...(_vm.$vuetify.breakpoint.mdAndUp && {
							width: '40px',
							height: '40px',
						}),
						...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
							borderRadius: '12px',
						}),
						...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
							borderRadius: '4px',
						}),
					}),on:{"click":_vm.onClick}},[(_vm.isSelected)?_c(VIcon,{staticStyle:{"font-size":"27px","color":"white"}},[_vm._v(" mdi-check ")]):(_vm.isMultiSelection)?_c(VIcon,{staticStyle:{"font-size":"27px"},style:({
							color: _vm.isSelected ? 'var(--primary-green)' : 'var(--grey-300)',
						})},[_vm._v(" mdi-plus-circle-outline ")]):(_vm.isSingleSelection)?_c(VIcon,{staticStyle:{"font-size":"27px"},style:({
							color: _vm.isSelected ? 'var(--primary-green)' : 'var(--grey-300)',
						})},[_vm._v(" mdi-check ")]):_vm._e()],1):_vm._e()]),(_vm.srcIsError || _vm.isErrorPlaceholder)?_c(VTooltip,{attrs:{"top":"","nudge-top":-50,"open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"runnit-image-error",style:({
							...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
								borderRadius: 'var(--global-border-radius)',
							}),
							...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
								borderRadius: '8px',
							}),
						})},'div',attrs,false),on),[_c('img',{staticClass:"transparent-img",attrs:{"src":require("./assets/transparent-square.png"),"alt":"Error"}}),_c('div',{staticClass:"runnit-image-error-content"},[_c(VIcon,{staticStyle:{"color":"var(--grey-700)"},style:({
									fontSize: _vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT ? '32px' : '24px',
								})},[_vm._v(" mdi-alert-circle-outline ")])],1)])]}}],null,false,968619107)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" Error generating this image, please try again ")])]):(_vm.src)?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[(_vm.useComparisonSlider && _vm.isInsideInfoCarousel)?[_c('img-comparison-slider',[_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",style:({
									...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: 'var(--global-border-radius)',
									}),
									...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: '8px',
									}),
								}),attrs:{"slot":"first","loading":_vm.isInsideInfoCarousel ? 'eager' : 'lazy',"src":_vm.src,"alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded},slot:"first"})]),_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",style:({
									...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: 'var(--global-border-radius)',
									}),
									...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: '8px',
									}),
								}),attrs:{"slot":"second","loading":_vm.isInsideInfoCarousel ? 'eager' : 'lazy',"src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fusers%2Fei7OE9EsV6d5OTfkG60JeEa4zl22%2Fruns%2FUyTy1IAzPetdBzqLB3xt%2Fthumbs%2FoQyfw6JZedCPDnTStBxEJ_512x512.webp?alt=media&token=75d78788-da5d-4da0-82dc-2b73c1db9ccd","alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded},slot:"second"})])])]:(_vm.useZoomViewer)?[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticStyle:{"position":"relative"}},[(_vm.renderZoomViewer)?_c('v-zoomer',{ref:"zoomer",style:({
									width: _vm.dimensions.width || 'auto',
									height: _vm.dimensions.height || 'auto',
								}),attrs:{"max-scale":7,"zoomed":_vm.zoomed},on:{"update:zoomed":function($event){_vm.zoomed=$event}}},[_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",class:{ loaded: _vm.srcIsLoaded },style:({
											...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
												borderRadius: 'var(--global-border-radius)',
											}),
											...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
												borderRadius: '8px',
											}),
											cursor: _vm.zoomed ? 'all-scroll' : 'pointer',
										}),attrs:{"loading":_vm.isInsideInfoCarousel ? 'eager' : 'lazy',"src":_vm.src,"alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded}})])]):_vm._e(),_c('div',{staticClass:"zoom-menu",style:({ opacity: hover ? 1 : 0 })},[_c(VTooltip,{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({attrs:{"disabled":!_vm.zoomed,"icon":"","text":""},on:{"click":_vm.onZoomOut}},on),[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Zoom Out")])]),_c(VTooltip,{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({attrs:{"disabled":!_vm.zoomed,"icon":"","text":""},on:{"click":_vm.onResetZoom}},'base-button',attrs,false),on),[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v("mdi-magnify-minus")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Reset Zoom")])]),_c(VTooltip,{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({attrs:{"icon":"","text":""},on:{"click":_vm.onZoomIn}},on),[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Zoom In")])])],1)],1)]}}],null,false,4101999526)})]:[_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",class:{ loaded: _vm.srcIsLoaded },style:({
								borderRadius: _vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT ? 'var(--global-border-radius)' : '8px',
							}),attrs:{"loading":_vm.isInsideInfoCarousel ? 'eager' : 'lazy',"src":_vm.src,"alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded}})])]],2):_c('img',{staticClass:"transparent-img",attrs:{"src":require("./assets/transparent-square.png"),"alt":"Error"}}),(!_vm.srcIsError && (_vm.srcIsFetching || !_vm.srcIsLoaded))?_c('div',{staticClass:"runnit-image-shimmer",style:({
					...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: 'var(--global-border-radius)',
					}),
					...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: '8px',
					}),
				})}):_vm._e(),(!_vm.srcIsError && _vm.isLoadingPlaceholder)?_c('div',{staticClass:"runnit-image-loading",style:({
					...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: 'var(--global-border-radius)',
					}),
					...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: '8px',
					}),
				})},[_c('img',{staticClass:"transparent-img",attrs:{"src":require("./assets/transparent-square.png"),"alt":"Loading"}}),_c('div',{staticClass:"runnit-image-loading-content",style:({
						'padding-top': `${_vm.computedLoadingContainerPadding}px`
					})},[_c('runnit-image-timer',{attrs:{"timer-start-at-millis":_vm.computedTimerStartAtMillis,"parent-width":_vm.dimensions.width,"parent-height":_vm.dimensions.height,"cold-start-warning-seconds":_vm.coldStartWarningSeconds}})],1)]):_vm._e(),(!_vm.noClick && !_vm.isInsideInfoCarousel && !_vm.useZoomViewer)?_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"blur-overlay-mobile",style:({
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}),on:{"click":_vm.onClick}}):_vm._e()],1)]):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{ref:"runnitImageContainer",staticClass:"runnit-image-container",class:{
				...(!_vm.noClick && {
					'runnit-image-container-no-selection-mode': !_vm.isSelectionMode,
					'runnit-image-container-selection-mode': _vm.isSelectionMode,
				}),
				'gradient-border': _vm.isSelectionMode && _vm.isSelected,
				'draggable': _vm.canBeDragged,
			},style:({
				...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
					borderRadius: '12px',
				}),
				...(_vm.dimensions.width <= _vm.WIDTH_XS_BREAKPOINT && {
					borderRadius: '8px',
				}),
				width: _vm.computedWidth || '100%',
				height: _vm.computedHeight || '100%',
				...(_vm.maxHeight && {
					maxHeight: _vm.maxHeight,
				}),
			}),attrs:{"draggable":_vm.canBeDragged},on:{"dragstart":_vm.handleDragStart,"dragend":_vm.handleDragEnd}},[_c(VSlideYTransition,[(_vm.isSelectionMode)?_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"runnit-image-container-selected",class:{ selected: _vm.isSelected },style:({
						...(_vm.$vuetify.breakpoint.mdAndUp && {
							width: '40px',
							height: '40px',
						}),
						...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
							borderRadius: '12px',
						}),
						...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
							borderRadius: '4px',
						}),
					}),on:{"click":_vm.onClick}},[(_vm.isSelected)?_c(VIcon,{staticStyle:{"font-size":"27px","color":"white"}},[_vm._v(" mdi-check ")]):(_vm.isMultiSelection)?_c(VIcon,{staticStyle:{"font-size":"27px"},style:({
							color: hover ? 'var(--primary-green)' : 'var(--grey-300)',
						})},[_vm._v(" mdi-plus-circle-outline ")]):(_vm.isSingleSelection)?_c(VIcon,{staticStyle:{"font-size":"27px"},style:({
							color: hover ? 'var(--primary-green)' : 'var(--grey-300)',
						})},[_vm._v(" mdi-check ")]):_vm._e()],1):_vm._e()]),(_vm.srcIsError || _vm.isErrorPlaceholder)?_c(VTooltip,{attrs:{"top":"","nudge-top":-50,"open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"runnit-image-error",style:({
							...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
								borderRadius: 'var(--global-border-radius)',
							}),
							...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
								borderRadius: '8px',
							}),
						})},'div',attrs,false),on),[_c('img',{staticClass:"transparent-img",attrs:{"src":require("./assets/transparent-square.png"),"alt":"Error"}}),_c('div',{staticClass:"runnit-image-error-content"},[_c(VIcon,{staticStyle:{"color":"var(--grey-700)"},style:({
									fontSize: _vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT ? '32px' : '24px',
								})},[_vm._v(" mdi-alert-circle-outline ")])],1)])]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" Error generating this image, please try again ")])]):(_vm.src)?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[(_vm.useComparisonSlider && _vm.isInsideInfoCarousel)?[_c('img-comparison-slider',[_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",style:({
									...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: 'var(--global-border-radius)',
									}),
									...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: '8px',
									}),
								}),attrs:{"slot":"first","src":_vm.src,"alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded},slot:"first"})]),_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",style:({
									...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: 'var(--global-border-radius)',
									}),
									...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
										borderRadius: '8px',
									}),
								}),attrs:{"slot":"second","src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fusers%2Fei7OE9EsV6d5OTfkG60JeEa4zl22%2Fruns%2FUyTy1IAzPetdBzqLB3xt%2Fthumbs%2FoQyfw6JZedCPDnTStBxEJ_512x512.webp?alt=media&token=75d78788-da5d-4da0-82dc-2b73c1db9ccd","alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded},slot:"second"})])])]:(_vm.useZoomViewer)?[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticStyle:{"position":"relative"}},[(_vm.renderZoomViewer)?_c('v-zoomer',{ref:"zoomer",style:({
									width: _vm.dimensions.width || 'auto',
									height: _vm.dimensions.height || 'auto',
								}),attrs:{"max-scale":7,"zoomed":_vm.zoomed},on:{"update:zoomed":function($event){_vm.zoomed=$event}}},[_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",class:{ loaded: _vm.srcIsLoaded },style:({
											...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
												borderRadius: 'var(--global-border-radius)',
											}),
											...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
												borderRadius: '8px',
											}),
											cursor: _vm.zoomed ? 'all-scroll' : 'pointer',
										}),attrs:{"src":_vm.src,"alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded}})])]):_vm._e(),_c('div',{staticClass:"zoom-menu",style:({ opacity: hover ? 1 : 0 })},[_c(VTooltip,{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({attrs:{"disabled":!_vm.zoomed,"icon":"","text":""},on:{"click":_vm.onZoomOut}},on),[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Zoom Out")])]),_c(VTooltip,{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({attrs:{"disabled":!_vm.zoomed,"icon":"","text":""},on:{"click":_vm.onResetZoom}},'base-button',attrs,false),on),[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v("mdi-magnify-minus")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Reset Zoom")])]),_c(VTooltip,{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({attrs:{"icon":"","text":""},on:{"click":_vm.onZoomIn}},on),[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Zoom In")])])],1)],1)]}}],null,true)})]:[_c('div',{staticClass:"image-sizing-container",style:({ ..._vm.imgSizeStyles })},[_c('img',{staticClass:"runnit-image",class:{ loaded: _vm.srcIsLoaded },style:({
								borderRadius: _vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT ? 'var(--global-border-radius)' : '8px',
							}),attrs:{"loading":_vm.isInsideInfoCarousel ? 'eager' : 'lazy',"src":_vm.src,"alt":"Runnit Generation","decoding":"async"},on:{"load":_vm.onSrcLoaded}})])]],2):_c('img',{staticClass:"transparent-img",attrs:{"src":require("./assets/transparent-square.png"),"alt":"Error"}}),(!_vm.srcIsError && (_vm.srcIsFetching || !_vm.srcIsLoaded))?_c('div',{staticClass:"runnit-image-shimmer",style:({
					...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: 'var(--global-border-radius)',
					}),
					...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: '8px',
					}),
				})}):_vm._e(),(!_vm.srcIsError && _vm.isLoadingPlaceholder)?_c('div',{staticClass:"runnit-image-loading",style:({
					...(_vm.dimensions.width > _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: 'var(--global-border-radius)',
					}),
					...(_vm.dimensions.width < _vm.WIDTH_XS_BREAKPOINT && {
						borderRadius: '8px',
					}),
				})},[_c('img',{staticClass:"transparent-img",attrs:{"src":require("./assets/transparent-square.png"),"alt":"Loading"}}),_c('div',{staticClass:"runnit-image-loading-content",style:({
						'padding-top': `${_vm.computedLoadingContainerPadding}px`
					})},[_c('runnit-image-timer',{attrs:{"timer-start-at-millis":_vm.computedTimerStartAtMillis,"parent-width":_vm.dimensions.width,"parent-height":_vm.dimensions.height,"cold-start-warning-seconds":_vm.coldStartWarningSeconds}})],1)]):_vm._e(),(!_vm.noClick && !_vm.isInsideInfoCarousel && !_vm.useZoomViewer)?_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"blur-overlay",style:({
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}),on:{"click":_vm.onClick}}):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }