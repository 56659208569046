
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Runnit Page Graphic',
		},
		width: {
			type: Number,
			default: 36,
		},
		bevel: {
			type: Number,
			default: 4,
		},
		darkFill: {
			type: String,
			default: 'rgb(44, 44, 44)',
		},
		lightFill: {
			type: String,
			default: '#4d4d4d',
		},
	},
	computed: {
		svgHeight () {
			return this.spacer + this.shortRectangleHeight + this.spacer + this.squareLength + this.spacer + this.squareLength + this.spacer;
		},
		svgViewBox () {
			return `0 0 ${this.width} ${this.svgHeight}`;
		},
		spacer () {
			return this.width * 0.042;
		},
		shortRectangleHeight () {
			return this.squareLength / 4;
		},
		squareLength () {
			return this.width * 0.6;
		},
		tallRectangleHeight () {
			return (2 * this.squareLength) + this.spacer;
		},
		tallRectangleWidth () {
			return this.squareLength / 2;
		}
	}
});
