import { render, staticRenderFns } from "./SelectedColumnsButton.vue?vue&type=template&id=30e2b12c&scoped=true"
import script from "./SelectedColumnsButton.vue?vue&type=script&lang=ts"
export * from "./SelectedColumnsButton.vue?vue&type=script&lang=ts"
import style0 from "./SelectedColumnsButton.vue?vue&type=style&index=0&id=30e2b12c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e2b12c",
  null
  
)

export default component.exports