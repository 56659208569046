
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import RunnitMarkColorLogo from '@/assets/RunnitMarkColorLogo.vue';

export default Vue.extend({
	name: 'SessionInfoCard',
	props: {
		showLogo: { type: Boolean, default: false },
		showApp: { type: Boolean, default: false },
		showHardware: { type: Boolean, default: false },
		showSoftware: { type: Boolean, default: false },
		showType: { type: Boolean, default: false },
		showTeam: { type: Boolean, default: false },
		showWorkspace: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		showMultiSessionsBtn: { type: Boolean, default: false },
		typeFontSize: { type: String, default: '28px' },
		appFontSize: { type: String, default: '28px' },
		hardwareFontSize: { type: String, default: '20px' },
		softwareFontSize: { type: String, default: '20px' },
		teamFontSize: { type: String, default: '20px' },
		workspaceFontSize: { type: String, default: '20px' },
		xSmallIcons: { type: Boolean, default: false },
		borderRadius: { type: String, default: 'var(--global-border-radius)' },
		minWidth: { type: String, default: null },
	},
	computed: {
		...mapState([
			'toolbar',
			'appOffersMap',
			'hardwareOffersMap',
			'softwareOffersMap',
		]),
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		onMultiSessionClick () {
			this.updateToolbar({
				settingsOpen: !this.toolbar.settingsOpen,
				mobileMenuOpen: false,
			});
		},
	},
	components: {
		LoadingSVG,
		RunnitMarkColorLogo,
	},
});
