import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"virtualScrollWrapper",staticClass:"virtual-scroll-wrapper",style:({ 'background-color': _vm.backgroundColor, 'height': _vm.height })},[(_vm.initializing)?_c('div',{staticClass:"d-flex justify-center align-center full-width full-height",staticStyle:{"flex-direction":"column","gap":"12px"}},[_c(VProgressCircular,{staticStyle:{"color":"var(--grey-600)"},attrs:{"indeterminate":"","size":"50"}}),_c('div',{staticStyle:{"color":"var(--grey-600)","font-size":"18px"}},[_vm._v(" Practicing mathematics... ")])],1):_c('div',{ref:"virtualScrollContainer",staticClass:"virtual-scroll-container",on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"virtual-scroll-spacer",style:({ height: _vm.totalHeight + 'px' })}),_vm._l((_vm.visibleRows),function(row){return _c('div',{key:row.id,staticClass:"virtual-scroll-row",style:({
			position: 'absolute',
			top: row.top + 'px',
			left: '0',
			right: '0',
			'grid-template-columns': `repeat(${_vm.numColumns}, 1fr)`,
			'gap': `${_vm.gap}px`,
			height: `${row.size}px`
		})},_vm._l((row.results),function(result){return _c('div',{key:result.uuid,staticClass:"virtual-scroll-item",style:({
				'max-height': `${row.size}px`,
			})},[(result.isPlaceholder)?_c('runnit-result',{attrs:{"is-loading-placeholder":""}}):_c('runnit-result',{attrs:{"node-run-result":result,"node-run-id":result.nodeRunId,"node-def":_vm.runnitNodeRunsById[result.nodeRunId].nodeDef,"node-run":_vm.runnitNodeRunsById[result.nodeRunId],"is-nsfw-placeholder":result.isNsfw,"max-height":`${row.size}px`,"draggable":_vm.draggable},on:{"on-click":function($event){return _vm.onNodeRunResultClick(_vm.runnitNodeRunsById[result.nodeRunId], result)},"on-single-select":_vm.onSingleSelect,"on-multi-select":_vm.onMultiSelect}})],1)}),0)})],2),(_vm.hasMoreResults)?_c(VBtn,{staticClass:"load-more-container",attrs:{"loading":_vm.isLoadingMore || _vm.initializing},on:{"click":_vm.loadNextPage}},[_vm._v(" Load More ")]):_vm._e(),(!_vm.disableCarousel)?_c('runnit-image-info-carousel-dialog',{attrs:{"value":_vm.imageInfoCarouselConfig.dialogOpen,"node-run":_vm.imageInfoCarouselConfig.nodeRun,"node-runs":_vm.runnitNodeRuns,"node-run-result":_vm.imageInfoCarouselConfig.nodeRunResult,"result-filter":_vm.resultFilter},on:{"on-prev-or-next-result":_vm.handleCarouselStep,"input":function($event){_vm.imageInfoCarouselConfig.dialogOpen = $event},"is-public-updated":_vm.handleIsPublicUpdated}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }