
import Vue from 'vue';
import moment from 'moment-timezone';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { MasterConfigWebNewVersion } from '@run-diffusion/shared';
import { db } from '@/firebase';
import { Route } from 'vue-router/types/router';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'VersionTracker',
	components: {
		ConfirmDialog,
	},
	data() {
		return {
			initialForceRefreshAtMoment: null,
			showDialog: false,
            webNewVersion: null,
		};
	},
	created() {
		this.$bind('webNewVersion', db.collection('masterConfigs').doc('webNewVersion'));
	},
	watch: {
		'$route' (newVal: Route, oldVal: Route) {
			if (newVal !== oldVal) {
                this.onWebNewVersionChange(this.webNewVersion, newVal);
			}
		},
		webNewVersion: {
			immediate: true,
			handler (newVal: MasterConfigWebNewVersion, oldVal: MasterConfigWebNewVersion) {
				// Store the initial timestamp when component is first mounted
				if (newVal && newVal.forceRefreshAt && !this.initialForceRefreshAtMoment) {
					this.initialForceRefreshAtMoment = moment(newVal.forceRefreshAt.toMillis());
					return;
				}
				if (newVal !== oldVal) {
					this.onWebNewVersionChange(newVal, this.$route);
				}
			},
		},
	},
	methods: {
        onWebNewVersionChange(webNewVersion: MasterConfigWebNewVersion, route: Route) {
            if (
				webNewVersion &&
				webNewVersion.forceRefreshAt &&
				this.initialForceRefreshAtMoment &&
				route.name !== ROUTER.LAUNCH &&
				this.initialForceRefreshAtMoment.isBefore(webNewVersion.forceRefreshAt.toMillis())
			) {
				this.showDialog = true;
            }
        },
		refreshApp() {
			window.location.reload();
		},
	},
});
