
import Vue from 'vue';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@run-diffusion/shared';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import RunnitImageTimer from "@/views/Runnits/RunnitImageTimer.vue";
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'OfferingCard',
	props: {
		disabled: { type: Boolean, default: false },
		selectIcon: { type: String, default: 'mdi-check-circle-outline' },
		selectText: { type: String, default: 'Select' },
		showEditBtn: { type: Boolean, default: false },
		editText: { type: String, default: 'Edit' },
		disabledText: { type: String, default: null },
		disabledCtaText: { type: String, default: null },
		disabledCtaProps: { type: Object, default: () => ({}) },
		title: { type: String, default: '' },
		imageUrl: { type: String, default: '' },
		avatar: { type: [Object, String], default: null },
		description: { type: String, default: '' },
		tags: { type: Array, default: () => [] },
		displayOnly: { type: Boolean, default: false },
		horizontal: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		toolType: { type: String, required: false },
		isFeatured: { type: Boolean, required: false },
		noClick: { type: Boolean, default: false }, // pass through to the runnit-image noClick
		fullImage: { type: Boolean, default: false },
		persistentFooter: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		loadingProgress: { type: Number, default: 0 },
		loadAt: { type: Number, default: Date.now() },
		imageOnly: { type: Boolean, default: false },
		imageHeightWidth: { type: Number, default: null }, // Explicit control of image dimensions
		loadingMessage: { type: String, default: null },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
		};
	},
	filters: {
		truncate (value: string, options: { length: number }) {
			if (!value) return '';
			const length = options.length || 30;
			if (value.length <= length) return value;
			return value.substring(0, length) + '...';
		}
	},
	computed: {
		vertical () {
			return !this.horizontal && !this.fullImage;
		},
		computedContainerWidth () {
			let width: number = 300;

			if (this.imageOnly) {
				return this.computedImageHeightWidth;
			}

			if (this.vertical) {
				width = 300

				if (this.small) {
					width = 190;
				}
			}

			if (this.horizontal) {
				width = 460

				if (this.small) {
					width = 320;
				}
			}

			if (this.fullImage) {
				width = 256;
			}

			return width;
		},
		computedContainerHeight () {
			let height: number = 415;

			if (this.imageOnly) {
				return this.computedImageHeightWidth;
			}

			if (this.vertical) {
				height = 415

				if (this.small) {
					height = 300;
				}
			}

			if (this.horizontal) {
				height = 250

				if (this.small) {
					height = 150;
				}
			}

			if (this.fullImage) {
				height = 256;
			}

			return height;
		},
		computedImageHeightWidth (): string {
			// If imageHeightWidth is provided, use it
			if (this.imageHeightWidth) {
				return `${this.imageHeightWidth}`;
			}

			let heightWidth: number = 260;

			if (this.vertical) {
				heightWidth = 260

				if (this.small) {
					heightWidth = 150;
				}
			}

			if (this.horizontal) {
				heightWidth = 200

				if (this.small) {
					heightWidth = 120;
				}
			}

			if (this.fullImage) {
				heightWidth = 256;
			}

			return `${heightWidth}`;
		},
		computedDescriptionCharLimit () {
			const baseLimit = this.small ? 77 : 70;
			if (this.title && this.title.length > 18) {
				// Reduce character limit if title is long
				return baseLimit - 15; // Reduce by 15 characters
			}
			return baseLimit;
		},
		offeringCardStyles () {
			if (this.imageOnly) {
				return {
					background: 'transparent',
					boxShadow: 'none',
				};
			}

			let styles = {
				background: 'linear-gradient(180deg, var(--grey-825), var(--grey-835))',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.background = 'var(--blue-grey-800)';
				styles['backgroundImage'] = 'radial-gradient(circle at -30% 0, var(--blue-grey-500), rgba(255, 255, 255, 0) 70%)';
			}

			return styles;
		},
		descriptionStyles () {
			const styles = {
				color: 'rgba(255, 255, 255, 0.6)',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.color = 'var(--blue-grey-200)';
			}

			return styles;
		},
	},
	components: {
		RunnitImageTimer,
		RunnitImage,
		ListItem,
	},
});
