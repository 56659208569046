
import Vue from 'vue';
import _sampleSize from 'lodash/sampleSize';
import _remove from 'lodash/remove';
import { localPrompts } from '@/views/Runnits/utils/localData';
import { extractPromptStringValue, PROMPT_MAGIC_MODE } from '@run-diffusion/shared';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import PromptMagicButton from '@/views/Runnits/RunnitSettings/PromptMagicButton.vue';

export default Vue.extend({
	name: 'Prompt',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [String, Object], default: null },
		adminOnly: { type: Boolean, default: false },
		textareaOnly: { type: Boolean, default: false },
	},
	data () {
		return {
			loadingPrompt: false,
		};
	},
	computed: {
		promptStringValue(): string {
			return extractPromptStringValue(this.value);
		},
	},
	methods: {
		onPromptStringValueInput (value: string) {
			this.$emit('input', {
				value: value || null,
				magicMode: this.value?.magicMode || PROMPT_MAGIC_MODE.AUTO,
			});
		},
		handleGenerateRandomPrompt () {
			this.loadingPrompt = true;

			const randomPromptsArray: string[] = [...localPrompts];
			if (this.promptStringValue) _remove(randomPromptsArray, (pr: string) => pr === this.promptStringValue); // Remove last prompt
			const randomPrompt: string = _sampleSize(randomPromptsArray, 1)[0];

			setTimeout(() => {
				this.onPromptStringValueInput(randomPrompt);
				this.loadingPrompt = false;
			}, 150);
		},
	},
	components: {
		RunnitSettingHelperBtn,
		RunnitDynamicFieldLabelRow,
		PromptMagicButton,
	},
});
