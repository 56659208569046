import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"version-selector"},[_c('menu-or-bottom-sheet',{attrs:{"min-width":220,"bottom-sheet-on-small":""},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"version-selector-btn",attrs:{"text":"","outlined":"","color":"white"}},on),[_c('span',[_vm._v(_vm._s(_vm.selectedVersion))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}},{key:"content",fn:function({ close }){return [_c(VCard,{staticClass:"version-selector-menu"},[_c(VList,_vm._l((_vm.versions),function(version,index){return _c(VListItem,{key:index,class:{ 'version-active': index === _vm.selectedVersionIndex },on:{"click":function($event){return _vm.selectVersion(index, close)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.formatDate(version.date))+" ")])],1)}),1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }