import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('runnit-dialog',{attrs:{"title":"Models","icon":"mdi-image-multiple-outline","value":_vm.value,"multi-line-header":"","body-height":"70vh","body-padding":"0","max-width":_vm.maxWidth,"persistent":_vm.persistent},on:{"input":_vm.setOpen,"click:outside":function($event){return _vm.setOpen(false)}},scopedSlots:_vm._u([{key:"header-nav",fn:function(){return [_c('runnit-nav-tabs',{attrs:{"tabs":_vm.modelsNavTabs,"curr-tab-id":_vm.currNavTab,"initial-active-tab-id":_vm.RUNNITS_ACCESS_LEVEL.PRIVATE,"tabs-height":_vm.$vuetify.breakpoint.xsOnly ? '53px' : undefined,"icon-size":_vm.$vuetify.breakpoint.xsOnly ? '18px' : '15px',"active-background-color":'var(--grey-950)'},on:{"on-curr-tab-id-input":_vm.changeNavTab}})]},proxy:true}])},[_c('div',{staticClass:"model-library-outer"},[_c('div',{staticClass:"model-library",class:{
					'model-library-horizontal': _vm.$vuetify.breakpoint.mdAndUp,
					'model-library-vertical': _vm.$vuetify.breakpoint.smAndDown,
				}},[_c('div',{staticClass:"model-library-items-section"},[(_vm.loadingModels)?_c('div',{staticClass:"full-width"},[_c('loading-state')],1):(!_vm.loadingModels && !_vm.filteredModels.length)?_c('div',{staticClass:"full-width",staticStyle:{"overflow":"hidden"}},[_c('empty-state',{attrs:{"icon":"mdi-image-multiple-outline","message":"No models found"}},[[_vm._v(" Go create or upload a model to use in your tool. "),_c('div',{staticClass:"d-flex justify-center align-center"},[_c(VBtn,{staticClass:"mt-6",attrs:{"color":"primary"},on:{"click":_vm.goToModelsPage}},[_vm._v(" Create Model ")])],1)]],2)],1):_c('div',[_c('transition-group',{staticClass:"transition-group-flex-container",staticStyle:{"gap":"28px"},attrs:{"name":"slide-fade","tag":"div"}},[_vm._l((_vm.filteredModels),function(model){return [_c('offering-card',{key:model.id,attrs:{"title":model.name,"avatar":model.avatar,"description":model.description,"tags":Object.keys(model.tags || {})
										.filter(tag => _vm.publicRunnitNodeDefTagsMap[tag].type === _vm.RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE)
										.map(tag => _vm.TRAINING_TYPE_CARDS[tag].title),"select-text":"Use model in tool","show-edit-btn":"","edit-text":"Configure settings or delete Model","full-image":"","persistent-footer":""},on:{"click":function($event){return _vm.onModelSelect(model)},"edit-click":function($event){return _vm.onEditModelSettings(model)}}})]})],2)],1)]),_c('div',{staticClass:"model-library-filter-section"},[_c('div',{staticStyle:{"display":"flex","gap":"12px"}},[_c(VTextField,{attrs:{"value":_vm.modelSearchValue,"label":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify","background-color":"var(--grey-950)","filled":"","rounded":"","dense":"","hide-details":"auto"},on:{"input":_vm.handleSearchInput}}),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticStyle:{"height":"40px","display":"flex","justify-content":"center","align-items":"center"},on:{"click":_vm.toggleFilterCollapse}},[_c(VBtn,{attrs:{"icon":"","color":!_vm.filtersCollapsed ? 'primary' : 'default'}},[_c(VIcon,[_vm._v("mdi-filter-variant")])],1)],1):_vm._e()],1),_c(VSlideYTransition,[(!_vm.filtersCollapsed || _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-container-title"},[_c(VIcon,{staticStyle:{"font-size":"15px","margin":"0 5px 0 0"}},[_vm._v("mdi-shape")]),_vm._v(" Category ")],1),_c('div',{staticClass:"filter-container-description"},[_vm._v(" Select which category you want to filter by to browse ")]),_c('model-tag-type-select',{attrs:{"selected-types":_vm.selectedTypes},on:{"on-select":_vm.handleMultiSelectFilter,"is-all-selected":function($event){_vm.isAllTypesSelected = $event}}})],1)]):_vm._e()])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }