import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('runnit-dialog',{attrs:{"value":_vm.open,"max-width":"800","title":"What is a Runnit Board?","icon":"mdi-help-circle-outline","body-padding":"0","body-height":"auto"},on:{"input":_vm.setOpen},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-center pb-10 px-10",style:({
      'flex-direction': _vm.$vuetify.breakpoint.xsOnly ? 'column' : 'row',
      'align-items': _vm.$vuetify.breakpoint.xsOnly ? 'center' : 'flex-start'
    })},[_c('base-button',{staticStyle:{"padding":"10px 30px"},attrs:{"href":"https://learn.rundiffusion.com/runnit-board/","target":"_blank","rel":"noopener noreferrer","super-primary":""}},[(_vm.$vuetify.breakpoint.xsOnly)?[_vm._v("Learn More")]:[_vm._v("Learn More about Runnit Boards")]],2)],1)]},proxy:true}])},[_c(VCardText,{style:({
          padding: _vm.$vuetify.breakpoint.xsOnly ? '25px' : '50px'
      })},[_c('p',{staticClass:"text-subtitle-1 grey--text text--lighten-1 pb-5"},[_vm._v(" Let's get started with Runnit Boards, our AI-powered creative platform exclusively on Runnit.ai. Whether you're a business professional, a hobbyist, or a seasoned creative, you'll find resources and inspiration to help you unlock the full potential of AI for your image creation needs from individual generative tools to entire workflows a Runnit Board is your solution. ")]),_c('h3',{staticClass:"text-h5 font-weight-medium mt-6 mb-4"},[_vm._v("Why Use Runnit Boards?")]),_c('div',{staticClass:"d-flex flex-column"},[_c('list-item',{staticClass:"mb-4",attrs:{"icon":"mdi-flask","icon-color":"white"}},[_c('div',{staticClass:"list-title"},[_vm._v("Experiment and iterate")]),_c('div',{staticClass:"list-subtitle"},[_vm._v(" Easily add multiple instances of the same tool with different settings to A/B test prompts and parameters. This helps you quickly discover what works best. ")])]),_c('list-item',{staticClass:"mb-4",attrs:{"icon":"mdi-folder","icon-color":"white"}},[_c('div',{staticClass:"list-title"},[_vm._v("Stay organized")]),_c('div',{staticClass:"list-subtitle"},[_vm._v(" Create separate Boards for different projects or tasks. This keeps your workspace tidy and focused. ")])]),_c('list-item',{attrs:{"icon":"mdi-source-branch","icon-color":"white"}},[_c('div',{staticClass:"list-title"},[_vm._v("Streamline your workflow")]),_c('div',{staticClass:"list-subtitle"},[_vm._v(" Arrange tools in a top-to-bottom flow to create a seamless creative process. For example, start with an idea, generate an image, inpaint unwanted details, upscale for higher resolution, and finally, turn it into a video. ")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }