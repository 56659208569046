
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import WindowStepper from '@/components/WindowStepper.vue';
import RunnitsSubscriptionCard from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitsSubscriptionCard.vue';
import RunnitsSubscriptionCardComingSoon from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitsSubscriptionCardComingSoon.vue';
import {
	CHECKOUT_USER_SUBSCRIPTION,
	ClubOffer,
} from '@run-diffusion/shared';
import _clamp from 'lodash/clamp';
import _omit from 'lodash/omit';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import IsMonthlySwitch from '@/views/Runnits/pages/RunnitsSubscriptionsPage/IsMonthlySwitch.vue';
import TokenCalculationsTable from '@/views/Runnits/pages/RunnitsSubscriptionsPage/TokenCalculationsTable.vue';
import PersonalSubscriptionManagement
	from '@/views/Runnits/pages/RunnitsSubscriptionsPage/PersonalSubscriptionManagement.vue';
import RunnitsFaqs from '@/views/Runnits/base/RunnitsFaqs.vue';
import RunnitsWithRDHorizontalLogoSVG from '@/assets/RunnitsWithRDHorizontalLogoSVG.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'RunnitsSubscriptionsPage',
	data () {
		return {
			isMonthly: false,
			carouselModel: 0,
			goToManageSubscriptionDialogOpen: false,
			selectedTokenClubAddOnsMap: {}, // Map from ClubKey to TokenClubAddOn obj
			selectedLimitClubAddOnsMap: {}, // Map from ClubKey to LimitClubAddOn obj
		};
	},
	created () {
		this.bindClubOffers();
	},
	computed: {
		...mapState([
			'authUser',
			'user',
			'clubOffers',
			'loadingClubOffers',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		isLoading() {
			return this.loadingClubOffers === null || this.loadingClubOffers;
		},
		hasActiveNonFreemiumSubscription() {
			return !!(
				this.user &&
				this.user.clubOffer &&
				typeof this.user.clubOffer !== 'string' &&
				!this.user.clubOffer.isFreemium
			);
		},
		showCcOptions () {
			return !!this.$route.query.showCcOptions;
		},
		isCc() {
			return !!(this.clubOfferBenefits && this.clubOfferBenefits.isCc);
		},
		visibleClubOffers () {
			return (this.clubOffers || []).filter((clubOffer: ClubOffer) => {
				return !!(
					this.isCc || // Their current subscription is an `isCc` subscription
					this.showCcOptions ||
					!clubOffer.benefits.isCc
				);
			});
		},
		carouselLength () {
			return (
				this.visibleClubOffers.length +
				1 // Enterprise coming soon selection
				// 1 Team coming soon selection
			);
		},
	},
	methods: {
		...mapActions([
			'bindClubOffers',
			'goToStripeSubscriptionPortal',
		]),
		goToLogin () {
			this.routerPush(this.$route, this.$router, {
				name: ROUTER.LOGIN,
				query: {
					...this.$route.query,
					redirect: this.$route.path,
				},
			});
		},
		onChangeShowCcOptions (val: boolean) {
			this.routerReplace(this.$route, this.$router, {
				name: this.$route.name,
				params: this.$route.params,
				query: {
					..._omit(this.$route.query, 'showCcOptions'),
					...(val && {
						showCcOptions: '1',
					}),
				},
			});
		},
		handleCtaClick ({ clubOffer, tokenClubAddOn, limitClubAddOn }) {
			const routeDataGoToPayment = this.$router.resolve({
				name: 'GoToPayment',
				query: {
					checkoutAction: CHECKOUT_USER_SUBSCRIPTION,
					club: clubOffer.club,
					...(!this.isMonthly && {
						annual: '1',
					}),
					tokenClubAddOnId: tokenClubAddOn.id,
					limitClubAddOnId: limitClubAddOn.id,
				},
			});

			if (!this.user && clubOffer.isFreemium) {
				// Unauthenticated user select free plan
				this.routerPush(this.$route, this.$router, {
					name: ROUTER.SIGNUP,
					query: this.$route.query,
				});
			} else if (this.user && clubOffer.isFreemium) {
				// Logged-in user select free plan
				this.goToManageSubscriptionDialogOpen = true;
			} else if (!this.user && !clubOffer.isFreemium) {
				// Unauthenticated user select paid plan
				const routeDataSignup = this.$router.resolve({
					name: ROUTER.SIGNUP,
					query: {
						...this.$route.query,
						redirect: routeDataGoToPayment.href,
					},
				});
				window.open(routeDataSignup.href, '_blank');
			} else if (this.user && !clubOffer.isFreemium) {
				// Logged-in user select paid plan
				window.open(routeDataGoToPayment.href, '_blank');
			}
		},
		handleStepClick (stepIndex: number) {
			this.carouselModel = stepIndex;
		},
		prevSlide () {
			if (this.carouselModel > 0) this.carouselModel--;
			this.carouselModel = _clamp(this.carouselModel, 0, this.carouselLength - 1);
		},
		nextSlide () {
			if (this.carouselModel < this.carouselLength - 1) this.carouselModel++;
			this.carouselModel = _clamp(this.carouselModel, 0, this.carouselLength - 1);
		},
	},
	components: {
		RunnitsWithRDHorizontalLogoSVG,
		RunnitsFaqs,
		PersonalSubscriptionManagement,
		TokenCalculationsTable,
		IsMonthlySwitch,
		ConfirmDialog,
		WindowStepper,
		RunnitsSubscriptionCard,
		RunnitsSubscriptionCardComingSoon,
	},
});
