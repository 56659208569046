// src/plugins/vuetify-tooltip-override.ts
import Vue, { VueConstructor } from 'vue';
import Vuetify from 'vuetify/lib';

interface InstallOptions {
  vuetify: Vuetify
}

export default {
  install(Vue: VueConstructor, options: InstallOptions): void {
    const vuetify = options.vuetify;

    // Function to check if we're on mobile
    const isMobileDevice = () => {
      return vuetify.framework.breakpoint.smAndDown;
    };

    // Add a simple CSS rule to disable tooltips on mobile by default
    const addGlobalMobileTooltipStyle = () => {
      if (!document.getElementById('mobile-tooltip-disabler')) {
        const style = document.createElement('style');
        style.id = 'mobile-tooltip-disabler';
        style.innerHTML = `
          @media (max-width: 960px) {
            .v-tooltip__content {
              display: none !important;
            }

            .v-tooltip__content.mobile-enabled {
              display: inherit !important;
            }
          }
        `;
        document.head.appendChild(style);
      }
    };

    // Add the global style
    addGlobalMobileTooltipStyle();

    // Patch the v-tooltip component
    const VTooltipComponent = Vue.component('v-tooltip');

    if (VTooltipComponent) {
      Vue.component('v-tooltip', {
        extends: VTooltipComponent,

        props: {
          // Support both camelCase and kebab-case
          onMobile: {
            type: Boolean,
            default: false
          },
          "on-mobile": {
            type: Boolean,
            default: false
          }
        },

        computed: {
          // Check if mobile is allowed
          allowOnMobile() {
            return this.onMobile || this['on-mobile'];
          }
        },

        methods: {
          // Override the activate method to prevent activation on mobile
          // This is where Vuetify decides whether to show the tooltip
          activate() {
            if (isMobileDevice() && !this.allowOnMobile) {
              // On mobile without on-mobile prop, don't activate
              return;
            }

            // Call the original activate method
            const activate = (this as any).$options.extends.options.methods.activate;
            if (activate) {
              activate.call(this);
            }
          }
        },

        // Add watchers to update tooltip content CSS class
        watch: {
          isActive(val) {
            // When the tooltip becomes active, add the class if needed
            this.$nextTick(() => {
              if (this.$refs.content && this.$refs.content.$el) {
                const el = this.$refs.content.$el;

                if (this.allowOnMobile) {
                  el.classList.add('mobile-enabled');
                }
              }
            });
          }
        },

        // Make sure to set the class on mount too
        mounted() {
          if (this.allowOnMobile) {
            this.$nextTick(() => {
              if (this.$refs.content && this.$refs.content.$el) {
                this.$refs.content.$el.classList.add('mobile-enabled');
              }
            });
          }
        },

        // Update when props change
        updated() {
          if (this.$refs.content && this.$refs.content.$el) {
            if (this.allowOnMobile) {
              this.$refs.content.$el.classList.add('mobile-enabled');
            } else {
              this.$refs.content.$el.classList.remove('mobile-enabled');
            }
          }
        }
      });
    }
  }
};
