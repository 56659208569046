
import Vue from 'vue';
import RunnitCarousel from './RunnitCarousel.vue';
import { RUNNIT_TYPE, RunnitNodeDef } from '@run-diffusion/shared';
import { ROUTER } from '@/router/constants';
import { RunnitsCRUDMixin } from '@/mixins';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'RunnitIntentionsBar',
	mixins: [
		RunnitsCRUDMixin,
	],
	data () {
		return {
		}
	},
	computed: {
		...mapState([
			'user',
		]),
		intentions () {
			const intentions: {
				label: string,
				imgUrl: string,
				description: string,
				nodeDef?: Partial<RunnitNodeDef>,
				routeTo?: string
			}[] = [
					{
						label: 'Image',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FImage-Intention-003.png?alt=media&token=45674377-0a59-4264-8f4d-1fe2ba90c4c7',
						description: 'Generate an image using either a text prompt or another image as input.',
						nodeDef: {
							id: '2t5JQGvAIiSr669KYnVq',
							title: 'Juggernaut Lightning Flux',
						} as RunnitNodeDef
					},
					{
						label: 'Video',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FVideo-Intention-002.jpg?alt=media&token=c4d779e1-d923-4700-84a9-1e47b1b79770',
						description: 'Create a short video from an existing image.',
						nodeDef: {
							id: 'cDsoqEOxLLOXlhZIpoEb',
							title: 'Kling 1.6 [Pro]'
						} as RunnitNodeDef
					},
					{
						label: 'Vector',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FVector-Intention-003.png?alt=media&token=b34f83a2-eb19-4b8f-bb98-3d3affcc857e',
						description: 'Create a vector graphic using a text prompt for scalability',
						nodeDef: {
							id: 'nGL2wB5LEnmWBW72zk3K',
							title: 'SVG Generator'
						} as RunnitNodeDef
					},
					{
						label: 'Train',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FTrain-Intention-002.png?alt=media&token=fe738f8e-6cd6-4b39-88d4-3c749d37a7f7',
						description: 'Train a custom AI model for use in other tools',
						routeTo: ROUTER.RUNNITS_MODELS_LIST,
					},
					{
						label: 'Remove Background',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FRemove-Background-Intention-002.png?alt=media&token=86dddd4e-eae0-4e7e-8a3e-80bf6c003f03',
						description: 'Automatically remove the background of an image',
						nodeDef: {
							id: 'w4WjiaPJ8YlnXqalLseS',
							title: 'Remove Background'
						} as RunnitNodeDef
					},
					// This is only a team tool
					// {
					// 	label: 'Sketch to Render',
					// 	imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fusers%2FHsJOMJvCLoYAlVJdXseqbncEX702%2Fruns%2FgXaLPG5Bcaywr0UItKez%2F08adb480-a5c4-43e0-b766-0dc9954b8b65.png?alt=media&token=96333f2d-ffae-499f-86da-831147e1ef9a',
					// 	description: 'Converts a sketch into a rendered image',
					// 	nodeDef: {
					// 		id: '02hQcm7FuK7TMrJ8C2Mk',
					// 		title: 'Kling 1.6 [Standard]'
					// 	} as RunnitNodeDef
					// },
					{
						label: 'Enhance',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FEnhance-Intention-002.png?alt=media&token=e1137b6e-cc4c-4b88-9279-ffd26c4c6f64',
						description: 'Improve the quality and resolution of an image',
						nodeDef: {
							id: 'QejUMwHU49HRMWwpqhkD',
							title: 'AuraSR - True Upscaler'
						} as RunnitNodeDef
					},
					{
						label: 'Reimagine',
						imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FReimagine-Intention-002.png?alt=media&token=2da92ea6-0633-4d9a-8bb0-066665edbde7',
						description: 'Expand an existing image with AI-generated elements',
						nodeDef: {
							id: 'n4H8sQlLxTc1aeYJxdiv',
							title: 'Creative Upscaler'
						} as RunnitNodeDef
					},
					// {
					// 	label: 'Workflow',
					// 	imgUrl: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fintentions-bar%2FWorkflow-Intention-002.png?alt=media&token=e95ccb72-e310-473f-92bd-365d54beb6f6',
					// 	description: 'Create a template to automate a sequence of tasks',
					// 	routeTo: [PUT ROUTE HERE],
					// },
				];
			return intentions;
		}
	},
	methods: {
		onIntentionClick (intention) {
			if (intention.nodeDef) {
				this.onAddRunnitClick(intention.nodeDef, RUNNIT_TYPE.SINGLE_TOOL);
				return;
			}
			if (intention.routeTo) {
				this.routerPush(this.$route, this.$router, { name: intention.routeTo });
				return;
			}
		},
	},
	components: {
		RunnitCarousel,
	}
});
