
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import RunnitsInfoDialog from '@/views/Runnits/RunnitsInfoDialog.vue';
import { User } from '@run-diffusion/shared';
import { ROUTER } from '@/router/constants';
import {
	FREE_PLAN_LIMIT_CLUB_ADD_ON_NUM_NODES_LIMIT,
	FREE_PLAN_LIMIT_CLUB_ADD_ON_NUM_RUNNITS_LIMIT,
} from '@/views/Runnits/constants';
import MyRunnitsPlanDetailsDialog from '@/components/UpsellRunnitsPlan/MyRunnitsPlanDetailsDialog.vue';

export default Vue.extend({
	name: 'UpsellRunnitsPlanDialog',
	data () {
		return {
			FREE_PLAN_LIMIT_CLUB_ADD_ON_NUM_NODES_LIMIT,
			FREE_PLAN_LIMIT_CLUB_ADD_ON_NUM_RUNNITS_LIMIT,
		};
	},
	computed: {
		...mapState([
			'upsellDialog',
			'user',
			'runnits',
			'runnitNodes',
		]),
		runnitsIsNumNodesLimitWithUnlimitedRunnitsAddOn () {
			return !!(
				this.upsellDialog.runnitsIsNumNodesLimit &&
				this._get(this.user, 'clubInfo.limit.unlimitedRunnits')
			);
		},
		showCancel () {
			return this.runnitsIsNumNodesLimitWithUnlimitedRunnitsAddOn;
		},
		showConfirm () {
			return !this.runnitsIsNumNodesLimitWithUnlimitedRunnitsAddOn;
		},
		myRunnitsPlanDetailsDialogOpen () {
			return !!this._get(this.upsellDialog, 'runnitsPlanDetailsData.open');
		},
		defaultDialogOpen () {
			return !!(
				!this.myRunnitsPlanDetailsDialogOpen &&
				(
					this.upsellDialog.runnitsIsAtNumRunnitsLimit ||
					this.upsellDialog.runnitsIsNumNodesLimit
				)
			);
		},
		title () {
			if (this.upsellDialog.runnitsIsAtNumRunnitsLimit) {
				return `Your Plan's Limit on Runnit Boards is Reached`;
			} else if (this.upsellDialog.runnitsIsNumNodesLimit) {
				return `Your Plan's Limit on Tools per Runnit Board is Reached`;
			} else if (this.myRunnitsPlanDetailsDialogOpen) {
				return `My Runnit Plan Details`;
			}
			return null;
		},
	},
	watch: {
		user: {
			immediate: true,
			handler (newVal: User, oldVal: User) {
				if (
					newVal !== oldVal &&
					(
						this.upsellDialog.runnitsIsAtNumRunnitsLimit ||
						this.upsellDialog.runnitsIsNumNodesLimit
					) &&
					this._get(newVal, 'clubInfo.token.unlimitedRunnits')
				) {
					this.dialogsInput(false);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
		dialogsInput (val: boolean) {
			if (!val) {
				this.setUpsellDialog({});
			}
		},
		onUpgrade () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_SUBSCRIPTIONS });
			this.dialogsInput(false);
		},
	},
	components: {
		MyRunnitsPlanDetailsDialog,
		RunnitsInfoDialog,
	},
});
