
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { Runnit, RUNNIT_NODE_DEF_TOOL_APP_TYPE, RUNNIT_TYPE } from '@run-diffusion/shared';
import RunnitPageSettings from '@/views/Runnits/RunnitPageSettings.vue';
import ImageGalleryDrawer from '@/components/ImageGallery/ImageGalleryDrawer.vue';
import ComplexBackgroundHeader from '@/components/designElements/ComplexBackgroundHeader.vue';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import pluralize from 'pluralize';
import _isEmpty from 'lodash/isEmpty';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import GreyButton from '@/components/base/GreyButton.vue';
import { RUNNITS_OWNER_SELECTION } from './constants';
import RunnitsLogoMarkSVG from '@/assets/RunnitsLogoMarkSVG.vue';
import RunnitsWithRDHorizontalLogoSVG from '@/assets/RunnitsWithRDHorizontalLogoSVG.vue';
import RunnitTitleBar from '@/views/Runnits/RunnitTitleBar.vue';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import AccountMenuBtn from '@/components/Toolbar/AccountMenuBtn.vue';

export default Vue.extend({
	name: 'RunnitsApp',
	mixins: [
		RunnitsImageSelectMixin,
		RunnitBulkActionsMixin,
	],
	data () {
		return {
			RUNNIT_TYPE,

			deleteImagesDialog: {
				open: false,
				deleting: false,
			},
		};
	},
	created () {
		this.updateToolbar({
			insideRunnits: true,
		});

		// Set initial history length when entering the app
		this.updateRunnitState({
			initialHistoryLength: window.history.length
		});
	},
	watch: {
		'runnitState.runnitsOwnerSelection': {
			immediate: true,
			async handler (newVal, oldVal) {
				if (oldVal && newVal !== oldVal) {
					this.routeAwayDueToRunnitOwnershipIssue();
				}
			}
		},
		runnitsLimitMap: {
			immediate: true,
			async handler (newVal: Record<string, Runnit>, oldVal: Record<string, Runnit>) {
				if (newVal !== oldVal && this.isAbovePlanRunnitsLimit(this.runnitState.runnit, newVal)) {
					this.routeAwayDueToRunnitsLimit();
				}
			},
		},
		'runnitState.runnit': {
			immediate: true,
			async handler (newVal: Runnit, oldVal: Runnit) {
				if (newVal !== oldVal && this.isAbovePlanRunnitsLimit(newVal, this.runnitsLimitMap)) {
					this.routeAwayDueToRunnitsLimit();
				}

				// Runnit doesn't belong to the current Team
				const ownerSelection = this.runnitState.runnitsOwnerSelection;
				if (
					newVal &&
					oldVal &&
					(
						ownerSelection &&
						(
							ownerSelection === RUNNITS_OWNER_SELECTION.USER &&
							newVal.teamId !== null
						) ||
						(
							newVal.type === RUNNIT_TYPE.WORKFLOW &&
							ownerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
							this.team &&
							this.team.id !== newVal.teamId
						)
					)
				) {
					this.routeAwayDueToRunnitOwnershipIssue();
				}
			}
		},
		bulkDeleteConfirmDialogOpen: {
			immediate: true,
			async handler (newVal: boolean, oldVal: boolean) {
				if (newVal !== oldVal && !newVal && this.$refs.doubleConfirmDangerDialog) {
					this.$refs.doubleConfirmDangerDialog.reset();
				}
			}
		}
	},
	computed: {
		...mapState([
			'user',
			'runnitState',
			'runnits',
			'isAdminProxyingUser',
			'team',
			'runnitNodesMap',
			'runnitNodes',
		]),
		...mapGetters([
			'runnitsLimitMap',
		]),
		isTrainerAppType () {
			const selectedNodeId = this.runnitState.selectedNodeId ? this.runnitState.selectedNodeId : this.runnitNodes[0]?.id;
			return this.runnitNodesMap &&
				selectedNodeId &&
				this.runnitNodesMap[selectedNodeId]?.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.TRAINER;
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'updateRunnitState',
		]),
		pluralize,
		onBackClick () {
			if (!this.$route.meta.isSessionPage) {
				if (window.history.length > this.runnitState.initialHistoryLength) {
					this.$router.back();
				} else {
					this.$router.push({ name: ROUTER.RUNNITS_HOME });
				}
			}
		},
		onGalleryDrawerClose (open: boolean) {
			this.updateRunnitState({
				imageGalleryDrawerOpen: open,
			});
		},
		isAbovePlanRunnitsLimit (runnit: Runnit, runnitsLimitMap: Record<string, Runnit>) {
			// Runnit isn't in runnitsLimitMap (hit user's plan limit on number of Runnits)
			return !!(
				runnit &&
				this.$route.params.runnitId === runnit.id && // We're on a page for this runnit right now
				runnit.type !== RUNNIT_TYPE.SINGLE_TOOL && // Single tool runnits are available to everyone
				!_isEmpty(runnitsLimitMap) && // It's loaded, and has at least one in there
				runnit.teamId === Object.values(runnitsLimitMap)[0].teamId && // Make sure that the limitMap is properly initialized for the team or private
				!runnitsLimitMap[runnit.id]
			);
		},
		routeAwayDueToRunnitsLimit () {
			console.log('routeAwayDueToRunnitsLimit')
			this.routerReplace(this.$route, this.$router, {
				name: ROUTER.RUNNITS_HOME,
			});
		},
		routeAwayDueToRunnitOwnershipIssue () {
			console.log('routeAwayDueToRunnitOwnershipIssue')
			this.routerReplace(this.$route, this.$router, {
				name: ROUTER.RUNNITS_HOME,
			});
		},
	},
	components: {
		AccountMenuBtn,
		GetSupportBtn,
		RunnitsWithRDHorizontalLogoSVG,
		RunnitsLogoMarkSVG,
		RunnitTitleBar,
		GreyButton,
		ComplexBackgroundHeader,
		ImageGalleryDrawer,
		RunnitPageSettings,
		DoubleConfirmDangerDialog,
	},
});
