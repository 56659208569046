import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-nav",style:({
		background: _vm.$vuetify.breakpoint.mdAndUp ? 'var(--dark-purple-900)' : 'var(--dark-purple-gradient-120)',
	})},[_c('div',{staticClass:"runnit-nav-background"}),_c('div',{staticClass:"runnit-nav-content"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('complex-background-side-nav'):_vm._e(),_c('div',{staticClass:"runnit-nav-content-wrapper",class:{ 'max-width': _vm.$vuetify.breakpoint.smOnly }},[_c('div',{staticClass:"runnit-nav-upper"},[_c('div',{staticClass:"runnit-nav-logo",class:{
						'mobile': _vm.$vuetify.breakpoint.smAndDown
					}},[_c('div',{staticClass:"temp-runnits-logo"},[_c('runnits-vertical-logo-with-r-d-s-v-g',{attrs:{"width":120,"height":95,"fill":"white"}})],1),(_vm.$vuetify.breakpoint.smAndDown)?_c(VBtn,{staticStyle:{"position":"absolute","right":"-10px","top":"0","z-index":"100"},attrs:{"icon":"","large":""},on:{"click":_vm.closeSideNav}},[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.5)","font-size":"35px"}},[_vm._v("mdi-close-circle-outline")])],1):_vm._e()],1),_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isHomeRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_HOME)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-home-variant-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Home ")])],1),_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isLibraryRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_LIBRARY)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-image-multiple-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Library ")])],1),_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isBoardsRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_BOARDS)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-cards-variant ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Runnit Boards ")]),_c(VTooltip,{attrs:{"top":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"runnit-nav-item-add-icon",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddRunnitClick.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticStyle:{"font-size":"20px"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" Add a new Runnit Board ")])])],1),_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isAllToolsRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_ALL_TOOLS)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-tools ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Tools ")])],1),_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isAppsRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_APP_LIBRARY)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-view-grid-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Apps ")])],1),_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isModelsRoute,
					},staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_MODELS_LIST)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-vector-circle ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Models ")]),(_vm.getUploading.length)?_c('loading-state',{attrs:{"color":"white","size":20,"container-styles":"","hide-display-text":"","progress":Math.max(..._vm.getUploading.map(uploadingModel => uploadingModel.progress), 0)}}):_vm._e()],1),_c(VTooltip,{attrs:{"open-delay":500,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('glass-button',_vm._g(_vm._b({attrs:{"styles":{
									flexDirection: 'column',
									alignItems: 'center',
									margin: '30px 15px 0 15px',
									padding: '12px 15px',
									width: 'calc(100% - 30px)',
								}},on:{"click":_vm.openRunDiffusionInNewTab}},'glass-button',attrs,false),on),[_c('RunDiffusionWithRunnitMarkLogo',{attrs:{"width":"155","height":"40","fill":"white"}}),_c('div',{staticClass:"runnit-nav-item-label",staticStyle:{"margin-top":"8px","text-align":"center","display":"flex","align-items":"flex-start","justify-content":"center","gap":"8px","transition":"var(--transition-default)"},style:({ color: hover ? 'white' : undefined })},[_c('div',{staticClass:"d-flex flex-column"},[_c('b',[_vm._v("Open Source Apps")]),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("(Auto1111, ComfyUI, Fooocus, etc.)")])]),_c(VIcon,{staticStyle:{"margin-top":"2px"},style:({
											color: hover ? 'white' : 'var(--grey-500)',
											transition: 'var(--transition-default)'
										}),attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)],1)]}}],null,true)})]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" This will open up the "),_c('b',[_vm._v("RunDiffusion")]),_vm._v(" platform in a new tab where you can still access all of our amazing Open Source Apps ")])])],1),_c('div',{staticClass:"runnit-nav-lower"},[(_vm.user && _vm.user.isAdmin)?_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isHelpRoute,
					},staticStyle:{"width":"100%","margin-left":"0"},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.HELP_CENTER)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-help ")]),_c('div',{staticClass:"runnit-nav-item-label deep-orange--text"},[_vm._v(" Help ")])],1):_vm._e(),(_vm.user && _vm.user.isAdmin)?_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isWhatsNewRoute,
					},staticStyle:{"width":"100%","margin-left":"0"},on:{"click":_vm.openWhatsNew}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-bullhorn-outline ")]),_c('div',{staticClass:"runnit-nav-item-label deep-orange--text"},[_vm._v(" What's New ")])],1):_vm._e(),(_vm.runnitState.runnitsOwnerSelection !== _vm.RUNNITS_OWNER_SELECTION.TEAM || _vm.isTeamActive)?_c('div',{staticClass:"runnit-nav-item",class:{
						'runnit-nav-item-selected': _vm.isSubscriptionsRoute,
					},staticStyle:{"width":"100%","margin-left":"0","margin-bottom":"10px"},on:{"click":_vm.onManagePlanClick}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-crown ")]),_c('div',{staticClass:"runnit-nav-item-label"},[(_vm.showStripeUserMembershipPortalBtn || (_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM && _vm.isTeamActive))?_c('span',[_vm._v(" Manage My Plan ")]):_c('span',[_vm._v("Upgrade My Plan")])])],1):_vm._e(),_c('div',{staticClass:"account-tokens-menu"},[_c('runnit-account-selection-dialog',{staticClass:"full-width",attrs:{"is-side-nav-design":""}}),_c('div',{staticClass:"account-tokens-menu-divider"}),_c('runnit-tokens',{staticClass:"full-width",attrs:{"background":"transparent","token-size":14,"is-club-tokens":"","needMoreBtnStyle":"margin-right: -6px"}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }