
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db, storage } from '@/firebase';
import { NODE_RUN_RESULT_SELECTION_MODE } from '@/views/Runnits/constants';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { RunnitState } from '@/store';
import {
	RunnitMask,
	RunnitNodeRunInputValue,
	RunnitNodeRunResult,
	RunnitUpload,
	UploadFile,
	IMAGE_UPLOAD_MODE
} from '@run-diffusion/shared';
import _isEqual from 'lodash/isEqual';
import ImageInputSelectedImageContainer from '@/views/Runnits/RunnitSettings/ImageInput/ImageInputSelectedImageContainer.vue';
import { IMAGE_GALLERY_DIALOG_NAV, ImageGalleryDialogNav } from '@/components/ImageGallery/utils';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import InpaintingEditor from '../InpaintingEditor/InpaintingEditor.vue';
import BigNumber from 'bignumber.js';

export default Vue.extend({
	name: 'ImageInput',
	mixins: [
		RunnitDynamicFieldMixin,
		RunnitsImageSelectMixin,
	],
	props: {
		field: { type: Object, required: true },
		value: { type: Object, default: null },
		nodeId: { type: String, default: null },
		adminOnly: { type: Boolean, default: false },
	},
	data () {


		return {
			IMAGE_UPLOAD_MODE,
			IMAGE_GALLERY_DIALOG_NAV,
			FILE_SIZE_LIMIT: 4194304, // 4 MB in bytes

			isUploading: false,
			isBeingDraggedOver: false,

			imageDetails: null,
		};
	},
	destroyed () {
		this.exitSelectionMode(this.selectionStateTriggerId);
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		selectionStateTriggerId () {
			return `image-input-${this.field.uuid}`;
		},
		hasInpainting () {
			return !!this._get(this.field, 'display.hasInpainting');
		},
		inpaintingRequired () {
			return this.hasInpainting && this.field.required && !!this._get(this.field, 'display.inpaintingRequired');
		},
		hasStrength () {
			return !!this._get(this.field, 'display.hasStrength');
		},
		imageValue () {
			let val = this.value
			if (this.hasInpainting || this.hasStrength) {
				val = this._get(this.value, 'image') || null;
			}
			return val;
		},
		inpaintingValue () {
			if (this.hasInpainting) {
				return this._get(this.value, 'inpainting') || { mask: null };
			}
			return null;
		},
		strengthValue () {
			if (this.hasStrength) {
				return this._get(this.value, 'strength') || 0.8;
			}
			return null;
		},
	},
	watch: {
		value: {
			immediate: true,
			async handler (newVal: RunnitNodeRunInputValue, oldVal: RunnitNodeRunInputValue) {
				if (this.hasInpainting) {
					const mask = this._get(newVal, 'inpainting.mask') || {}
					await this.setMaskURL(this._get(mask, 'id') || null);
				} else {
					this.setMaskURL(null);
				}
			},
		},
		runnitState: {
			immediate: false,
			handler (newVal: RunnitState, oldVal: RunnitState) {
				if (
					newVal !== oldVal &&
					newVal.selectionStateHistory[this.selectionStateTriggerId] &&
					newVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection &&
					newVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection.uuid === this.field.uuid &&
					oldVal.selectionStateHistory[this.selectionStateTriggerId]
				) {
					const newResultUuidsList: string[] = newVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults.map(({ uuid }) => uuid);
					const oldResultUuidsList: string[] = oldVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults.map(({ uuid }) => uuid);
					const newUploadIdList: string[] = newVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads.map(({ id }) => id);
					const oldUploadIdList: string[] = oldVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads.map(({ id }) => id);
					if (
						(
							!oldVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection ||
							oldVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection.uuid !== this.field.uuid
						) ||
						!_isEqual(newResultUuidsList, oldResultUuidsList) ||
						!_isEqual(newUploadIdList, oldUploadIdList)
					) {
						const valueNodeRunResultUuid: string = this._get(this.imageValue, 'nodeRunResult.uuid') || null;
						const valueUploadId: string = this._get(this.imageValue, 'upload.id') || null;
						const firstSelectedNodeRunResult: RunnitNodeRunResult = this._get(newVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults, '[0]') || null;
						const firstSelectedUpload: RunnitUpload = this._get(newVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads, '[0]') || null;

						if (!firstSelectedNodeRunResult && !firstSelectedUpload && (valueNodeRunResultUuid || valueUploadId)) {
							this.onInput(null, null, null);
						} else if (firstSelectedNodeRunResult && firstSelectedNodeRunResult.uuid !== valueNodeRunResultUuid) {
							this.onInput({ nodeRunResult: firstSelectedNodeRunResult }, this.inpaintingValue, this.strengthValue);
						} else if (firstSelectedUpload && firstSelectedUpload.id !== valueUploadId) {
							this.onInput({ upload: firstSelectedUpload }, this.inpaintingValue, this.strengthValue);
						}
					}
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onInput (image: RunnitNodeRunInputValue, inpainting: RunnitNodeRunInputValue, strength: number) {
			if (this.hasInpainting || this.hasStrength) {
				if (image || inpainting || strength) {
					this.$emit('input', {
						image,
						inpainting,
						strength,
					});
				} else {
					this.$emit('input', null);
				}
			} else {
				this.$emit('input', image);
			}
		},
		clearValueAndStartSelectSelectionMode () {
			this.onInput(null, null, null);
			this.clearSelectionsFromState(this.selectionStateTriggerId);
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field, ['IMG']);
		},
		onStrengthChange (strength) {
			this.onInput(this.imageValue, this.inpaintingValue, strength);
		},
		startLibrarySelectionMode (imageGalleryDialogInitialNav: ImageGalleryDialogNav) {
			this.updateRunnitState({
				imageGalleryDialogOpen: true,
				imageGalleryDialogInitialNav: imageGalleryDialogInitialNav,
			});
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field, ['IMG']);
		},
		onToggleImageSelectionMode () {
			if (this.currentSelectionState.triggeringId) {
				this.exitSelectionMode(this.currentSelectionState.triggeringId);
			} else {
				this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field, ['IMG']);
			}
		},
		async onInpaintingMaskChange (mask) {
			this.onInput(this.imageValue, { ...this.inpaintingValue, mask }, this.strengthValue);
			await this.setMaskURL(this._get(mask, 'id') || null);
		},
		async setMaskURL (maskId) {
			if (maskId) {
				const maskRef = db.doc(`runnitMasks/${maskId}`);
				const mask: RunnitMask = (await maskRef.get()).data() as RunnitMask;

				const maskImageRef = storage.ref(`runnitMasks/users/${mask.userId}/masks/${mask.name}`);

				let maskURL = null;
				try {
					// Exists
					maskURL = await maskImageRef.getDownloadURL();
				} catch (err) {
					// doesn't exist
				}
				this.updateRunnitState({
					inpaintingMaskUrl: maskURL,
				});
			} else {
				this.updateRunnitState({
					inpaintingMaskUrl: null,
				});
			}
		},
		validateImageSize (v: RunnitNodeRunInputValue) {
			if (!v) return true; // no value so can't check its size
			const imageDetails: {
				size: number,
				width: number,
				height: number,
			} = v.nodeRunResult ? {
				size: v.nodeRunResult.file.size,
				width: v.nodeRunResult.file.width,
				height: v.nodeRunResult.file.height,
			} : v.upload ? {
				size: v.upload.size,
				width: v.upload.width,
				height: v.upload.height,
			} : null;
			if (!imageDetails) return true; // we don't have any information on the image yet

			const { maxSize, maxWidth, maxHeight } = this.field.display || {};
			if (!maxSize && !maxWidth && !maxHeight) return true; // no max set so can't validate size

			// check file size
			if (maxSize && imageDetails.size && imageDetails.size > maxSize) {
				// The size is in bytes the '/ 1024 / 1024' is to convert it to MB
				return `Image is too large (${(imageDetails.size / 1024 / 1024).toFixed(2)} MB). Max: ${(maxSize / 1024 / 1024).toFixed(2)} MB`;
			}

			// Check dimensions
			const dimensionsValid: boolean = (!maxWidth || !imageDetails.width || imageDetails.width <= maxWidth) &&
				(!maxHeight || !imageDetails.height || imageDetails.height <= maxHeight);
			return dimensionsValid || `Dimensions (${imageDetails.width}x${imageDetails.height}) are too large. Max: ${maxWidth}x${maxHeight}`;
		},
		validateImageAspectRatio (v: RunnitNodeRunInputValue) {
			if (!v) return true; // no value so can't check its ratio
			const imageDetails: {
				width: number,
				height: number,
			} = v.nodeRunResult ? {
				width: v.nodeRunResult.file.width,
				height: v.nodeRunResult.file.height,
			} : v.upload ? {
				width: v.upload.width,
				height: v.upload.height,
			} : null;
			if (!imageDetails) return true; // we don't have any information on the image yet

			let { maxAspectRatio } = this.field.display || {};
			maxAspectRatio = maxAspectRatio || 3;

			const aspectRatio: BigNumber = new BigNumber(imageDetails.width).div(imageDetails.height);

			if (aspectRatio.isGreaterThan(maxAspectRatio)) {
				return `Image aspect ratio (${aspectRatio.toFixed(2)}:1) is too wide. Maximum allowed is ${maxAspectRatio}:1`;
			}
			if (aspectRatio.isLessThan(new BigNumber(maxAspectRatio).pow(-1))) {
				return `Image aspect ratio (1:${aspectRatio.pow(-1).toFixed(2)}) is too tall. Maximum allowed is 1:${maxAspectRatio}`;
			}

			return true;
		},
		handleImageUpload (upload: any) {
			// Handle different types of uploads
			if (upload.nodeRunResult) {
				this.onInput({ nodeRunResult: upload.nodeRunResult }, this.inpaintingValue, this.strengthValue);
			} else if (upload.upload) {
				this.onInput({ upload: upload.upload }, this.inpaintingValue, this.strengthValue);
			} else {
				this.onInput({ upload }, this.inpaintingValue, this.strengthValue);
			}
		},
	},
	components: {
		ImageInputSelectedImageContainer,
		RunnitDynamicFieldLabelRow,
		InpaintingEditor,
	},
});
