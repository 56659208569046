import { render, staticRenderFns } from "./RunnitDynamicField.vue?vue&type=template&id=72e32ea5&scoped=true"
import script from "./RunnitDynamicField.vue?vue&type=script&lang=ts"
export * from "./RunnitDynamicField.vue?vue&type=script&lang=ts"
import style0 from "./RunnitDynamicField.vue?vue&type=style&index=0&id=72e32ea5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72e32ea5",
  null
  
)

export default component.exports